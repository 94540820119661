import { StyleSheet, ActivityIndicator, Pressable} from 'react-native'

import React from 'react'
import Colours from '../constants/colours';
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import { VibrateOnce } from '../functions/vibration';
import { useNavigation } from '@react-navigation/native';





const HouseBar = ({ houseName, houseWidth, houseLogo, housePoints, houseColour }) => {
    const colorScheme = useColorScheme();
const navigation = useNavigation();


    const WidthOfBarChart = 250;


    const navigateToHouseList = ()=>{
      const house = houseName
      VibrateOnce()
      navigation.navigate("GroupingData1List", {house});
    }

  return (


<Pressable onPress={navigateToHouseList}>
    <View style={styles.houseBar}>

      <View style={styles.houseLogo}>
        {houseLogo}</View>
      <View
        style={[
          styles.barBackground,
          {
            width: WidthOfBarChart,
          },
        ]}
      >
        <View
          style={
     
            {
              backgroundColor: houseColour,
              width: houseWidth,
              height: 25,
              borderRadius: 5,
              paddingHorizontal: 5,
              alignItems: "flex-end",
              justifyContent: "center",
            }
            
        }
        >
          <Text
            style={[
              styles.pointsNumber,
              { color: Colours[colorScheme].background },
            ]}
          >
            {housePoints}
          </Text>
        </View>
      </View>
    </View></Pressable>

  )
}

export default HouseBar

const styles = StyleSheet.create({barBackground: {
    backgroundColor: "transparent",
    height: 25,
    borderRadius: 5,
    flexDirection: "row",
  },houseLogo: { width: 40, height: 40, margin: 10 },
    houseBar: { flexDirection: "row", alignItems: "center", marginRight: 10 },
    pointsNumber: { marginHorizontal: 5, color: "black" },})



