import React, { useEffect, useState } from "react";
import { Image, Pressable} from 'react-native'
import { Storage } from "aws-amplify";
import DynamicBadge from "./DynamicBadge";
import { View, Text} from "../constants/themedViewAndText";
import { useNavigation } from "@react-navigation/native";
import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp} from 'react-native-reanimated';
import Animated from 'react-native-reanimated';
import ActivityIndicatorAnimation from "./ActivityIndicator";
import { VibrateOnce } from "../functions/vibration";
const GridItem = ({item}) => {

const [isLoading, setIsLoading] = useState(true)

  const navigation = useNavigation();
    const [downloadedImage, setDownloadedImage] = useState(null);
    const fetchBadgeImage = async () => {
        try {


    if (item.Opportunity.logoURL) {
        const file = await Storage.get(item.Opportunity.logoURL, { level: "public" });
        setDownloadedImage(file);
      } else {
        setDownloadedImage(null); // Set to null if no photo URL is available
      }

    } catch (error) {

        console.error("Error fetching user data:", error);
    }

    finally {
     setIsLoading(false)
      }

    }


      useEffect(() => {
        fetchBadgeImage();
      }, []);
    
      const NavigateToOpportunityDetail = () => {
        VibrateOnce();
        navigation.navigate("OpportunityDetail", { opportunityID: item.Opportunity.id, logo:downloadedImage });
      };

  return (
    <Pressable onPress={NavigateToOpportunityDetail}>
<Animated.View 
entering={FadeIn} exiting={FadeOut}

style={{backgroundColor:'transparent', margin:10}}>
  {!isLoading?
<DynamicBadge
                size={120}
                edgeColour={item.Opportunity.colour}
                engagementLevel={item.Opportunity.engagementLevel}
                logo={downloadedImage}
              />:null
              // <ActivityIndicatorAnimation />
              }
</Animated.View>
</Pressable>
    
  );
};

export default GridItem;