import { StyleSheet,  Pressable, Image} from 'react-native'
// import { Image } from 'expo-image';
import {View} from '../constants/themedViewAndText'
import React, { useEffect, useState } from 'react'
import {Storage} from 'aws-amplify'
import TimelineIcon from '../../assets/icons/Timeline';
import useColorScheme from '../hooks/useColorScheme';
import Colours from '../constants/colours';
import { BoldText } from '../constants/styledText';
import { useNavigation } from "@react-navigation/native";
import { VibrateOnce } from '../functions/vibration';
const ThumbnailTimeLine = ({id, photoURL, house, preferredFirstName, preferredSurname, title, type}) => {
    const colorScheme = useColorScheme();
const [downloadedImage, setDownloadedImage] = useState(null)



const houseColor = house ? Colours.palette[house.toLowerCase()] : 'grey';
const navigation = useNavigation();



const NavigateToTimelineDetail = () => {
  navigation.navigate("TimelinesDetail",
   { userID: id }
  ); 
};



const whatShouldWeCallThisPerson = type === 'STAFF' || type ===  'ADMIN' ? title + ' ' + preferredSurname : preferredFirstName


    const fetchPhoto = async () => {
        
        const file = await Storage.get(photoURL, { level: "public" });
        setDownloadedImage(file);
      };
      
      useEffect(() => {
        if(photoURL) {
        fetchPhoto();
        } else {
          setDownloadedImage("https://support.recognition.org.uk/img/favicon48.png")
        }
      }, []);




  return (
    <Pressable onPress={()=>{
      VibrateOnce()
      NavigateToTimelineDetail()
    }
      }>
    <View style={{   borderRadius: 10,
        // borderWidth: 1,
        padding: 10,
        // marginHorizontal: 3,
        // marginVertical: 8,
        margin:5,
        // alignContent:'center',
        alignItems: 'center',
        justifyContent:'space-between',
        backgroundColor:Colours[colorScheme].background,
        // backgroundColor: houseColor,
        width: 180,
        height:200}}>
      {/* <Text>{downloadedImage}</Text> */}
      

      <View style={{backgroundColor: 'transparent', height:150, width: 150}}>
      <View style={{height: 150,
                    width: 150,

                    borderRadius: 120,
                    borderColor: houseColor,
                    borderWidth: 5,}}>
      <TimelineIcon color={"#ccc"} />
      {downloadedImage ? 
      <Image source={{uri: downloadedImage}}
      style={{height: 140, width: 140,
    
   
        borderRadius: 100,
        // borderColor: houseColor,
        // borderWidth: 3,
    
    
        
        
      
        position: "absolute",
    
    
    
    }}
      accessibilityLabel={preferredFirstName}
    //   placeholder={require('../../assets/images/')}
      />:null}</View>
      </View>
      <BoldText style={{fontSize:20, color:Colours[colorScheme].textLessEmphasised}}>{whatShouldWeCallThisPerson}</BoldText>
    </View>
    </Pressable>
  )
}

export default ThumbnailTimeLine

const styles = StyleSheet.create({})