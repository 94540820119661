import React from "react";
import Svg, { Path } from "react-native-svg";

const BadgeIcon = (color: any) => {
  return (
    <Svg id="Layer_1" viewBox="0 0 300 300">
      <Path
        d="M219.8,61.5H80.2c-5.8,0-10.5,4.7-10.5,10.5c0,0,0,0,0,0v111.1c0,3.6,1.8,6.9,4.8,8.9l69.8,44.9l0.1,0.1
	c0.3,0.2,0.7,0.4,1.1,0.6l0.2,0.1c0.4,0.2,0.8,0.3,1.3,0.5h0.2c0.4,0.1,0.8,0.2,1.1,0.2l0.3,0.1c0.4,0.1,0.9,0.1,1.3,0.1l0,0
	c0.4,0,0.9,0,1.3-0.1l0.3-0.1c0.4-0.1,0.8-0.1,1.1-0.2h0.2c0.4-0.1,0.9-0.3,1.3-0.5l0.2-0.1c0.4-0.2,0.7-0.4,1.1-0.6l0.1-0.1
	l69.8-44.9c3-1.9,4.8-5.3,4.8-8.8V72C230.3,66.2,225.6,61.5,219.8,61.5C219.8,61.5,219.8,61.5,219.8,61.5z M90.8,82.5h48.8v126.3
	l-48.8-31.4L90.8,82.5z M209.3,177.4l-48.8,31.4V82.5h48.8L209.3,177.4z"
        fill={color.color}
      />
    </Svg>
  );
};

export default BadgeIcon;
