import React, { useContext } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import { useNavigation } from "@react-navigation/native";

const AdminRoot = () => {
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const colorScheme = useColorScheme();
  const navigation = useNavigation()
  const type = userDetailsFromUserContext.type

  const NavigateToAdminUsers = () => {
    navigation.navigate("AdminUsers", { });
  };


  return (
    
    <View style={{backgroundColor:Colours[colorScheme].background, flex:1,}}>



      <View style={{flex:1, width:'100%',backgroundColor: Colours[colorScheme].almostBackground, borderRadius:10,alignItems:'center', justifyContent:'center' }}>
  

{type === 'ADMIN'?<>
      <ActionButton icon={'users'} text={'Users'} onPressFunction={NavigateToAdminUsers} />
      <ActionButton icon={'dribbble'} text={'Flexible Learning'} onPressFunction={undefined} />
</>:null}


      </View>
    </View>
  );
};

export default AdminRoot;

const styles = StyleSheet.create({});
