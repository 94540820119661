import { StyleSheet, Pressable, Image } from "react-native";
// import { Image } from 'expo-image';
import { View, Text } from "../constants/themedViewAndText";
import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import TimelineIcon from "../../assets/icons/Timeline";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { BoldText } from "../constants/styledText";
import HouseLogo from "./HouseLogo";
import CoachingIcon from "../../assets/icons/Coaching";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import Animated from "react-native-reanimated";
import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp } from "react-native-reanimated";
import { VibrateOnce } from "../functions/vibration";
import { whatShouldWeCallThisPerson } from "../functions/whatShouldWeCallThisPerson";

const PhotoListItemRegisterSearch = ({ item,onPressToAdd  }) => {
  const { id, photoURL, preferredFirstName, preferredSurname, title, type } = item;
  const house = item.Enrolments?.items[0]?.groupingData1;
  const coach = item.Enrolments?.items[0]?.groupingData2;
  const year = item.Enrolments?.items[0]?.groupingData3;
  const colorScheme = useColorScheme();
  const [downloadedImage, setDownloadedImage] = useState(null);
  const navigation = useNavigation();
  // const whatShouldWeCallThisPerson = type === "STAFF" ? title + " " + preferredSurname : preferredFirstName + " " + preferredSurname;
  const [pressed, setPressed] = useState(false);
  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";


  const fetchPhoto = async () => {
    const file = await Storage.get(photoURL, { level: "public" });
    setDownloadedImage(file);
  };

  useEffect(() => {
    if (photoURL) {
      fetchPhoto();
    } else {
      console.log("no photo available");
    }
  }, []);

  return (

    <Pressable

    onPressIn={() => {
      setPressed(true);

      VibrateOnce();
    }}
    onPressOut={() => setPressed(false)}



    onPress={() =>{
      onPressToAdd(item.id)
    
    
    
    
    }}
      style={{
        // flexDirection: "column",
        // justifyContent: "space-between",
        // alignContent: "flex-start",

        height: 150,
        alignItems: "center",
        backgroundColor: pressed ? Colours[colorScheme].almostBackground : "transparent",
        borderRadius:10,
        width: 110,

        // borderBottomWidth: 1,
        // borderBottomColor: Colours[colorScheme].background,
      }}>


{/* IMAGE */}

      <View style={{ backgroundColor: "transparent", height: 90, width: 90, marginHorizontal: 5, alignItems: "center", justifyContent: "center" }}>
        
        <View
          style={{
            height: 84,
            width: 84,

            borderRadius: 84,
            borderColor: houseColor,
            borderWidth: 2,
          }}>
          <TimelineIcon color={"#ccc"} />

          {downloadedImage ? (
            <Image
              source={{ uri: downloadedImage }}
              style={{
                height: 80,
                width: 80,
                borderRadius: 80,
                position: "absolute",
              }}
              accessibilityLabel={preferredFirstName}
            />
          ) : null}
        </View>
        
      </View>


      {/* NAME */}

      <View style={{ width:100, backgroundColor: "transparent", marginHorizontal: 5, alignItems: "flex-start", justifyContent: "space-between" }}>
        <View style={{ backgroundColor: "transparent", width:100,alignItems:'center' }}>
          <BoldText numberOfLines={1} ellipsizeMode='tail'  style={{ fontSize: 16, color: Colours[colorScheme].text }}>
           {preferredFirstName}
          </BoldText>
          <BoldText numberOfLines={1} ellipsizeMode='tail' style={{ fontSize: 16, color: Colours[colorScheme].text }}>
           {preferredSurname}
          </BoldText>
          <Text style={{ fontSize: 12, color: Colours[colorScheme].textLessEmphasised }}>{year}</Text>
        </View>
      </View>


      {/* ICON */}

      {/* <Pressable onPress={() => onPressDelete(item.id)} style={{ paddingHorizontal: 20, backgroundColor: "transparent" }}>
        <Feather name="x-square" color={Colours.palette.softRed} size={30} />
      </Pressable> */}
    </Pressable>
  );
};

export default PhotoListItemRegisterSearch;

const styles = StyleSheet.create({});
