import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TimelinesRootScreen from "../screens/timelinesRoot";
import TimelinesDetail from "../screens/timelinesDetail";
import OpportunityDetail from "../screens/opportunityDetail";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import PhotoPopUp from "../screens/photoPopUp";
import { VibrateOnce } from "../functions/vibration";
import GroupgingData2List from "../screens/groupingData2List";
import GroupingData2List from "../screens/groupingData2List";
import GroupingData3List from "../screens/groupingData3List";
import GroupingData1List from "../screens/groupingData1List";
import CriteriaPopUp from "../screens/CriteriaPopUp";
import ActivitiesRoot from "../screens/ActivitiesRoot";
import ActivitiesSelection from "../screens/ActivitiesSelection";
import ActivitiesRegister from "../screens/ActivitiesRegister";
import ActivitiesPreviousRegister from "../screens/ActivitiesPreviousRegister";
import ActivitiesPreviousSessions from "../screens/ActivitiesPreviousSessions";

const ActivitiesPreviousSessionsStack = createNativeStackNavigator();

export default function ActivitiesPreviousSessionsStackNavigator({route}) {

const {opportunityID} = route.params

  const colorScheme = useColorScheme();
  return (
    <ActivitiesPreviousSessionsStack.Navigator
    
      screenOptions={{
        headerStyle: {
          backgroundColor: Colours[colorScheme].background,
        },
        headerShown: false,
        headerTitleStyle: { fontFamily: "avenir-next" },
        headerShadowVisible: false,
      }}>
      <ActivitiesPreviousSessionsStack.Screen
      initialParams={{ opportunityID:opportunityID}}
        name="ActivitiesPreviousSessions"
        component={ActivitiesPreviousSessions}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />



<ActivitiesPreviousSessionsStack.Group
        screenOptions={{
          presentation: "modal",
          // presentation: 'transparentModal'
          // presentation:'containedTransparentModal'
        }}>
        {/* <TimeslineStack.Screen name="FlexibleLearningHeatMap" component={FlexibleLearningHeatMap} /> */}
        
        <ActivitiesPreviousSessionsStack.Screen
        name="ActivitiesPreviousRegister"
        initialParams={{ opportunityID:opportunityID}}
        component={ActivitiesPreviousRegister}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
        
        <ActivitiesPreviousSessionsStack.Screen
          name="PhotoPopUp"
          component={PhotoPopUp}
          options={{
            headerShown: true,
            headerShadowVisible: false,
            headerTransparent: true,
            headerStyle: { backgroundColor: "transparent" },
            title: "",
            headerTintColor: Colours[colorScheme].buttons,
          }}
          listeners={() => ({
            transitionStart: () => {
              VibrateOnce();
            },
          })}
        />
        <ActivitiesPreviousSessionsStack.Screen
          name="CriteriaPopUp"
          component={CriteriaPopUp}
          options={{
            headerShown: false,
            // headerShadowVisible: false,
            // headerTransparent: true,
            // headerStyle: { backgroundColor: "transparent" },
            // title: "",
            // headerTintColor: Colours[colorScheme].buttons,
          }}
          listeners={() => ({
            transitionStart: () => {
              VibrateOnce();
            },
          })}
        />
      </ActivitiesPreviousSessionsStack.Group>









     
      
    </ActivitiesPreviousSessionsStack.Navigator>
  );
}
