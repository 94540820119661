import { StyleSheet, Pressable, Image, Platform, Modal } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useEffect, useState } from "react";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";
import { useNavigation } from "@react-navigation/native";
import { getLast60Days } from "../functions/last60Days";
import { Picker } from "@react-native-picker/picker";
import useColorScheme from "../hooks/useColorScheme";
import { prettyDate } from "../functions/prettyDate";
import { BoldText } from "../constants/styledText";
import ActionButton from "./ActionButton";

const InformationBox = ({ message, date, setDate }) => {
  const colorScheme = useColorScheme();
  const [selectedDate, setSelectedDate] = useState(date); // State to store selected date
  const arrayForPicker = getLast60Days();
  const [modalVisible, setModalVisible] = useState(false);
  const [pressed, setPressed] = useState(false);
  console.log("date", date);
  console.log("selectedDate", selectedDate);

  const changeDate = () => {
    // Here, you can use selectedDate to set the date
    setDate(selectedDate.machineReadableDate);
    setModalVisible(false); // Close the modal after setting the date
  };

  return (
    <View
      style={{ borderRadius: 10, borderWidth: 1, borderColor: "#f9b31d", backgroundColor: "#fdf9e8", margin: 10,
      width: Platform.OS === "web"?'auto':"80%",
      // flex:1,
      flexDirection: "column", padding: 10, gap: 5, alignItems: "center" }}>
      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
        <Feather name="alert-circle" size={24} color="#f9b31d" />
        <Text style={{ flexWrap: "wrap", color: Colours.light.buttons, marginLeft: 5 }}>{message}</Text>
      </View>

      {Platform.OS === "web" ? (
        <Picker
        itemStyle={
          {
            fontSize: 16, fontFamily: "avenir-next"
          }
        }
          style={{
            borderWidth: 1,
            margin: 10,
            padding: 10,
            borderRadius: 8,
            fontSize: 16,
            fontFamily: "avenir-next",
            width: 280,
            // outlineStyle: "none",
            color: Colours[colorScheme].textLessEmphasised,
            backgroundColor: Colours[colorScheme].background,
            borderColor: Colours[colorScheme].edging,
          }}
          selectedValue={selectedDate.machineReadableDate}
          onValueChange={(itemValue, itemIndex) => {
            const newSelectedDate = arrayForPicker.find((item) => item.machineReadableDate === itemValue);
            setSelectedDate(newSelectedDate);
            setDate(newSelectedDate.machineReadableDate);
          }}>
          {arrayForPicker.map((item, index) => (
            <Picker.Item style={{}} key={index} label={item.humanReadableDate} value={item.machineReadableDate} />
          ))}
        </Picker>
      ) : (
        <>
          <Pressable
            style={{ flexDirection: "row", alignItems: "center" }}
            onPress={
              () => setModalVisible(true)
            }>
            <BoldText style={{ marginRight: 5, color: Colours.light.buttons }}>{prettyDate(date)}</BoldText>
            <Feather name="edit" size={12} color={Colours.light.buttons} />
          </Pressable>
          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}>
            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", flex: 1, width: "100%", alignItems: "center", justifyContent: "center" }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: Colours[colorScheme].edging,
                  backgroundColor: Colours[colorScheme].background,
                  // backgroundColor:'transparent',
                  // alignItems: "center",
                  margin: 10,
                  padding: 10,
                  borderRadius: 8,
                  // alignItems: "center",
                  // fontSize: 12,
                  // fontFamily: "avenir-next",
                  width: "90%",
                }}>
                <View style={{ backgroundColor: "transparent", height: 50 }}>
                  <Pressable
                    onPress={() => {
                      setModalVisible(!modalVisible);
                      VibrateOnce();
                    }}
                    onPressOut={() => setPressed(false)}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      backgroundColor: pressed ? Colours[colorScheme].almostBackground : "transparent",
                      borderRadius: 5,
                    }}>
                    <Feather name="x" color={pressed ? Colours.palette.logoGreen : Colours[colorScheme].buttons} size={30} />
                  </Pressable>
                </View>
                <Picker
                  selectedValue={selectedDate.machineReadableDate}
                  onValueChange={(itemValue, itemIndex) => setSelectedDate(arrayForPicker.find((item) => item.machineReadableDate === itemValue))}
                  itemStyle={
                    {
                      fontSize: 16, fontFamily: "avenir-next"
                    }
                  }
                  style={
                    {
                      // borderWidth: 1,
                      // margin: 10,
                      // padding: 10,
                      // borderRadius: 8,
                      // fontSize: 12,
                      // fontFamily: "avenir-next",
                      // width: '80%',
                      // outlineStyle: "none",
                      // color: Colours[colorScheme].textLessEmphasised,
                      // backgroundColor: Colours[colorScheme].background,
                      // borderColor: Colours[colorScheme].edging,
                    }
                  }>
                  {arrayForPicker.map((item, index) => (
                    <Picker.Item style={{}} key={index} label={item.humanReadableDate} value={item.machineReadableDate} />
                  ))}
                </Picker>
                <View style={{ alignItems: "center", width: "100%" }}>
                  <ActionButton icon={"check"} text={"Confirm"} onPressFunction={changeDate} />
                </View>
              </View>
            </View>
          </Modal>
        </>
      )}
    </View>
  );
};

export default InformationBox;
