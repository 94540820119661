import { StyleSheet, TextInput, Keyboard, Pressable, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { Entypo, Feather } from "@expo/vector-icons";

import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { VibrateOnce } from "../functions/vibration";
import Animated, { FadeIn, SlideInLeft, SlideInUp } from "react-native-reanimated";

const SearchBar = ({ masterData, filteredData, setFilteredData }) => {

  const handleKeyboardUse = (text) => {
  //  VibrateOnce()
    setSearchTerm(text)

  }

  // useEffect(() => {
  //   setPressed(false);
  // }, []);

  const [pressed, setPressed] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const colorScheme = useColorScheme();
  const [clicked, setClicked] = useState(false);

  const handleSearch = () => {
    const text = searchTerm;
    const terms = text.toLowerCase().split(" ");
    const filteredList = masterData.filter((user) => {
      return terms.every((term) => `${user.preferredSurname} ${user.surname} ${user.preferredFirstName} ${user.firstName} ${user.id}`.toLowerCase().includes(term));
    });
    setFilteredData(filteredList);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  return (
    <>
    <View
      style={{
        // backgroundColor: Colours[colorScheme].background,
        backgroundColor:'transparent',
        // marginTop: 50,
        justifyContent: "flex-start",
        alignItems: "center",

        // width: 500,
        width: "100%",
      }}>
      <View style={{ backgroundColor: "transparent", width: '90%', maxWidth: 400, flexDirection: "row" }}>
        <View
          style={
            !clicked
              ? [
                  styles.searchBar__unclicked,
                  {
                    backgroundColor: Colours[colorScheme].almostBackground,
                    // borderColor: Colours[colorScheme].edging,
                    borderColor:'transparent',
                  },
                ]
              : [
                  styles.searchBar__clicked,
                  {
                    backgroundColor: Colours[colorScheme].almostBackground,
                    borderColor: Colours.palette.logoGreen,
                  },
                ]
          }>
          {/* search Icon */}
          <Feather name="search" size={20} color={Colours[colorScheme].text} style={{ marginLeft: 1 }} />
          {/* Input field */}
          <TextInput
            style={[
              Platform.OS === "web" ? { outlineWidth: 0 } : null,
              // {outlineStyle: 'none'},
              styles.input,
              { color: Colours[colorScheme].text },
            ]}
            placeholder="Search for anyone..."
            placeholderTextColor={Colours[colorScheme].textLessEmphasised}
            value={searchTerm}
            // onChangeText={setSearchTerm}
            onChangeText={handleKeyboardUse}
            onFocus={() => {
              setClicked(true);
            }}
          />
        </View>
        {/* cancel button, depending on whether the search bar is clicked or not */}
        {clicked && (
          <View style={{ flex: 1, backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}>
            <Pressable
            style={{padding:5, borderRadius:5, backgroundColor:pressed?Colours[colorScheme].almostBackground:'transparent'}}

onPressIn={()=>setPressed(true)}
onPressOut={()=>setPressed(false)}
              onPress={() => {
                Keyboard.dismiss();
                VibrateOnce();
                setClicked(false);
                setSearchTerm("");
                setPressed(false)
              }}>
              <Text
                style={{
                  fontFamily: "avenir-next",
                  fontSize: 12,
                  color: pressed?Colours.palette.logoGreen:Colours[colorScheme].text,
                  // marginLeft: 5,
                }}>
                Cancel
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </View>

{/* additional panel when user activates search */}

    {/* {clicked && (
    <Animated.View
    entering={SlideInLeft}
    style={{backgroundColor:'red', width:'100%', height: 200,}}><Text>yo</Text></Animated.View>)} */}
    </>
  );
};
export default SearchBar;

// styles
const styles = StyleSheet.create({
  container: {},
  searchBar__unclicked: {
    padding: 10,
    margin: 10,
    flexDirection: "row",
    width: "95%",

    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    // justifyContent: "space-evenly",
    // justifyContent: 'space-between',
  },
  searchBar__clicked: {
    padding: 10,
    margin: 10,
    flexDirection: "row",
    flex: 4,
    // width: "80%",

    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    // justifyContent: 'space-between',
  },
  input: {
    fontSize: 16,
    marginLeft: 10,
    // outlineStyle: "none",
    // width: "90%",
    fontFamily: "avenir-next",
    flex: 2,
    // borderWidth:1,
    // borderColor: 'red',
  },
});
