// export const getLast60Days = () => {
//     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
//     const currentDate = new Date();
//     const result = [];
  
//     for (let i = 0; i < 60; i++) {
//       const date = new Date(currentDate);
//       date.setDate(date.getDate() - i);
      
//       const dayOfWeek = daysOfWeek[date.getDay()];
//       const dayOfMonth = date.getDate();
//       const month = months[date.getMonth()];
//       const year = date.getFullYear();
      
//       const suffix = getDaySuffix(dayOfMonth);
      
//       const formattedDate = `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
      
//       result.push(formattedDate); // Push to the end of the array
//     }

//     return result
    
//     // .reverse(); // Reverse the array before returning
// }
   
  
  
//   function getDaySuffix(day) {
//     if (day >= 11 && day <= 13) {
//       return 'th';
//     }
//     switch (day % 10) {
//       case 1: return 'st';
//       case 2: return 'nd';
//       case 3: return 'rd';
//       default: return 'th';
//     }
//   }
  
export const getLast60Days = () => {
  const today = new Date();
  const last60Days = [];

  for (let i = 0; i < 60; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i);

    // Format the date as "Tuesday 24th March 2023"
    const humanReadableDate = formatDate(currentDate);

    // Format the date as "2023-03-24"
    const machineReadableDate = `${currentDate.getFullYear()}-${padNumber(currentDate.getMonth() + 1)}-${padNumber(currentDate.getDate())}`;

    last60Days.push({ humanReadableDate, machineReadableDate });
  }

  return last60Days;
};

// Function to format the date as "Tuesday 24th March 2023"
const formatDate = (date) => {
  const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};

// Function to pad a number with leading zeros if necessary
const padNumber = (number) => {
  return number.toString().padStart(2, '0');
};

// export default getLast60Days;

