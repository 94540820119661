import React, { useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { useRoute } from "@react-navigation/native";
import BackButton from "../components/BackButton";
import { Storage } from "aws-amplify";
import { Image } from "expo-image";

import { API, graphqlOperation } from "aws-amplify";
import { BoldText } from "../constants/styledText";
import { Video } from "expo-av";

export const getCriteria = /* GraphQL */ `
  query GetCriteria($id: ID!) {
    getCriteria(id: $id) {
      id
      name
      aaa
      description
      logoColourURI
      colour
      strapline
      categoryName
      categoryStrapline
      categoryLogoURL
      videoURL
      synonyms
      level1Description
      level2Description
      level3Description
      level4Description
      level5Description
      level6Description
      Mappings {
        nextToken
        startedAt
        __typename
      }
      logoWhiteURI
      logoGreyURI
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
const CriteriaPopUp = ({ navigation }) => {
  const route = useRoute();
  const { width } = useWindowDimensions();
  const { criteriaID } = route.params;
  console.log(criteriaID);

  const colorScheme = useColorScheme();

  const [criteriaQueryResponse, setCriteriaQueryResponse] = useState([]); //state variabel for query data
  const [downloadedImageURI, setDownloadedImageURI] = useState(null); //state variable for imageURI
  const [downloadedVideoURI, setDownloadedVideoURI] = useState(null); //state variable for imageURI

  const fetchCriteria = async () => {
    const response = await API.graphql(graphqlOperation(getCriteria, { id: criteriaID })); //run query
    setCriteriaQueryResponse(response.data.getCriteria); //store query data in state variable

    const file = await Storage.get(response.data.getCriteria.logoWhiteURI, { level: "public" }); //get file using query data
    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page

    const video = await Storage.get(response.data.getCriteria.videoURL, { level: "public" }); //get file using query data
    setDownloadedVideoURI(video); //store imageURI in state variable to be used elsewhere on the page
  };
  useEffect(() => {
    fetchCriteria();
  }, []);

  const video = React.useRef(null);

  useEffect(() => {
    // Set up your video playback when the component mounts
    // ...

    // Define a cleanup function
    const stopAndUnloadVideo = async () => {
      if (video.current) {
        await video.current.stopAsync();
        await video.current.unloadAsync();
      }
    };

    // Add an event listener to handle cleanup when the modal is dismissed
    const unsubscribeFocus = navigation.addListener("focus", () => {
      // Optional: Additional setup when the modal gains focus
    });

    const unsubscribeBlur = navigation.addListener("blur", () => {
      // Handle cleanup when the modal loses focus (dismissed)
      stopAndUnloadVideo();
    });

    // Clean up event listeners when the component unmounts
    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
      stopAndUnloadVideo();
    };
  }, [navigation]);

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      {criteriaQueryResponse ? (
        <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10, alignItems: "center" }}>
          <View style={{ width: "90%", marginTop: 20, height: 200, backgroundColor: criteriaQueryResponse.colour, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
            <View style={{ backgroundColor: "transparent", width: "100%", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <View style={{ padding: 20, backgroundColor: "transparent", maxWidth: "60%" }}>
                <BoldText style={{ fontSize: width / 20, color: "white" }}>{criteriaQueryResponse.name}</BoldText>
                <Text style={{ fontSize: width / 40, color: "white" }}>{criteriaQueryResponse.strapline}</Text>
              </View>

              <Image source={downloadedImageURI} contentFit={"contain"} style={{ marginHorizontal: 20, height: width / 4, width: width / 4 }} />
            </View>
          </View>
          <View style={{ width: "90%", marginBottom: 20, flex: 1, backgroundColor: Colours[colorScheme].background, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
            {/* <Video
              ref={video}
              style={[{ alignSelf: "center", width: "100%", aspectRatio: 16 / 9 }]}
              source={{
                uri: downloadedVideoURI,
              }}
              useNativeControls
              shouldPlay
              resizeMode={"contain"}
            /> */}
            <View style={{ backgroundColor: "transparent", padding: 20 }}>
              <Text style={{ color: Colours[colorScheme].text }}>{criteriaQueryResponse.description}</Text>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default CriteriaPopUp;

const styles = StyleSheet.create({});
