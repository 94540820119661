import { FlatList, Pressable, StyleSheet } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext, AllUsersContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { getSuffix } from "../functions/getSuffix";
import { useFocusEffect } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";
import LeagueListItem from "../components/LeagueListItem";
import BackButton from "../components/BackButton";
const GroupingData2LeagueTable = ({ route }) => {
  const { sortedCoachingGroups, time } = route.params;

  const { id, Enrolments } = useContext(UserContext);
  const navigation = useNavigation();
  const thisUsersCoachingGroup = Enrolments.items[0]?.groupingData2;
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);

  // Find the index of the user's coaching group in the data array
  const indexOfUserGroup = sortedCoachingGroups.findIndex((item) => item[0] === thisUsersCoachingGroup);

  // Ref to FlatList for scrolling
  const flatListRef = useRef(null);

  // Function to scroll to the user's coaching group
  const scrollToUserGroup = () => {
    if (indexOfUserGroup !== -1 && flatListRef.current) {
      setTimeout(() => flatListRef.current.scrollToIndex({ index: indexOfUserGroup, animated: true }), 500);
    }
  };

  // getItemLayout function to provide item height
  const getItemLayout = (data, index) => ({ length: 70, offset: 70 * index, index }); // Replace 50 with the actual height of your list items

  // useEffect to scroll to the user's coaching group when the component mounts
  useEffect(() => {
    scrollToUserGroup();
  }, [time]);

  // useFocusEffect to scroll to the user's coaching group every time the screen comes into focus
  useFocusEffect(
    React.useCallback(() => {
      scrollToUserGroup();
    }, [time])
  );

    

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10 }}>


      <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, width:'100%',justifyContent:'center', flexDirection: "row", borderTopRightRadius: 10,borderTopLeftRadius:10, alignItems: "center", }}>
          <BackButton />
         
          <BoldText style={{ color: Colours[colorScheme].textLessEmphasised, fontSize: 24, alignSelf: "center", marginVertical: 6 }}>HOUSE POINTS:</BoldText>
          <Text style={{ fontWeight: "800", fontSize: 20, marginLeft: 10, color: Colours[colorScheme].textLessEmphasised }}>This {time}</Text>
        </View>


        <FlatList
          data={sortedCoachingGroups}
          ref={flatListRef}
          getItemLayout={getItemLayout}
          renderItem={({ item, index}) => ( <LeagueListItem item={item} index={index} indexOfUserGroup={indexOfUserGroup} />
            
          )}
        />
      </View>
    </View>
  );
};

export default GroupingData2LeagueTable;

const styles = StyleSheet.create({});
