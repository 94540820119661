import React from "react";
import Svg, { Path } from "react-native-svg";

const TrainingIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 358.8 213.2">
<Path d="M312.2,46.3c-36.8-9.7-82.8-15.4-132.6-15.4S83.9,36.7,47,46.3c-8.5,18.5-13.2,39-13.2,60.6S38.5,149,47,167.5
	c36.8,9.7,82.8,15.4,132.6,15.4s95.7-5.7,132.6-15.4c8.5-18.5,13.2-39,13.2-60.6S320.6,64.8,312.2,46.3z M100.9,146.1
	c-20.5,0-36.5-12.5-40.2-30.8h18.2c3.1,9.6,11.8,15.9,23,15.9c7.6,0,14-3,19-9l11.2,10.4C125,141.4,114.1,146.1,100.9,146.1z
	 M120.9,91.6c-5.1-5.8-11.5-8.9-19-8.9c-11.2,0-19.8,6.2-23,15.9H60.7c3.7-18.4,19.7-30.8,40.3-30.8c13.1,0,24,4.8,31.2,13.4
	L120.9,91.6z M180.3,126.7h-2.5v-17h2.5c6.6,0,12-5.4,12-12.1s-5.4-12.1-12-12.1H161V145h-17V68.5h36.3c16,0,29,13,29,29.1
	C209.3,113.6,196.3,126.7,180.3,126.7z M298,107.2c0,21-16.7,38-37.1,38h-4.1v-17h4.1c11.1,0,20.1-9.4,20.1-21v-0.7
	c0-11.6-9-21-20.1-21H240V145h-17V68.5h37.9c20.5,0,37.1,17,37.1,38V107.2z" fill={color.color}/>
    </Svg>
        
  );
};

export default TrainingIcon;