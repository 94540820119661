import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import colours from "../../src/constants/colours"
import useColorScheme from "../../src/hooks/useColorScheme"
import { View , Text} from "../../src/constants/themedViewAndText";
const RecognitionLogoLong = () => {
    const colorScheme = useColorScheme();

    return(
<View style={{backgroundColor:'transparent',
width: '100%',
alignItems:'center',
}}>
<Svg id="Layer_1"
	 viewBox="0 0 760.18 170.68" >


	<Path d="M208.93,108.32l-9.84-14.01c-0.91,0.06-1.89,0.13-2.87,0.13h-11.28v13.88h-6.52V62.7h17.79
		c11.86,0,19.03,5.99,19.03,15.9c0,7.04-3.65,12.12-10.04,14.4l10.82,15.31H208.93z M208.73,78.6c0-6.52-4.37-10.23-12.71-10.23
		h-11.08V88.9h11.08C204.37,88.9,208.73,85.12,208.73,78.6z M266.86,102.65v5.67h-33.11V62.7h32.19v5.67h-25.68v14.01h22.87v5.54
		h-22.87v14.73H266.86z M281.46,85.51c0-13.49,10.3-23.33,24.18-23.33c7.04,0,13.16,2.41,17.33,7.1l-4.23,4.1
		c-3.52-3.71-7.82-5.41-12.84-5.41c-10.3,0-17.92,7.43-17.92,17.53c0,10.1,7.62,17.53,17.92,17.53c5.02,0,9.32-1.76,12.84-5.47
		l4.23,4.1c-4.17,4.69-10.3,7.17-17.4,7.17C291.76,108.84,281.46,99,281.46,85.51z M335.55,85.51c0-13.36,10.3-23.33,24.31-23.33
		c13.88,0,24.18,9.9,24.18,23.33s-10.3,23.33-24.18,23.33C345.84,108.84,335.55,98.87,335.55,85.51z M377.51,85.51
		c0-10.1-7.56-17.53-17.66-17.53c-10.23,0-17.79,7.43-17.79,17.53c0,10.1,7.56,17.53,17.79,17.53
		C369.96,103.04,377.51,95.61,377.51,85.51z M433.56,85.25h6.26v17.79c-4.56,3.84-10.88,5.8-17.27,5.8
		c-14.01,0-24.31-9.84-24.31-23.33c0-13.49,10.3-23.33,24.44-23.33c7.23,0,13.36,2.41,17.53,7.03l-4.04,4.04
		c-3.78-3.65-8.08-5.28-13.23-5.28c-10.56,0-18.18,7.36-18.18,17.53c0,10.04,7.62,17.53,18.12,17.53c3.85,0,7.43-0.85,10.69-2.93
		V85.25z M499.44,62.7v45.62h-5.35L466.73,74.3v34.02h-6.52V62.7h5.35l27.37,34.02V62.7H499.44z M521.09,62.7h6.52v45.62h-6.52V62.7
		z M558.3,68.37h-15.64V62.7h37.73v5.67h-15.64v39.95h-6.45V68.37z M595.44,62.7h6.52v45.62h-6.52V62.7z M619.88,85.51
		c0-13.36,10.3-23.33,24.31-23.33c13.88,0,24.18,9.9,24.18,23.33s-10.3,23.33-24.18,23.33C630.18,108.84,619.88,98.87,619.88,85.51z
		 M661.85,85.51c0-10.1-7.56-17.53-17.66-17.53c-10.23,0-17.79,7.43-17.79,17.53c0,10.1,7.56,17.53,17.79,17.53
		C654.29,103.04,661.85,95.61,661.85,85.51z M725.51,62.7v45.62h-5.34L692.8,74.3v34.02h-6.52V62.7h5.34L719,96.72V62.7H725.51z" fill={colours[colorScheme].textLessEmphasised}/>
<Path d="M91.62,28.51c-31.48,0-57,25.52-57,57c0,31.48,25.52,57,57,57s57-25.52,57-57
	C148.62,54.03,123.1,28.51,91.62,28.51z M64.92,115.52V57.06V55.5h0.88v0.02c10.75,0.23,21.55,5.16,24.03,16.51
	c0.11,0.51,0.21,1.02,0.28,1.54c0,0,0,0,0,0c1.37,8.49-2.05,17.03-9.02,21.06c-1.59,0.98-1.99,3.11-0.93,4.64l11.33,16.24
	C84.88,115.52,71.54,115.52,64.92,115.52z M104.9,115.52L93.56,99.27c-1.08-1.54-0.61-3.66,1-4.64c0.01,0,0.01-0.01,0.02-0.01
	c6.11-3.76,9.59-9.91,9.59-17.91c0-8.85-4.21-15.54-11.54-19.22c-0.29-0.15-0.58-0.29-0.88-0.43c-1.37-0.62-2.84-1.14-4.4-1.56h5.28
	v0.02c15.16,0.5,24.58,8.71,24.58,21.75c0,7.75-3.37,13.73-9.29,17.37c-1.59,0.98-1.99,3.11-0.93,4.64l11.33,16.24H104.9z" fill={colours.palette.logoGreen}/>
</Svg></View>)
}
export default RecognitionLogoLong