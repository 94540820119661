import React from "react";
import Svg, { Path } from "react-native-svg";

const TimelineIcon = (color: any) => {
  return (
    <Svg id="Layer_1" viewBox="0 0 300 300">
      <Path
        d="M241.3,145.1c-2.7-50.5-45.9-89.1-96.3-86.4c-48.1,2.6-86.2,42.9-86.3,91.1c-0.1,18.7,5.7,37,16.4,52.4
		c0.4,0.6,0.9,1.2,1.4,1.7c0,0,0,0,0,0c4.3,4.2,11.2,4.1,15.4-0.3l0.2-0.2c3.7-3.8,4.1-9.7,1-14c-16.5-23.8-16.5-55.4,0.1-79.1
		c21.9-31.4,65.1-39.1,96.5-17.2c31.4,21.9,39.1,65.1,17.2,96.5c-2.9,4.3-2.4,10,1.1,13.8l0.2,0.2c0.5,0.5,1,0.9,1.6,1.4
		c4.9,3.4,11.7,2.3,15.2-2.7c10.8-15.3,16.5-33.6,16.5-52.3C241.4,148.4,241.3,146.8,241.3,145.1z"
        fill={color.color}
      />
      <Path
        d="M197.8,218.9c-3.2-3.9-6.9-7.3-10.9-10.3c-10.6-7.8-23.5-12-36.7-12H150c-18.4,0-35.9,8.2-47.8,22.3
		c-2.4,2.8-2,7,0.8,9.4c0,0,0,0,0,0c0.3,0.2,0.6,0.4,0.9,0.6c28.5,16.7,63.7,16.7,92.2,0c3.1-1.9,4.2-6,2.3-9.1
		C198.2,219.5,198,219.2,197.8,218.9z"
        fill={color.color}
      />
      <Path
        d="M150.1,195.4C150.1,195.4,150.1,195.4,150.1,195.4c24.5,0,44.3-19.8,44.3-44.3c0-24.5-19.8-44.3-44.3-44.3
		c-24.5,0-44.3,19.8-44.3,44.3C105.8,175.6,125.7,195.4,150.1,195.4z"
        fill={color.color}
      />
    </Svg>
  );
};

export default TimelineIcon;