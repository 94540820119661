import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const RecognitionLogoIndentIcon = (color: any) => (
  <Svg
    data-name="Layer 1"
    
    viewBox="0 0 198.21 223.8"
    
  >
    <Path
      d="M204.27 198a13.18 13.18 0 0 1 3.85-18.73 75.93 75.93 0 0 0-39.54-140.75h-49.74a75.93 75.93 0 0 1 39.55 140.76 13.17 13.17 0 0 0-3.85 18.72l44.54 64.31h49.73Z"
      transform="translate(-50.59 -38.52)"
      // fill= "#00d07e"
// fill='#51cc85'
fill={color.color}
    />
    <Path
      d="M145.41 114.47a75.93 75.93 0 0 0-75.95-75.95H50.59v223.8h99.12L105.17 198a13.17 13.17 0 0 1 3.83-18.72 75.85 75.85 0 0 0 36.41-64.81Z"
      transform="translate(-50.59 -38.52)"
      // fill="#0097aa"
      // fill='#51cc85'
      fill={color.color}
    />
  </Svg>
)

export default RecognitionLogoIndentIcon