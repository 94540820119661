import { StyleSheet, ActivityIndicator} from 'react-native'
import React from 'react'
import Colours from '../constants/colours';



const ActivityIndicatorAnimation = () => {

  return (

  <ActivityIndicator size="large" color={Colours.palette.logoGreen} />
    
  )
}

export default ActivityIndicatorAnimation

const styles = StyleSheet.create({})



