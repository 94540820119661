import React, { useContext, useEffect, useState } from "react";
import { Alert, Image, Platform, Pressable, ScrollView, StyleSheet, TextInput } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import HouseLogo from "../components/HouseLogo";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import { VibrateOnce } from "../functions/vibration";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import { Picker } from "@react-native-picker/picker";
import TimelineIcon from "../../assets/icons/Timeline";

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      aaa
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      pushToken
      Enrolments {
        nextToken
        startedAt
        __typename
      }
      Opportunities {
        nextToken
        startedAt
        __typename
      }
      AwardedBadges {
        nextToken
        startedAt
        __typename
      }
      ConnectionsUsersThatSeeThisUser {
        nextToken
        startedAt
        __typename
      }
      ConnectionsUsersThisUserSees {
        nextToken
        startedAt
        __typename
      }
      MomentsContributed {
        nextToken
        startedAt
        __typename
      }
      MomentsActuallUploaded {
        nextToken
        startedAt
        __typename
      }
      Tags {
        nextToken
        startedAt
        __typename
      }
      ClubAttendanceMarks {
        nextToken
        startedAt
        __typename
      }
      AttendanceMarksRecordedBy {
        nextToken
        startedAt
        __typename
      }
      DailyPointsTotals {
        nextToken
        startedAt
        __typename
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
      }
      DeliveredTrainings {
        nextToken
        startedAt
        __typename
      }
      TrainingAttendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      Enrolments {
        items {
          contextData1
          contextData2
          contextData3
          groupingData1
          groupingData2
          groupingData3
          scoringData1
          scoringData2
          scoringData3
        }
      }
      ClubAttendanceMarks {
        nextToken
        startedAt
        items {
          id
          recordedDate
          opportunityID
          Opportunity {
            name
            logoURL
          }
        }
      }
      AwardedBadges {
        nextToken
        startedAt
        items {
          id
          scoringData3Value
          dateAwarded
          opportunityID
          Opportunity {
            id
            isActive
            name
            aaa
            colour
            logoURL
            oneOffDate
            multipleStartDate
            multipleEndDate
            opportunitycategoryID
          }
        }
      }
      TrainingAttendance {
        nextToken
        startedAt
        items {
          id
          trainingID
          Training {
            id
            venue
            date
            name
            type
            description
          }
        }
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
        items {
          id
          name
          type
          logoURL
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const AdminUserDetails = ({ route }) => {
  const { userID } = route.params;
  //   console.log("userID", userID);
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const colorScheme = useColorScheme();
  const type = userDetailsFromUserContext.type;
  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const [clubAttendanceData, setClubAttendanceData] = useState([]);
  const [trainingAttendanceData, setTrainingAttendanceData] = useState([]);
  const [userData, setUserData] = useState({});
  const [downloadedImage, setDownloadedImage] = useState();
  const house = userData ? userData.Enrolments?.items[0]?.groupingData1 : null;
  const coach = userData ? userData.Enrolments?.items[0]?.groupingData2 : null;
  const year = userData ? userData.Enrolments?.items[0]?.groupingData3 : null;
  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [isAccountSectionVisible, setIsAccountSectionVisible] = useState(true);
  const [headlineAchievements, setHeadlineAchievements] = useState([]);
  const [formData, setFormData] = useState({}); // State to track form data
  const [isDirty, setIsDirty] = useState(false); // State to track if there are unsaved changes
  const [isEnrolmentSectionVisible, setIsEnrolmentSectionVisible] = useState(true);

  console.log(formData);

  // Function to handle save action
  const handleSave = async () => {
    try {
      // Construct mutation input object with user ID and modified fields
      const input = {
        id: userID,
        _version: userData._version,
        ...formData,
      };

      // Send mutation to update user data
      const response = await API.graphql(graphqlOperation(updateUser, { input }));
      //   console.log("User data updated:", response);
      //   console.log("input", input);
      // Reset form data and set isDirty to false after successful update
      setFormData({});

      // Generate the alert message
      //   let alertMessage = `User's data has been updated 👌`;

      //   if (Platform.OS === "web") {
      //     alert(alertMessage);
      //   } else {
      //     Alert.alert(alertMessage);
      //   }
      setIsDirty(false);
    } catch (error) {
      console.error("Error updating user data:", error);
      // Handle error, e.g., display an error message to the user
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getUser, { id: userID }));

      if (response && response.data && response.data.getUser) {
        setUserData(response.data.getUser);

        if (response.data.getUser.AwardedBadges && response.data.getUser.AwardedBadges.items) {
          setAwardedBadgesData(response.data.getUser.AwardedBadges.items);
        } else {
          setAwardedBadgesData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.HeadlineAchievements && response.data.getUser.HeadlineAchievements.items) {
          setHeadlineAchievements(response.data.getUser.HeadlineAchievements.items);
        } else {
          setHeadlineAchievements([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.ClubAttendanceMarks && response.data.getUser.ClubAttendanceMarks.items) {
          setClubAttendanceData(response.data.getUser.ClubAttendanceMarks.items);
        } else {
          setClubAttendanceData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.TrainingAttendance && response.data.getUser.TrainingAttendance.items) {
          setTrainingAttendanceData(response.data.getUser.TrainingAttendance.items);
        } else {
          setTrainingAttendanceData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.photoURL) {
          const file = await Storage.get(response.data.getUser.photoURL, { level: "public" });
          setDownloadedImage(file);
        } else {
          setDownloadedImage(null); // Set to null if no photo URL is available
        }
      } else {
        // Handle the case when the response is empty or doesn't contain the expected data
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUserData();
  }, [userID]);

  //   console.log("userData", userData);

  const renderDateOfBirthPicker = () => {
    const birthDate = userData.dateOfBirth ? new Date(userData.dateOfBirth) : new Date();
    const years = [];
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 100; // Set a minimum year, e.g., 100 years ago
    for (let year = currentYear; year >= minYear; year--) {
      years.push(year.toString());
    }

    const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
    const daysInMonth = (year, month) => new Date(year, month, 0).getDate();
    const days = Array.from({ length: daysInMonth(birthDate.getFullYear(), birthDate.getMonth() + 1) }, (_, i) => (i + 1).toString().padStart(2, "0"));

    return (
      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
          <Text>Date of birth:</Text>
        </View>
        <View style={{ backgroundColor: "transparent", width: "50%", flexDirection: "row", alignItems: "center" }}>
          <Picker
            selectedValue={birthDate.getDate().toString().padStart(2, "0")}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 12,
              fontFamily: "avenir-next",
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].almostBackground,
              borderColor: Colours.palette.logoGreen,
              flex: 1,
            }}
            onValueChange={(value) => handleInputChange("day", value)}>
            {days.map((day) => (
              <Picker.Item key={day} label={day} value={day} />
            ))}
          </Picker>

          <Picker
            selectedValue={(birthDate.getMonth() + 1).toString().padStart(2, "0")}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 12,
              fontFamily: "avenir-next",
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].almostBackground,
              borderColor: Colours.palette.logoGreen,
              flex: 1,
            }}
            onValueChange={(value) => handleInputChange("month", value)}>
            {months.map((month) => (
              <Picker.Item key={month} label={month} value={month} />
            ))}
          </Picker>
          <Picker
            selectedValue={birthDate.getFullYear().toString()}
            style={{
              borderWidth: 1,
              margin: 10,
              padding: 10,
              borderRadius: 8,
              fontSize: 12,
              fontFamily: "avenir-next",
              color: Colours[colorScheme].textLessEmphasised,
              backgroundColor: Colours[colorScheme].almostBackground,
              borderColor: Colours.palette.logoGreen,
              flex: 1,
            }}
            onValueChange={(value) => handleInputChange("year", value)}>
            {years.map((year) => (
              <Picker.Item key={year} label={year} value={year} />
            ))}
          </Picker>
        </View>
      </View>
    );
  };

  const renderTextInput = (label, value, onChange) => (
    <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
      <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
        <Text>{label}:</Text>
      </View>
      <View style={{ backgroundColor: "transparent", width: "50%" }}>
        <View
          style={{
            padding: 10,
            margin: 10,
            flexDirection: "row",
            backgroundColor: Colours[colorScheme].almostBackground,
            borderColor: Colours.palette.logoGreen,
            borderWidth: 1,
            borderRadius: 8,
            alignItems: "center",
          }}>
          <TextInput
            style={[
              Platform.OS === "web" ? { outlineWidth: 0 } : null,
              {
                width: "100%",
                color: Colours[colorScheme].text,
                fontSize: 12,
                fontFamily: "avenir-next",
              },
            ]}
            value={value}
            onChangeText={onChange}
            placeholderTextColor={Colours[colorScheme].textLessEmphasised}
          />
        </View>
      </View>
    </View>
  );

  // Function to handle input change and track changes
  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: newValue,
    }));

    setIsDirty(true); // Set isDirty to true when there are unsaved changes
  };

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, width: "100%", padding: 20, backgroundColor: Colours[colorScheme].practicallyBackground, borderRadius: 10, alignItems: "center" }}>
        {/* ACCOUNT SECTION */}

        <Pressable
          onPress={() => {
            VibrateOnce();
            setIsAccountSectionVisible(!isAccountSectionVisible);
          }}
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 10,
            backgroundColor: Colours[colorScheme].almostBackground,
          }}>
          <Feather name={isAccountSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

          <BoldText style={{ color: Colours[colorScheme].text }}>Account</BoldText>
        </Pressable>

        {isAccountSectionVisible && (
          <View
            style={{
              marginBottom: 10,
              padding: 10,
              borderBottomLeftRadius: 10,
              flexDirection: "row",
              borderBottomRightRadius: 10,
              width: "100%",
              backgroundColor: Colours[colorScheme].background,
            }}>
            {!isLoading ? (
              <>
                {userData && (
                  <>
                    <View style={{ width: "50%", backgroundColor: "transparent" }}>
                      {renderTextInput("Recognition ID", userData.id, (newValue) => handleInputChange("id", newValue))}
                      {renderTextInput("Legal surname", userData.surname, (newValue) => handleInputChange("surname", newValue))}
                      {renderTextInput("Legal first name", userData.firstName, (newValue) => handleInputChange("firstName", newValue))}
                      {renderTextInput("Preferred surname", userData.preferredSurname, (newValue) => handleInputChange("preferredSurname", newValue))}
                      {renderTextInput("Preferred first name", userData.preferredFirstName, (newValue) => handleInputChange("preferredFirstName", newValue))}
                      {/* Add more fields as needed */}
                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>Gender:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            selectedValue={userData.gender}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            onValueChange={(value) => handleInputChange("gender", value)}>
                            <Picker.Item label="Male" value="M" />
                            <Picker.Item label="Female" value="F" />
                          </Picker>
                        </View>
                      </View>
                      {renderDateOfBirthPicker()}
                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>Status:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            selectedValue={userData.isActive}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            onValueChange={(value) => handleInputChange("isActive", value)}>
                            <Picker.Item label="Active" value={true} />
                            <Picker.Item label="Inactive" value={false} />
                          </Picker>
                        </View>
                      </View>
                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>Type:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            selectedValue={userData.type}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            onValueChange={(value) => handleInputChange("type", value)}>
                            <Picker.Item label="Child" value="CHILD" />
                            <Picker.Item label="Staff" value="STAFF" />
                            <Picker.Item label="Admin" value="ADMIN" />
                          </Picker>
                        </View>
                      </View>
                      {renderTextInput("Email address", userData.email, (newValue) => handleInputChange("email", newValue))}

                      {isDirty && <ActionButton icon="save" text="Save" onPressFunction={handleSave} />}
                    </View>

                    <View style={{ width: "50%", backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}>
                      {/* photo */}

                      <View
                        style={{
                          backgroundColor: "transparent",
                          width: 290,
                          marginHorizontal: 0,
                        }}>
                        {/* fall back icon that sits behind the photo */}

                        <View
                          style={{
                            height: 290,
                            width: 290,

                            borderRadius: 220,
                            borderColor: houseColor,
                            borderWidth: 5,
                          }}>
                          <TimelineIcon color={"#ccc"} />

                          <Image
                            source={{ uri: downloadedImage }}
                            style={{
                              height: 280,
                              width: 280,

                              borderRadius: 220,

                              position: "absolute",
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </>
                )}
              </>
            ) : (
              <ActivityIndicatorAnimation />
            )}
          </View>
        )}

        {!isAccountSectionVisible && (
          <View
            style={{
              marginBottom: 10,
              padding: 10,
              borderBottomLeftRadius: 10,
              // height: "60%",
              borderBottomRightRadius: 10,
              width: "100%",
              backgroundColor: Colours[colorScheme].background,
            }}></View>
        )}

        {/* ENROLMENT SECTION */}
        <Pressable
          onPress={() => {
            VibrateOnce();
            setIsEnrolmentSectionVisible(!isEnrolmentSectionVisible);
          }}
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 10,
            backgroundColor: Colours[colorScheme].almostBackground,
          }}>
          <Feather name={isEnrolmentSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

          <BoldText style={{ color: Colours[colorScheme].text }}>Enrolment</BoldText>
        </Pressable>

        {isEnrolmentSectionVisible && (
          <View
            style={{
              marginBottom: 10,
              padding: 10,
              borderBottomLeftRadius: 10,
              flexDirection: "row",
              borderBottomRightRadius: 10,
              width: "100%",
              backgroundColor: Colours[colorScheme].background,
            }}>
            {!isLoading ? (
              <>
                {userData && (
                  <>
                    <View style={{ width: "50%", backgroundColor: "transparent" }}>


                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>House:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            selectedValue={userData.Enrolments.items[0].groupingData1 ? userData.Enrolments.items[0].groupingData1 : "none selected"}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            // onValueChange={(value) => handleInputChange("isActive", value)}
                          >
                            <Picker.Item label="Bremner" value={"Bremner"} />
                            <Picker.Item label="Robinson" value={"Robinson"} />
                            <Picker.Item label="Tomlinson" value={"Tomlinson"} />
                            <Picker.Item label="Trueman" value={"Trueman"} />
                          </Picker>
                        </View>
                      </View>


                      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                        <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
                          <Text>Year group:</Text>
                        </View>
                        <View style={{ backgroundColor: "transparent", width: "50%" }}>
                          <Picker
                            selectedValue={userData.Enrolments.items[0].groupingData3 ? userData.Enrolments.items[0].groupingData3 : "none selected"}
                            style={{
                              borderWidth: 1,
                              margin: 10,
                              padding: 10,
                              borderRadius: 8,
                              fontSize: 12,
                              fontFamily: "avenir-next",
                              color: Colours[colorScheme].textLessEmphasised,
                              backgroundColor: Colours[colorScheme].almostBackground,
                              borderColor: Colours.palette.logoGreen,
                            }}
                            // onValueChange={(value) => handleInputChange("isActive", value)}
                          >
                            <Picker.Item label="Reception" value={"Reception"} />
                            <Picker.Item label="Year 1" value={"Year 1"} />
                            <Picker.Item label="Year 2" value={"Year 2"} />
                            <Picker.Item label="Year 3" value={"Year 3"} />
                            <Picker.Item label="Year 4" value={"Year 4"} />
                            <Picker.Item label="Year 5" value={"Year 5"} />
                            <Picker.Item label="Year 6" value={"Year 6"} />
                            <Picker.Item label="Year 7" value={"Year 7"} />
                            <Picker.Item label="Year 8" value={"Year 8"} />
                            <Picker.Item label="Year 9" value={"Year 9"} />
                            <Picker.Item label="Year 10" value={"Year 10"} />
                            <Picker.Item label="Year 11" value={"Year 11"} />
                            <Picker.Item label="Class of 2023" value={"Class of 2023"} />
                            <Picker.Item label="Class of 2024" value={"Class of 2024"} />
                            <Picker.Item label="Alumni" value={"Alumni"} />
                          </Picker>
                        </View>
                      </View>



                      {renderTextInput("Coaching group", userData.Enrolments.items[0].groupingData2, (newValue) => handleInputChange("groupingData2", newValue))}





                    </View>
                    <View style={{ width: "50%", backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}></View>
                  </>
                )}
              </>
            ) : (
              <ActivityIndicatorAnimation />
            )}
          </View>
        )}

        {!isEnrolmentSectionVisible && (
          <View
            style={{
              marginBottom: 10,
              padding: 10,
              borderBottomLeftRadius: 10,
              // height: "60%",
              borderBottomRightRadius: 10,
              width: "100%",
              backgroundColor: Colours[colorScheme].background,
            }}></View>
        )}
      </View>
    </View>
  );
};

export default AdminUserDetails;

const styles = StyleSheet.create({});
