import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, FlatList, TextInput, Platform, Keyboard } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { ClubAttendanceContext, OrganisationContext, UserContext } from "../hooks/Contexts";
import { Image } from "expo-image";
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import { API, Storage, graphqlOperation } from "aws-amplify";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { Feather } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import { useNavigation } from "@react-navigation/native";
import SearchBarActivity from "../components/SearchBarActivity";
import ListItemOpportunity from "../components/ListItemOpportunity";
import ListItemDate from "../components/ListItemDate";

// export const clubAttendanceByOpportunityID = /* GraphQL */ `
//   query ClubAttendanceByOpportunityID(
//     $opportunityID: ID!
//     $recordedDate: ModelStringKeyConditionInput
//     $sortDirection: ModelSortDirection
//     $filter: ModelClubAttendanceFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     clubAttendanceByOpportunityID(opportunityID: $opportunityID, recordedDate: $recordedDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//         attendanceMark
//         recordedDate
//         userID
//         User {
//           id
//           surname
//           firstName
//           preferredFirstName
//           preferredSurname
//           aaa
//           title
//           type
//           photoURL
//           dateOfBirth
//           email
//           gender
//           isActive
//           pushToken
//           createdAt
//           updatedAt
//           _version
//           _deleted
//           _lastChangedAt
//           __typename
//         }
//         opportunityID
//         recordedByID
//         createdAt
//         updatedAt
//         _version
//         _deleted
//         _lastChangedAt
//         __typename
//       }
//       nextToken
//       startedAt
//       __typename
//     }
//   }
// `;
const ActivitiesPreviousSession = ({ route }) => {
  const { opportunityID } = route.params;

  const colorScheme = useColorScheme();
  const { allClubAttendanceForThisClub } = useContext(ClubAttendanceContext);

  const clubAttendanceFromContext = allClubAttendanceForThisClub;
 
// const [allClubAttendanceForThisClubLocal, setAllClubAttendanceForThisClubLocal] = useState([]);
  const [previousSessions, setPreviousSessions] = useState([]);

  const fetchData = async () => {
    // try {
    //   const response = await API.graphql(
    //     graphqlOperation(clubAttendanceByOpportunityID, {
    //       opportunityID: opportunityID,
    //       filter: {
    //         attendanceMark: { eq: "P" },
            
    //       },
    //     })
    //   );

    



      // Extract unique dates using a Set
      const uniqueDatesSet = new Set(clubAttendanceFromContext.map((item) => item.recordedDate));
console.log('uniqueDatesSet',uniqueDatesSet)
console.log('allClubAttendanceForThisClub', allClubAttendanceForThisClub)
      // Create an array of objects with date and count
      const uniqueDatesWithCountArray = Array.from(uniqueDatesSet).map((date) => {
        return {
          
          date,
          count: clubAttendanceFromContext.filter((item) => item.recordedDate === date).length,
        };
      });
      console.log('uniqueDatesWithCountArray',uniqueDatesWithCountArray)
      // console.log('response',response.data.clubAttendanceByOpportunityID.items)

      // Convert Set back to an array and set the state
      setPreviousSessions(uniqueDatesWithCountArray);
      // setAllClubAttendanceForThisClubLocal(response.data.clubAttendanceByOpportunityID.items)
    
  };

  useEffect(() => {
    console.log('allClubAttendanceForThisClub on previous sessions screen',allClubAttendanceForThisClub)
    fetchData();
  }, [clubAttendanceFromContext]);



  // console.log(previousSessions);

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, borderRadius: 10, backgroundColor: Colours[colorScheme].practicallyBackground, alignItems: "center" }}>
        <FlatList
          data={previousSessions}
          // contentContainerStyle={{alignItems:'center'}}
          style={{ width: "100%" }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
          keyExtractor={(item) => item.date}
          renderItem={({ item }) => <ListItemDate item={item} opportunityID={opportunityID} />}
        />
      </View>
    </View>
  );
};

export default ActivitiesPreviousSession;

const styles = StyleSheet.create({});
