import { FlatList, Pressable, SectionList, StyleSheet, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useEffect, useState } from "react";
import { UserContext, AllUsersContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Auth } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react-native";
import AchievementsTabNavigator from "../navigation/achievementsTabs";
import PointsDashboard from "../components/PointsDashboard";
import CoachingIcon from "../../assets/icons/Coaching";
import { Storage } from "aws-amplify";
import PhotoListItem from "../components/PhotoListItem";
import HouseLogo from "../components/HouseLogo";
import BackButton from "../components/BackButton";
import CardTimeline from "../components/CardTimeline";
const GroupingData1List = ({ route }) => {
  const { house } = route.params;
  const [numberOfColumnsForCards, setNumberOfColumnsForCards] = useState(1);
  const colorScheme = useColorScheme();
  const { width } = useWindowDimensions();
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const [numberOfColumnsForThumbnails, setNumberOfColumnsForThumnbnails] = useState(1);



  useEffect(() => {
    let columnsForCards = 1;
    let columnsForThumbnails = 1;
  
    if (width < 375) {
      columnsForCards = 1;
      columnsForThumbnails = 1;
    } else if(width < 400) {
      columnsForCards = 1;
      columnsForThumbnails = 2;
    } else if (width < 600) {
      columnsForCards = 1;
      columnsForThumbnails = 2;
    } else if (width < 800) {
      columnsForCards = 2;
      columnsForThumbnails = 3;
    } else if (width < 990) {
      columnsForCards = 2;
      columnsForThumbnails = 4;
    } else {
      columnsForCards = 3;
      columnsForThumbnails = 4;
    }
  
    setNumberOfColumnsForCards(columnsForCards);
    setNumberOfColumnsForThumnbnails(columnsForThumbnails);
  }, [width]);








  function filterRecords(records) {
    return records.filter((record) => record.Enrolments?.items[0]?.groupingData1 === house);
  }

  const inputRecords = allUserDetailsFromAllUsersContext;
  const filteredRecords = filterRecords(inputRecords);

 // Sort the records by preferredSurname
 const sortedRecords = [...filteredRecords].sort((a, b) => {
  const surnameA = a.preferredSurname.toUpperCase();
  const surnameB = b.preferredSurname.toUpperCase();
  return surnameA.localeCompare(surnameB);
});




 // Transform data for SectionList
 const sectionedData = sortedRecords.reduce((acc, item) => {
  const groupingData3 = item.Enrolments?.items[0]?.groupingData3 || 'Other'; // Change 'Other' to a default section title if necessary
  const existingSection = acc.find((section) => section.title === groupingData3);

  if (existingSection) {
    existingSection.data.push(item);
  } else {
    acc.push({ title: groupingData3, data: [item] });
  }

  return acc;
}, []);


const sortedSectionedData = sectionedData.slice().sort((a, b) => b.title.localeCompare(a.title));



  return (





    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
    <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10 }}>

    <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, width:'100%',justifyContent:'center', flexDirection: "row", borderTopRightRadius: 10,borderTopLeftRadius:10, alignItems: "center", }}>
          <BackButton />
          <View style={{backgroundColor:'transparent', width: 28, height: 28, marginRight: 10}}><HouseLogo house={house} /></View>
          <BoldText style={{ color: Colours[colorScheme].textLessEmphasised, fontSize: 24, alignSelf: "center", marginVertical: 6 }}>{house}</BoldText>
        </View>


        <FlatList
data={sortedRecords}
contentContainerStyle={{alignItems:'center'  }}
style={{ width: '100%', }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
numColumns={numberOfColumnsForCards}
keyExtractor={(item) => item.id}
key={numberOfColumnsForCards}
renderItem={({ item }) => <CardTimeline item={item} />}
/>






      
      {/* <SectionList
        sections={sortedSectionedData}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => <PhotoListItem item={item} />}
        renderSectionHeader={({ section: { title } }) => (
          <View style={{ backgroundColor: 'lightgray', padding: 10 }}>
            <Text>{title}</Text>
          </View>
        )}
      /> */}
    </View>
  </View>


















    // <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
    //   <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10 }}>
    //     <View
    //       style={{
    //         backgroundColor: Colours[colorScheme].practicallyBackground,
    //         width: "100%",
    //         justifyContent: "center",
    //         flexDirection: "row",
    //         borderTopRightRadius: 10,
    //         borderTopLeftRadius: 10,
    //         alignItems: "center",
    //       }}>
    //         <BackButton />
    //       <View style={{ backgroundColor: "transparent", width: 28, height: 28, marginRight: 10 }}>
    //         <HouseLogo house={house} />
    //       </View>
    //       <BoldText style={{ color: Colours[colorScheme].textLessEmphasised, fontSize: 24, alignSelf: "center", marginVertical: 6 }}>{house}</BoldText>
    //     </View>
    //     <FlatList
    //       data={sortedRecords}
    //       renderItem={({ item }) => <PhotoListItem item={item} />}
    //     />
    //   </View>
    // </View>
  );
};

export default GroupingData1List;

const styles = StyleSheet.create({});
