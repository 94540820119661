import React, { useEffect, useState } from "react";

import {Image} from 'expo-image'
import { Storage } from "aws-amplify";
import { View, Text} from "../constants/themedViewAndText";

const HeadlineAchievementListItem = ({item}) => {

const [isLoading, setIsLoading] = useState(true)


    const [downloadedImage, setDownloadedImage] = useState(null);
    const fetchBadgeImage = async () => {
        try {


    if (item.logoURL) {
        const file = await Storage.get(item.logoURL,
          { level: "public" }
          );
        setDownloadedImage(file);
      } else {
        setDownloadedImage(null); // Set to null if no photo URL is available
      }

    } catch (error) {

        console.error("Error fetching image:", error);
    }

    finally {
     setIsLoading(false)
      }

    }


      useEffect(() => {
        fetchBadgeImage();
      }, []);
    
  

  return (
 
<View 


style={{backgroundColor:'transparent', margin:5}}>
  {!isLoading?
<Image 
  source={downloadedImage}
  style={{height:40, width:40}}
/>:null}
</View>

    
  );
};

export default HeadlineAchievementListItem;