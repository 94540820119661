import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FlatList, Pressable, ScrollView, StyleSheet, TextInput } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import HouseLogo from "../components/HouseLogo";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import SearchBar from '../components/SearchBar'
const AdminUsers = () => {
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const [filteredUsers, setFilteredUsers] = useState(allUserDetailsFromAllUsersContext);
  const [filteredAndSearchedUsers, setFilteredAndSearchedUsers] = useState(allUserDetailsFromAllUsersContext);
  const colorScheme = useColorScheme();
  const type = userDetailsFromUserContext.type;
  const navigation = useNavigation();

  const [nameSearch, setNameSearch] = useState("");
  const [idSearch, setIdSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [houseFilter, setHouseFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");

  const resetFilters = () => {
    setNameSearch('');
    setIdSearch('');
    setTypeFilter('');
    setHouseFilter('');
    setYearFilter('');
  };





  const ClearAllButton = () => (
    <Pressable
      style={{backgroundColor:'transparent'}}
      onPress={resetFilters}
    >
      <Text style={{color:Colours[colorScheme].text}}>Clear All</Text>
    </Pressable>
  );

  const filterUsers = () => {
    const filtered = allUserDetailsFromAllUsersContext.filter((user) => {
      // const fullName = `${user.preferredFirstName} ${user.preferredSurname}`.toLowerCase();
      // const userId = user.id;
      const userType = user.type;
      const house = user.Enrolments?.items[0]?.groupingData1;
      const year = user.Enrolments?.items[0]?.groupingData3;
  
      return (
        // (nameSearch === '' || fullName.includes(nameSearch.toLowerCase())) &&
        // (idSearch === '' || userId.includes(idSearch)) &&
        (typeFilter === '' || userType === typeFilter) &&
        (houseFilter === '' || house === houseFilter) &&
        (yearFilter === '' || year === yearFilter)
      );
    });
  
    setFilteredUsers(filtered);
  };


  useEffect(() => {
    filterUsers();
  }, [
    // nameSearch, idSearch, 
    typeFilter, houseFilter, yearFilter]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };


  // const SearchInput = ({ placeholder, value, onChangeText }) => {
  //   const inputRef = useRef(null);
  //   const [searchTerm, setSearchTerm] = useState(value);
  //   const debouncedHandleSearch = useCallback(debounce(onChangeText, 300), []);
  
  //   const handleInputChange = (text) => {
  //     setSearchTerm(text);
  //     debouncedHandleSearch(text);
  //   };
  
  //   useEffect(() => {
  //     if (value !== searchTerm) {
  //       setSearchTerm(value);
  //     }
  //   }, [value]);
  
  //   return (
  //     <View style={styles.searchContainer}>
  //       <TextInput
  //         ref={inputRef}
  //         style={styles.searchInput}
  //         placeholder={'placeholder'}
  //         value={searchTerm}
  //         onChangeText={handleInputChange}
  //       />
  //     </View>
  //   );
  // };

  // FilterDropdown component
  const FilterDropdown = ({ label, value, onValueChange, items }) => (
    // <View style={styles.filterContainer}>
      
      <Picker style={{
          outlineStyle: "none",
          color: Colours[colorScheme].text,
          backgroundColor: Colours[colorScheme].almostBackground,
          // borderColor: Colours[colorScheme].edging,
          
          borderWidth: 0,
          margin: 10,
          padding: 10,
          borderRadius: 8,
          // fontSize: 14,
          fontFamily: "avenir-next",
          
          
          }} selectedValue={value} onValueChange={onValueChange}>
        <Picker.Item label={label} value="" />
        {items.map((item) => (
          <Picker.Item key={item} label={item} value={item} />
        ))}
      </Picker>
    // </View>
  );

  const RenderItem = ({ item }) => {
    const shouldRenderItem =
      // (nameSearch === "" || `${item.preferredFirstName} ${item.preferredSurname}`.toLowerCase().includes(nameSearch.toLowerCase())) &&
      // (idSearch === "" || item.id.includes(idSearch)) &&
      (typeFilter === "" || item.type === typeFilter) &&
      (houseFilter === "" || item.Enrolments?.items[0]?.groupingData1 === houseFilter) &&
      (yearFilter === "" || item.Enrolments?.items[0]?.groupingData3 === yearFilter);

    if (!shouldRenderItem) {
      return null;
    }

    let house = item.Enrolments?.items[0]?.groupingData1;
    let year = item.Enrolments?.items[0]?.groupingData3;
    const NavigateToUserDetails = () => {
      navigation.navigate("AdminUserDetails", { userID: item.id });
    };

    return (
      <Pressable
        onPress={NavigateToUserDetails}
        style={{
          backgroundColor: Colours[colorScheme].background,
          borderBottomColor: Colours[colorScheme].edging,
          flexDirection: "row",
          borderBottomWidth: 1,
          // borderBottomColor: "gray",
          alignItems: "center",
          alignSelf: "center",
          width: "90%",
        }}>
        <View
          style={{
            padding: 10,
            width: "10%",
            alignItems: "center",
            backgroundColor: "transparent",
          }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            {item.type}
          </Text>
        </View>
        <View
          style={{
            padding: 10,
            width: "30%",
            alignItems: "center",
            backgroundColor: "transparent",
          }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            {item.preferredFirstName} {item.preferredSurname}
          </Text>
        </View>

        <View
          style={{
            padding: 10,
            width: "10%",
            alignItems: "center",
            backgroundColor: "transparent",
            flexDirection: "row",
          }}>
          {house ? (
            <View style={{ height: 20, width: 20 }}>
              <HouseLogo house={house} />
            </View>
          ) : null}
        </View>
        <View
          style={{
            padding: 10,
            width: "25%",
            alignItems: "center",
            backgroundColor: "transparent",
            flexDirection: "row",
          }}>
          {year ? (
            <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
              {year}
            </Text>
          ) : null}
        </View>
        <View
          style={{
            padding: 10,
            width: "25%",
            alignItems: "center",
            backgroundColor: "transparent",
          }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            {item.id}
          </Text>
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{backgroundColor:'transparent', justifyContent:'space-between', flexDirection:'row', padding:20}}>
       <Text>Displaying {filteredAndSearchedUsers.length} users</Text>
       <ClearAllButton />
       </View>
      <View style={{alignItems: "center",
          alignSelf: "center",
          width: "90%",
          flexDirection:'row'
        }}>

<View
          style={{
            padding: 10,
            width: "10%",
            alignItems: "center",
            backgroundColor: "transparent",
          }}>
           <FilterDropdown
          label="Type"
          value={typeFilter}
          onValueChange={setTypeFilter}
          items={['CHILD', 'STAFF', 'ADMIN', 'ALUMNI']} 
        />
        </View>
        <View
          style={{
            padding: 10,
            width: "30%",
            alignItems: "center",
            backgroundColor: "transparent",
          }}>
          <SearchBar masterData={allUserDetailsFromAllUsersContext} filteredData={filteredAndSearchedUsers} setFilteredData={setFilteredAndSearchedUsers} />

        </View>

        <View
          style={{
            padding: 10,
            width: "10%",
            alignItems: "center",
            backgroundColor: "transparent",
            flexDirection: "row",
          }}>
        
           
            <FilterDropdown
          label="House"
          value={houseFilter}
          onValueChange={setHouseFilter}
          items={['Bremner', 'Robinson', 'Tomlinson', 'Trueman']}
        />
           
          
        </View>
        <View
          style={{
            padding: 10,
            width: "25%",
            alignItems: "center",
            backgroundColor: "transparent",
            flexDirection: "row",
          }}>
          <FilterDropdown
          label="Year"
          value={yearFilter}
          onValueChange={setYearFilter}
          items={['Year R', 'Year 1', 'Year 2', 'Year 3', 'Year 4','Year 5', 'Year 6', 'Year 7', 'Year 8','Year 9', 'Year 10', 'Year 11']} // Replace with your actual years
        />
        </View>
        <View
          style={{
            padding: 10,
            width: "25%",
            alignItems: "center",
            backgroundColor: "transparent",
          }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            COACH
          </Text>
        </View>




        {/* <SearchInput
        placeholder="Search Name"
        value={nameSearch}
        onChangeText={setNameSearch}
      />
        <SearchInput
        placeholder="Search ID"
        value={idSearch}
        onChangeText={setIdSearch}
      /> */}
       
        
        
      </View>
      <View style={{ flex: 1, width: '100%', backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
        
        <FlatList
          style={{ width: '100%' }}
          data={filteredAndSearchedUsers}
          renderItem={({ item }) => <RenderItem item={item} />}
          keyExtractor={(item) => item.id}
        />
      </View>
    </View>
  );
};

export default AdminUsers;

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    padding: 10,
  },
  searchContainer: {
    // backgroundColor: Colours[colorScheme].background,
    borderRadius: 5,
    borderWidth: 1,
    // borderColor: Colours[colorScheme].edging,
    padding: 5,
    marginVertical: 5,
    width: '45%',
  },
  searchInput: {
    // color: Colours[colorScheme].text,
  },
  filterContainer: {
    // backgroundColor: Colours[colorScheme].background,
    borderRadius: 5,
    borderWidth: 1,
    // borderColor: Colours[colorScheme].edging,
    padding: 5,
    marginVertical: 5,
    width: '45%',
  },
  filterLabel: {
    // color: Colours[colorScheme].text,
    fontWeight: 'bold',
  },
  filterPicker: {
    // color: Colours[colorScheme].text,
  },
  
 
});
