import { Pressable } from "react-native";
import { Text } from "../constants/themedViewAndText";
import React, {useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";

const ActionButton = ({ icon, text, onPressFunction }) => {
  const colorScheme = useColorScheme();
  const [pressed, setPressed] = useState(false);
  return (
    <Pressable
      onPressIn={() => {
        setPressed(true);
      }}
      onPressOut={() => setPressed(false)}
      onPress={() => {
        VibrateOnce();

        onPressFunction();
      }}
      style={{
        width: 260,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: pressed ? Colours[colorScheme].background : Colours.palette.logoGreen,
        borderWidth: 1,
        borderColor: pressed ? Colours.palette.logoGreen : Colours[colorScheme].background,
        padding: 10,
        margin: 20,
        borderRadius: 10,
      }}>
      <Feather name={icon} size={24} color={pressed ? Colours.palette.logoGreen : Colours[colorScheme].background} />
      <Text style={{ color: pressed ? Colours.palette.logoGreen : Colours[colorScheme].background }}>{text}</Text>
    </Pressable>
  );
};

export default ActionButton;
