import { FlatList, Pressable, StyleSheet, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useEffect, useState } from "react";
import { UserContext, AllUsersContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Auth } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react-native";
import AchievementsTabNavigator from "../navigation/achievementsTabs";
import PointsDashboard from "../components/PointsDashboard";
import CoachingIcon from "../../assets/icons/Coaching";
import { Storage } from "aws-amplify";
import PhotoListItem from "../components/PhotoListItem";
import { useNavigation } from "@react-navigation/native";
import BackButton from "../components/BackButton";
import CardTimeline from "../components/CardTimeline";
const GroupingData2List = ({route}) => {

const {coach} = route.params
  const navigator = useNavigation()
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  
  const [numberOfColumnsForCards, setNumberOfColumnsForCards] = useState(1);
  const { width } = useWindowDimensions();
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const [numberOfColumnsForThumbnails, setNumberOfColumnsForThumnbnails] = useState(1);



  useEffect(() => {
    let columnsForCards = 1;
    let columnsForThumbnails = 1;
  
    if (width < 375) {
      columnsForCards = 1;
      columnsForThumbnails = 1;
    } else if(width < 400) {
      columnsForCards = 1;
      columnsForThumbnails = 2;
    } else if (width < 600) {
      columnsForCards = 1;
      columnsForThumbnails = 2;
    } else if (width < 800) {
      columnsForCards = 2;
      columnsForThumbnails = 3;
    } else if (width < 990) {
      columnsForCards = 2;
      columnsForThumbnails = 4;
    } else {
      columnsForCards = 3;
      columnsForThumbnails = 4;
    }
  
    setNumberOfColumnsForCards(columnsForCards);
    setNumberOfColumnsForThumnbnails(columnsForThumbnails);
  }, [width]);



  function filterRecords(records) {
    return records.filter((record) => record.Enrolments?.items[0]?.groupingData2 === coach);
  }


  const inputRecords = allUserDetailsFromAllUsersContext;
  const filteredRecords = filterRecords(inputRecords);

 // Sort the records by preferredSurname
 const sortedRecords = [...filteredRecords].sort((a, b) => {
  const surnameA = a.preferredSurname.toUpperCase();
  const surnameB = b.preferredSurname.toUpperCase();
  return surnameA.localeCompare(surnameB);
});

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10 }}>
        <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, width:'100%',justifyContent:'center', flexDirection: "row", borderTopRightRadius: 10,borderTopLeftRadius:10, alignItems: "center", }}>
          <BackButton />
          <View style={{backgroundColor:'transparent', width: 28, height: 28, marginRight: 10}}><CoachingIcon color={Colours[colorScheme].textLessEmphasised} /></View>
          <BoldText style={{ color: Colours[colorScheme].textLessEmphasised, fontSize: 24, alignSelf: "center", marginVertical: 6 }}>{coach}</BoldText>
        </View>
        <FlatList
          
          // ref={flatListRef}
          // getItemLayout={getItemLayout}
          // renderItem={({ item }) => (
            // <PhotoListItem item={item} />
            // )}
            data={sortedRecords}
contentContainerStyle={{alignItems:'center'  }}
style={{ width: '100%', }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
numColumns={numberOfColumnsForCards}
keyExtractor={(item) => item.id}
key={numberOfColumnsForCards}
renderItem={({ item }) => <CardTimeline item={item} />}
             
        
        />
      </View>
    </View>
  );
};

export default GroupingData2List;

const styles = StyleSheet.create({});
