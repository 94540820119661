import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Storage } from "aws-amplify";
import { prettyDate } from "../functions/prettyDate";
import { useNavigation } from "@react-navigation/native";
import { VibrateOnce } from "../functions/vibration";
const ListItem = ({ item }) => {
  const navigation = useNavigation();
  const {width} = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [downloadedImage, setDownloadedImage] = useState(null);
  const formattedDate = item.Opportunity.oneOffDate
    ? prettyDate(item.Opportunity.oneOffDate)
    : item.Opportunity.multipleStartDate && item.Opportunity.multipleEndDate
    ? prettyDate(item.Opportunity.multipleStartDate) + " - " + prettyDate(item.Opportunity.multipleEndDate)
    : "-";
  
  const fetchBadgeImage = async () => {
    try {
      if (item.Opportunity.logoURL) {
        const file = await Storage.get(item.Opportunity.logoURL, { level: "public" });
        setDownloadedImage(file);
      } else {
        setDownloadedImage(null); // Set to null if no photo URL is available
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    fetchBadgeImage();
  }, []);

  const NavigateToOpportunityDetail = () => {
    VibrateOnce();
    navigation.navigate("OpportunityDetail", { opportunityID: item.Opportunity.id, logo: downloadedImage });
  };


  return (
    <Pressable onPress={NavigateToOpportunityDetail}>
      <View
        style={{
          backgroundColor: "transparent",
          // maxWidth: 800,
          // marginHorizontal: 20,
          // minWidth:450,
          // maxWidth:900,
          // width:'90%',
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: 5,
          marginHorizontal: width>500? 20:5,
        }}>
          <View style={{width:35, backgroundColor:'transparent'}}>
        <Image
          source={{ uri: downloadedImage }}
          style={{
            height: 30,
            width: 30,
            backgroundColor: "white",
            borderRadius: 100,
            // borderColor: houseColor,
            // borderWidth: 3,

            // position: "absolute",
          }}
        /></View>
        <View style={{flex: 1, flexDirection:'row', backgroundColor:'transparent', alignItems:'center', justifyContent:'space-between'}}>
        <Text style={{
          // marginHorizontal: 10,
           maxWidth: "50%",
           minWidth:"20%",
            }} numberOfLines={1}>
          {item.Opportunity.name}
        </Text>
        <Text style={{
          // marginHorizontal: 10,
             maxWidth: "40%",
             minWidth:"20%",
             fontSize:9,
             textAlign:'right',
            }} numberOfLines={1}>
          {formattedDate}
        </Text>
      </View>
      </View>
    </Pressable>
  );
};

export default ListItem;
