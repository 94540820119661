import React, { useState } from "react";
import { StyleSheet, ScrollView, SectionList, Pressable } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { ClubAttendance } from "../models";
import ListItem from "../components/ListItem";
import ListItemClubAttendance from "../components/ListItemClubAttendance";
import { BoldText } from "../constants/styledText";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import FlexibleLearningHeatMap from "./FlexibleLearningHeatMap";
import { VibrateOnce } from "../functions/vibration";
import ListItemTrainingAttendance from "../components/ListItemTrainingAttendance";
const getTerm = (date) => {
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month >= 0 && month <= 2) {
    return `Spring Term ${year}`;
  } else if (month >= 3 && month <= 7) {
    return `Summer Term ${year}`;
  } else {
    return `Autumn Term ${year}`;
  }
};

const Training = ({ route }) => {
  const { training } = route.params;
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const [numberOfColumnsForBadges, setNumberOfColumnsForBadges] = useState(3);
  const [view, setView] = useState("heat");
  const [isPressing, setIsPressing] = useState(false); // State to track press status

  const sortedTrainingAttendance = training.sort((sessionA, sessionB) => {
    // Convert dateAwarded to JavaScript Date objects for comparison
    const dateA = new Date(sessionA.Training.date);
    const dateB = new Date(sessionB.Training.date);

    // Compare the dates in reverse chronological order
    return dateB - dateA;
  });

  const groupedData = sortedTrainingAttendance.reduce((acc, item) => {
    const term = getTerm(new Date(item.Training.date));
    if (!acc[term]) {
      acc[term] = [];
    }
    acc[term].push(item);
    return acc;
  }, {});

  

  const sections = Object.keys(groupedData).map((key) => ({
    title: key,
    data: groupedData[key],
  }));



  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, alignItems:'center', flex: 1 }}>
      <View style={{ backgroundColor: Colours[colorScheme].almostBackground, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, alignItems: "center", flex: 1, width: "100%" }}>
        {/* <Pressable onPress={NavigateToHeatMap}>
    <Text>heatmap</Text>
    </Pressable> */}

        {/* view toggle button */}
        <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, alignItems: "flex-end", width: "100%", padding: 5 }}>
          
        </View>

       {sections.length>0?
          <SectionList
            sections={sections}
            contentContainerStyle={{ alignSelf: "center", 
            // alignItems:'center',
            width: "100%" 
          }}
            style={{ width: "100%" }}
            key={numberOfColumnsForBadges}
            keyExtractor={(item) => item.id}
            // numColumns={numberOfColumnsForBadges}
            renderItem={({ item }) => <ListItemTrainingAttendance item={item} />}
            renderSectionHeader={({ section: { title } }) => (
              <View style={{
                width: "100%",
                backgroundColor: Colours[colorScheme].practicallyBackground, padding: 15 }}>
                <Text style={{ color: Colours[colorScheme].textLessEmphasised }}>{title}</Text>
              </View>
            )}
          />:<Text style={{padding:30, fontSize:10, color:Colours[colorScheme].textLessEmphasised}}>No CPD has been added... yet.</Text>}
      
      </View>
    </View>
  );
};

export default Training;
