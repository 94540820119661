import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TimelinesRootScreen from "../screens/timelinesRoot";
import TimelinesDetail from "../screens/timelinesDetail";
import OpportunityDetail from "../screens/opportunityDetail";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import PhotoPopUp from "../screens/photoPopUp";
import { VibrateOnce } from "../functions/vibration";
import GroupgingData2List from "../screens/groupingData2List";
import GroupingData2List from "../screens/groupingData2List";
import GroupingData3List from "../screens/groupingData3List";
import GroupingData1List from "../screens/groupingData1List";
import CriteriaPopUp from "../screens/CriteriaPopUp";
import ActivitiesRoot from "../screens/ActivitiesRoot";
import ActivitiesSelection from "../screens/ActivitiesSelection";
import ActivitiesRegister from "../screens/ActivitiesRegister";
import ActivitiesPreviousRegister from "../screens/ActivitiesPreviousRegister";
import ChangeDatePopUp from "../screens/ChangeDatePopUp";

const ActivitiesStack = createNativeStackNavigator();

export default function ActivitiesStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <ActivitiesStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colours[colorScheme].background,
        },
        headerShown: false,
        headerTitleStyle: { fontFamily: "avenir-next" },
        headerShadowVisible: false,
      }}>
      <ActivitiesStack.Screen
        name="ActivitiesRoot"
        component={ActivitiesRoot}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
<ActivitiesStack.Screen
        name="ActivitiesSelection"
        component={ActivitiesSelection}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
      <ActivitiesStack.Screen
        name="ActivitiesRegister"
        component={ActivitiesRegister}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />




<ActivitiesStack.Group
        screenOptions={{
          presentation: "modal",
          // presentation: 'transparentModal'
          // presentation:'containedTransparentModal'
        }}>
        {/* <TimeslineStack.Screen name="FlexibleLearningHeatMap" component={FlexibleLearningHeatMap} /> */}
        
        <ActivitiesStack.Screen
        name="ChangeDatePopUp"
        // initialParams={{ opportunityID:opportunityID}}
        component={ChangeDatePopUp}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
        
        
        
      </ActivitiesStack.Group>


      
    </ActivitiesStack.Navigator>
  );
}
