import React from "react";
import Svg, { Path } from "react-native-svg";

const HomeIcon = (color: any) => {
  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300"><Path d="M215.6,105.76,170.66,65.6a31,31,0,0,0-41.32,0L84.4,105.76a31,31,0,0,0-10.35,23.12v66a31,31,0,0,0,31,31H195a31,31,0,0,0,31-31v-66A31,31,0,0,0,215.6,105.76ZM204,194.87a9,9,0,0,1-9,9h-89.9a9,9,0,0,1-9-9v-66a9,9,0,0,1,3-6.71L144,82a9,9,0,0,1,12,0L201,122.17a9,9,0,0,1,3,6.71Z" fill={color.color}/></Svg>
        
  );
};

export default HomeIcon;