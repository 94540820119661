import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TimelinesRootScreen from "../screens/timelinesRoot";
import TimelinesDetail from "../screens/timelinesDetail";
import OpportunityDetail from "../screens/opportunityDetail";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import PhotoPopUp from "../screens/photoPopUp";
import { VibrateOnce } from "../functions/vibration";
import GroupgingData2List from "../screens/groupingData2List";
import GroupingData2List from "../screens/groupingData2List";
import GroupingData3List from "../screens/groupingData3List";
import GroupingData1List from "../screens/groupingData1List";
import CriteriaPopUp from "../screens/CriteriaPopUp";

const TimeslineStack = createNativeStackNavigator();

export default function TimelinesStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <TimeslineStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colours[colorScheme].background,
        },
        headerShown: false,
        headerTitleStyle: { fontFamily: "avenir-next" },
        headerShadowVisible: false,
      }}>
      <TimeslineStack.Screen
        name="TimelinesRoot"
        component={TimelinesRootScreen}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />

      <TimeslineStack.Screen
        name="TimelinesDetail"
        component={TimelinesDetail}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerTransparent: true,
          // headerStyle: { backgroundColor: "transparent" },
          // headerTintColor: Colours[colorScheme].buttons,
          // headerShadowVisible: false,
          // headerBackTitle: '',
          // title: "",
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <TimeslineStack.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
<TimeslineStack.Screen
        name="GroupingData1List"
        component={GroupingData1List}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <TimeslineStack.Screen
        name="GroupingData2List"
        component={GroupingData2List}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />

<TimeslineStack.Screen
        name="GroupingData3List"
        component={GroupingData3List}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />

      <TimeslineStack.Group
        screenOptions={{
          presentation: "modal",
          // presentation: 'transparentModal'
          // presentation:'containedTransparentModal'
        }}>
        {/* <TimeslineStack.Screen name="FlexibleLearningHeatMap" component={FlexibleLearningHeatMap} /> */}
        <TimeslineStack.Screen
          name="PhotoPopUp"
          component={PhotoPopUp}
          options={{
            headerShown: true,
            headerShadowVisible: false,
            headerTransparent: true,
            headerStyle: { backgroundColor: "transparent" },
            title: "",
            headerTintColor: Colours[colorScheme].buttons,
          }}
          listeners={() => ({
            transitionStart: () => {
              VibrateOnce();
            },
          })}
        />
        <TimeslineStack.Screen
          name="CriteriaPopUp"
          component={CriteriaPopUp}
          options={{
            headerShown: false,
            // headerShadowVisible: false,
            // headerTransparent: true,
            // headerStyle: { backgroundColor: "transparent" },
            // title: "",
            // headerTintColor: Colours[colorScheme].buttons,
          }}
          listeners={() => ({
            transitionStart: () => {
              VibrateOnce();
            },
          })}
        />
      </TimeslineStack.Group>
    </TimeslineStack.Navigator>
  );
}
