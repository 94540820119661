
export const whatShouldWeCallThisPerson = (type, title, preferredSurname, preferredFirstName ) => {
    
if (!type) {
  return 'unknown'
} 
else {

  const theThingWeShouldCallThemtype = type === "STAFF" || type === "ADMIN" ? title + " " + preferredSurname : preferredFirstName + " " + preferredSurname;

return theThingWeShouldCallThemtype;
}
  };
  