import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Storage } from "aws-amplify";
import { prettyDate } from "../functions/prettyDate";
import { useNavigation } from "@react-navigation/native";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import TrainingIcon from "../../assets/icons/Training";
const ListItemClubAttendance = ({ item }) => {

  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [downloadedImage, setDownloadedImage] = useState(null);
  const formattedDate = prettyDate(item.recordedDate);

  

  // const fetchBadgeImage = async () => {
  //   try {
  //     if (item.Opportunity.logoURL) {
  //       const file = await Storage.get(item.Opportunity.logoURL, { level: "public" });
  //       setDownloadedImage(file);
  //     } else {
  //       setDownloadedImage(null); // Set to null if no photo URL is available
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   } finally {
  //     setIsLoading(false); // Mark the loading as complete, whether success or error
  //   }
  // };

  // useEffect(() => {
  //   fetchBadgeImage();
  // }, []);

  // const NavigateToOpportunityDetail = () => {
  //   navigation.navigate("OpportunityDetail", { opportunityID: item.Opportunity.id, logo:downloadedImage });
  // };

  // console.log("item", item);
  return (
   


    <View
      style={{
        backgroundColor: "transparent",
        // width: "100%",
        // height:50,
        // maxWidth: 800,
        // marginHorizontal: 20,
        // flexDirection: "row",
        // flex: 1,
        // alignItems: "center",
        // justifyContent: "space-evenly",
        padding: 5,
        flexDirection: "row",
        marginHorizontal: width > 500 ? 20 : 5,
        justifyContent: "space-evenly",
        // flex:1,
        // flex: 1,
      }}>
      <View style={{ marginRight: 10, backgroundColor: "white", borderRadius: 100, height: 30, width: 30 }}>
        <FlexibleLearningIcon color={"#ccc"} />
        {/* <Image
        source={{ uri: downloadedImage }}
        style={{
          height: 30,
          width: 30,
          backgroundColor: "white",
          borderRadius: 100,
          // borderColor: houseColor,
          // borderWidth: 3,

          // position: "absolute",
        }}
      /> */}
      </View>
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent", alignItems: "center", justifyContent: "space-between" }}>
        {item.Opportunity ? (
          <Text style={{ maxWidth: "60%", minWidth: "20%" }} numberOfLines={1}>
            {item.Opportunity.name}
          </Text>
        ) : null}
        <Text style={{ maxWidth: "400%", minWidth: "20%", fontSize: 9, textAlign: "right" }} numberOfLines={1}>
          {formattedDate}
        </Text>
      </View>
    </View>
  
  );
};

export default ListItemClubAttendance;
