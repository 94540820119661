import { Pressable, ScrollView, StyleSheet} from 'react-native'
import {View, Text} from '../constants/themedViewAndText'
import React, { useContext, useState } from 'react'
import { UserContext, AllUsersContext } from '../hooks/Contexts';
import useColorScheme from '../hooks/useColorScheme';
import Colours from '../constants/colours';
import { Feather } from '@expo/vector-icons';
import { BoldText } from '../constants/styledText';
import { Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react-native';
import AchievementsTabNavigator from '../navigation/achievementsTabs';
import PointsDashboard from '../components/PointsDashboard';
import CrewNominationsLink from '../components/CrewNominationsLink';

const HomeScreen = () => {
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext)
  

  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const { signOut } = useAuthenticator();


  return (
    <View style={{backgroundColor:Colours[colorScheme].background, flex:1}}>
    <ScrollView style={{flex:1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius:10}}>
  
      <CrewNominationsLink />

      <PointsDashboard />

  
    </ScrollView>
    </View>
  )
}

export default HomeScreen

const styles = StyleSheet.create({})