import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, FlatList, TextInput, Platform, Keyboard } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { OrganisationContext, UserContext } from "../hooks/Contexts";
import { Image } from "expo-image";
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import { API, Storage, graphqlOperation } from "aws-amplify";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { Feather } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import { useNavigation } from "@react-navigation/native";
import SearchBarActivity from "../components/SearchBarActivity";
import ListItemOpportunity from "../components/ListItemOpportunity";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";

export const opportunitiesByCategory = /* GraphQL */ `
  query OpportunitiesByCategory($opportunitycategoryID: ID!, $sortDirection: ModelSortDirection, $filter: ModelOpportunityFilterInput, $limit: Int, $nextToken: String) {
    opportunitiesByCategory(opportunitycategoryID: $opportunitycategoryID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const ActivitiesSelectionList = ({ route }) => {
  const { currentAcademicYear } = useContext(OrganisationContext);
  const { term } = route.params;
  const { additionalGraphicURL, additionalFileURL } = useContext(OrganisationContext);
  const colorScheme = useColorScheme();
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();
  const NavigateToActivitiesRegister = () => {
    navigation.navigate("ActivitiesRegister");
  };

  const [pressed, setPressed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState([]);
  // const [masterDataFilteredByTerms, setMasterDataFilteredByTerms] = useState([]);

  // const handleKeyboardUse = (text) => {
  //   //  VibrateOnce()
  //     setSearchTerm(text)

  //   }

  // const handleSearch = () => {
  //   const text = searchTerm;
  //   const terms = text.toLowerCase().split(" ");
  //   const filteredList = filteredAndSearchedData.filter((opportunity) => {
  //     return terms.every((term) => `${opportunity.name} ${opportunity.aaa} ${opportunity.location} ${opportunity.description}`.toLowerCase().includes(term));
  //   });
  //   setFilteredAndSearchedData(filteredList);
  // };

  // useEffect(() => {
  //   handleSearch();
  // }, [searchTerm]);

  const fetchData = async () => {
    setRefreshing(true);
    try {
      let nextToken = null;
      let allOpportunities = [];

      do {
        const response = await API.graphql(
          graphqlOperation(opportunitiesByCategory, {
            opportunitycategoryID: "9b07f29f-9188-492a-9536-09ed59b3f224", //only show FLexilbe Learning clubs
            filter: {
              year: { eq: currentAcademicYear },
              term: { eq: term },
            },
            nextToken: nextToken,
          })
        );

        allOpportunities = [...allOpportunities, ...response.data.opportunitiesByCategory.items];
        nextToken = response.data.opportunitiesByCategory.nextToken;
      } while (nextToken !== null);

      setMasterData(allOpportunities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
    setRefreshing(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (masterData) {
      setFilteredData(masterData);
    }
  }, [masterData]);

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, borderRadius: 10, backgroundColor: Colours[colorScheme].practicallyBackground, alignItems: "center" }}>
        {!isLoading ? (
          <>
            {/* <Pressable onPress={() => setAut1Toggle((prevToggle) => !prevToggle)} style={{ backgroundColor: aut1Toggle ? "green" : "red" }}>
          <Feather name="check" size={24} color="black" />
          <Text>Show Autumn 1</Text>
        </Pressable>

        <Pressable onPress={() => setAut2Toggle((prevToggle) => !prevToggle)} style={{ backgroundColor: aut2Toggle ? "green" : "red" }}>
          <Feather name="check" size={24} color="black" />
          <Text>Show Autumn 2</Text>
        </Pressable> */}

            
              
                <SearchBarActivity masterData={masterData} filteredData={filteredData} setFilteredData={setFilteredData} />
                {filteredData.length > 0 ? (<FlatList
                  data={filteredData}
                  onRefresh={fetchData}
                  refreshing={refreshing}
                  // contentContainerStyle={{alignItems:'center'  }}
                  style={{ width: "100%" }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
                  // numColumns={numberOfColumnsForCards}
                  keyExtractor={(item) => item.id}
                  // key={numberOfColumnsForCards}
                  renderItem={({ item }) => <ListItemOpportunity item={item} />}
                />
              
            ) : (
              <Text style={{ fontSize: 10, color: Colours[colorScheme].textLessEmphasised, padding: 30 }}>There aren't any Flexible Learning activities for this term. 🙁</Text>
            )}

            {/* <View style={{ backgroundColor: "transparent", width: 300 }}>
          <View style={{ backgroundColor: Colours.palette.logoGreen, padding: 10, margin: 20, borderRadius: 10 }}>
            <Pressable
              onPress={() => {
                VibrateOnce();
                NavigateToActivitiesRegister();
              }}
              style={{ alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
              <Feather name="check-square" size={24} color={Colours[colorScheme].background} />
              <Text style={{ color: Colours[colorScheme].background }}>Go!</Text>
            </Pressable>
          </View>
        </View> */}
          </>
        ) : (
          <View style={{ flex: 1, backgroundColor: "transparent", justifyContent: "center" }}>
            <ActivityIndicatorAnimation />
          </View>
        )}
      </View>
    </View>
  );
};

export default ActivitiesSelectionList;

const styles = StyleSheet.create({});
