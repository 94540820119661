import { Pressable, StyleSheet, Image, Platform} from 'react-native'
import {View, Text} from '../constants/themedViewAndText'
import React, { useContext, useState } from 'react'
import { UserContext, AllUsersContext } from '../hooks/Contexts';
import useColorScheme from '../hooks/useColorScheme';
import Colours from '../constants/colours';
import { Feather } from '@expo/vector-icons';
import { BoldText } from '../constants/styledText';
import { Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react-native';
import AchievementsTabNavigator from '../navigation/achievementsTabs';
import PointsDashboard from '../components/PointsDashboard';
import RecognitionLogoIndentIcon from '../../assets/icons/RecognitionLogoIndent';
import RecognitionLogoLong from '../../assets/icons/RecognitionLogoLong';

const PhotoPopUp = ({route}) => {

  const {photo} = route.params;
console.log(route.params)
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext)
  const platform = Platform.OS

  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const { signOut } = useAuthenticator();


  return (
    <View style={{flex:1, justifyContent:'center', alignItems:'center',backgroundColor: 'transparent'}}>
  

  <Image
                  source={{ uri: photo }}
                  style={{
                    
                    width: '100%',
                    height:'100%',
// aspectRatio:1,
                    // borderRadius: 120,
                    // borderColor: houseColor,
                    // borderWidth: 3,

                    // position: "absolute",
                    resizeMode:platform === 'web'?'contain':'cover',
                  }}
                />

    <Image source={require('../../assets/images/icon.png')} style={{height:50, top:20, right:20, position: 'absolute', width:50, borderRadius:100}} />


    </View>
  )
}

export default PhotoPopUp

const styles = StyleSheet.create({})