import React from 'react'
import Svg, {Path} from 'react-native-svg';

const OpportunityIcon = (color: any) => {

    return (
       
        <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300"><Path d="M237.09,189.19a21.19,21.19,0,0,0-6.28-8.59l-31.73-26.18,7.6-20.93a21.29,21.29,0,0,0-6.45-23.66L139.42,59.66l-.06,0a11.2,11.2,0,0,0-3.42-2,11,11,0,0,0-14.08,6.63L62.37,229.71A11,11,0,0,0,69,243.78a10.82,10.82,0,0,0,3.72.66,11,11,0,0,0,10.36-7.29l16.56-46.07,36.85.26a21.25,21.25,0,0,0,20.45,26.48l60.19.43h.17a20.86,20.86,0,0,0,17.49-9.18A21.29,21.29,0,0,0,237.09,189.19Zm-78.94,6.64,9.54-26.27-60.16-.43,29.74-82.71,48.56,40.05-12.73,35,42.1,34.73Z" fill={color.color}/></Svg>
        
    )
}

export default OpportunityIcon