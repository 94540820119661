import React from "react";
import Svg, { Path } from "react-native-svg";

const FlexibleLearningIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300">
<Path d="M182.33,169.72h-22.22v-66.67h22.22V169.72z M204.56,191.94v-22.22h-22.22v22.22H204.56z M115.67,169.72h22.22
	V147.5h-22.22v-22.22H93.44v66.67h22.22V169.72z M137.89,103.06h-22.22v22.22h22.22V103.06z M249,58.61v177.78H49V58.61H249z
	 M226.78,80.83H71.22v133.33h155.56V80.83z" fill={color.color}/>
    </Svg>
        
  );
};

export default FlexibleLearningIcon;