import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer, DefaultTheme, DarkTheme } from "@react-navigation/native";
import {useWindowDimensions, Platform, Pressable, StatusBar} from 'react-native';
import { View , Text} from "../constants/themedViewAndText";
import HomeScreen from "../screens/homeRoot";
import HeaderUserProfileImage from "../components/HeaderUserProfileImage";
import HeaderLogo from "../components/HeaderRecognitionLogo";
import TimelinesRootScreen from "../screens/timelinesRoot";
import HomeIcon from "../../assets/icons/Home";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TimelineIcon from "../../assets/icons/Timeline";
import TimelinesStackNavigator from "./timelinesStack";
import RecognitionLogoLong from "../../assets/icons/RecognitionLogoLong";
import RecognitionLogoIndentIcon from "../../assets/icons/RecognitionLogoIndent";
import SettingsIcon from "../../assets/icons/Settings";
import SettingsScreen from "../screens/settingsRoot";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import { VibrateOnce } from "../functions/vibration";
import HomeStackNavigator from "./homeStack";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import ActivitiesStackNavigator from "./activitiesStack";
import SettingsStackNavigator from "./SettingsStack";

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colours.light.almostBackground,
  },
};

const Tab = createBottomTabNavigator();

const handleTabPress = ()=> {
  VibrateOnce();
}

const MyTabs = () => {
  const colorScheme = useColorScheme();
  const {width} = useWindowDimensions();
  return (
    <NavigationContainer theme={colorScheme === "dark" ? DarkTheme : MyTheme}>
      <Tab.Navigator >
      

<Tab.Group
        // initialRouteName="Home"
        screenOptions={{
         
          // headerShown:false,

          headerTitle:  () => (
           
            // Platform.OS == 'web'?
           
            <View style={{ backgroundColor: Colours[colorScheme].background,width:width/2, maxWidth:280, minWidth:200, flex: 1,
            alignItems: Platform.OS == 'web'?'flex-start':'center', //this handles the fact that iOS puts the title in the middle, but web puts it on the left
             justifyContent:'center' }}>

              <RecognitionLogoLong />
       
            </View>
            // :null
          ),
          headerShadowVisible: false,
headerStyle:{backgroundColor:Colours[colorScheme].background},
          headerTitleContainerStyle:{marginHorizontal:0,alignSelf:'center' },
          tabBarShowLabel:width<400?false:true,
          tabBarIconStyle: { width: 50, height: 50 },
          tabBarLabelStyle: {
            color: Colours[colorScheme].textLessEmphasised,
            letterSpacing: 0.5,
            fontFamily: "avenir-next",
            marginBottom: 8,
          },
          // tabBarShowLabel:false,
          // tabBarInactiveTintColor: Colours.palette.menuInactive, //OLD COLOUR SCHEME
          // tabBarActiveTintColor: Colours.palette.menuActive, //OLD COLOUR SCHEME
          tabBarActiveTintColor:Colours.palette.secondaryGreen,
          tabBarInactiveTintColor:Colours[colorScheme].edging,
          tabBarStyle: {
            backgroundColor: Colours[colorScheme].background,
            shadowColor: "transparent", //doesn't work
            height: width<500?60:95, //AJL: increases the size of the bottom tab bar
            elevation: 0, //AJL: emoves the shadow for Android
            shadowOffset: {
              width: 0,
              height: 0, // AJL: removes the shadow for iOS ( NOT WORKING, SEE borderTopWidth below which is working, leaving this in just in case it doesn't work on Android)
            },
            borderTopWidth: 0, //AJL: this works
          },
        }}>
        <Tab.Screen
          name="Home"
          component={HomeStackNavigator}

          
          listeners={()=> ({
            tabPress:() => {
              VibrateOnce();
            },

          })}

          options={{
            title: "HOME",
            // headerLeft: () => <View style={{backgroundColor: 'transparent', marginHorizontal: 20}}><HeaderLogo /></View>,

            tabBarIcon: ({ color }) => <HomeIcon color={color} />,

headerShown:true
            




          }}
        />

        <Tab.Screen
          name="Timelines"
          component={TimelinesStackNavigator}
          options={{
            title: "TIMELINES",
            // headerLeft: () => <HeaderLogo />,
            // headerRight: () => <HeaderUserProfileImage />,
            tabBarIcon: ({ color }) => <TimelineIcon color={color} />,
            headerShown:width<500?false:true
           
          }}

          listeners={()=> ({
            tabPress:() => {
              VibrateOnce();
            },

          })}
        />
        <Tab.Screen
          name="Activities"
          component={ActivitiesStackNavigator}
          options={{
            title: "ACTIVITIES",
            // headerLeft: () => <HeaderLogo />,
            // headerRight: () => <HeaderUserProfileImage />,
            tabBarIcon: ({ color }) => <FlexibleLearningIcon color={color} />,
            headerShown:width<500?false:true
           
          }}

          listeners={()=> ({
            tabPress:() => {
              VibrateOnce();
            },

          })}
        />
         <Tab.Screen
          name="Settings"
          component={SettingsStackNavigator}
          options={{
            title: "SETTINGS",
            // headerLeft: () => <HeaderLogo />,
            // headerRight: () => <HeaderUserProfileImage />,
            tabBarIcon: ({ color }) => <SettingsIcon color={color} />,
            headerShown:width<500?false:true
          }}

          listeners={()=> ({
            tabPress:() => {
              VibrateOnce();
            },

          })}
        />
</Tab.Group>
    
       




      </Tab.Navigator>
    </NavigationContainer>

  );
};

export default MyTabs;
