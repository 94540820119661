import { StyleSheet,  } from 'react-native'
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext } from 'react'
import Colours from "../constants/colours";
import { BoldText } from "../constants/styledText";



import useColorScheme from "../hooks/useColorScheme";


const HousePointForPupil = ({points}) => {
    const colorScheme = useColorScheme();

 
  return (
    <View
            style={{
              borderRadius: 5,
              borderColor: Colours[colorScheme].edging,
              borderWidth: 1,
              alignItems: "center",
              justifyContent:'center',
              padding: 5,
            }}>
            <BoldText
              style={{
                color: Colours[colorScheme].text,
                fontSize: 20,
              }}>
              {/* {calculateHousePoints()} */}
              {points}
            </BoldText>
            <Text
              style={{
                color: Colours[colorScheme].textLessEmphasised,
                fontSize: 10,
              }}>
              HOUSE POINTS
            </Text>
          </View>
  )
}

export default HousePointForPupil

const styles = StyleSheet.create({})