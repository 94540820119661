import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Storage } from "aws-amplify";
import { prettyDate } from "../functions/prettyDate";
import { useNavigation } from "@react-navigation/native";
import { VibrateOnce } from "../functions/vibration";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
const ListItemOpportunity = ({ item }) => {
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const {width} = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [downloadedImage, setDownloadedImage] = useState(null);
  // const formattedDate = item.Opportunity.oneOffDate
  //   ? prettyDate(item.Opportunity.oneOffDate)
  //   : item.Opportunity.multipleStartDate && item.Opportunity.multipleEndDate
  //   ? prettyDate(item.Opportunity.multipleStartDate) + " - " + prettyDate(item.Opportunity.multipleEndDate)
  //   : "-";
  
  const fetchBadgeImage = async () => {
    try {
      if (item.logoURL) {
        const file = await Storage.get(item.logoURL, { level: "public" });
        setDownloadedImage(file);
      } else {
        setDownloadedImage(null); // Set to null if no photo URL is available
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    fetchBadgeImage();
  }, []);

  const NavigateToActivitiesRegister = () => {
    VibrateOnce();
    navigation.navigate("ActivitiesRegister", { opportunityID: item.id, logo: downloadedImage, name:item.name, term:item.term, item });
  };

// console.log(item.logoURL)
  return (
    <Pressable onPress={NavigateToActivitiesRegister}>
      
      
      <View
        style={{
          // backgroundColor: Colours[colorScheme].almostBackground,
          backgroundColor:'transparent',
          // maxWidth: 800,
          // marginHorizontal: 20,
          // minWidth:450,
          // maxWidth:900,
          // width:'90%',
          flexDirection: "row",
          flex: 1,
          // alignItems: "center",
          // justifyContent: "space-evenly",
          padding: 5,

          // marginHorizontal: width>500? 20:5,
        }}>


    <View style={{width:50, height:50, backgroundColor:'transparent'}}>
{downloadedImage?
        <Image
          source={{ uri: downloadedImage }}
          style={{
            height: 50,
            width: 50,
            backgroundColor: "white",
            borderRadius: 100,
            // borderColor: houseColor,
            // borderWidth: 3,

            position: "absolute",
          }}
        />:<FlexibleLearningIcon color={"#ccc"} />}</View> 
       
        
      
        <View style={{flex: 1, padding:10,flexDirection:'row', backgroundColor:'transparent', alignItems:'center', justifyContent:'space-between'}}>
        
          <Text style={{
          fontSize:14,
          // maxWidth:'60%',
        }}
      
            numberOfLines={1}
            >
          {item.name}
        </Text>
        {/* <Text style={{
       fontSize:12,
            }} numberOfLines={1}>
          {item.term}
        </Text> */}
      </View>
  

      </View>
    </Pressable>
  );
};

export default ListItemOpportunity;
