import React from 'react'
import Svg, {Path } from 'react-native-svg';

const SettingsIcon = (color: any) => {
    return (
       
        <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300"><Path d="M177.94,217.46a73.94,73.94,0,0,0,8.74-4.32l18.25,5a6.05,6.05,0,0,0,5.64-1.36c2.18-2,4.25-4.05,6.21-6.21a6,6,0,0,0,1.35-5.64l-5-18.25a72.5,72.5,0,0,0,7.44-17.95L237,159.34a6,6,0,0,0,3-4.94c.14-2.92.15-5.85,0-8.79a6,6,0,0,0-3-5l-16.45-9.37a71.62,71.62,0,0,0-3.12-9.23,73.61,73.61,0,0,0-4.31-8.72l5-18.27a6,6,0,0,0-1.33-5.61,86.25,86.25,0,0,0-6.27-6.27,6,6,0,0,0-5.61-1.33l-18.26,5a72.47,72.47,0,0,0-17.95-7.43L159.34,63a6.06,6.06,0,0,0-5-3c-2.91-.14-5.84-.14-8.77,0a6.08,6.08,0,0,0-5,3l-9.37,16.44a72.86,72.86,0,0,0-18,7.44l-18.25-5a6.07,6.07,0,0,0-5.65,1.36,86.48,86.48,0,0,0-6.2,6.21,6,6,0,0,0-1.36,5.64l5,18.25a72.47,72.47,0,0,0-7.43,18L63,140.65a6,6,0,0,0-3,4.95,85.9,85.9,0,0,0,0,8.79,6,6,0,0,0,3,5l16.44,9.38A75.38,75.38,0,0,0,82.54,178a74.43,74.43,0,0,0,4.32,8.72l-5,18.26a6,6,0,0,0,1.33,5.62,88.16,88.16,0,0,0,6.27,6.26,6,6,0,0,0,5.61,1.33l18.26-5a72.54,72.54,0,0,0,18,7.43L140.66,237a6,6,0,0,0,4.94,3q4.36.21,8.78,0a6.05,6.05,0,0,0,5-3l9.38-16.44A73.18,73.18,0,0,0,177.94,217.46Zm-69.41-50.28a44.89,44.89,0,1,1,58.65,24.3A44.89,44.89,0,0,1,108.53,167.18Z" fill={color.color}/></Svg>
                  
    )
}

export default SettingsIcon