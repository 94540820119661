import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TimelinesRootScreen from "../screens/timelinesRoot";
import TimelinesDetail from "../screens/timelinesDetail";
import OpportunityDetail from "../screens/opportunityDetail";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import PhotoPopUp from "../screens/photoPopUp";
import { VibrateOnce } from "../functions/vibration";
import GroupgingData2List from "../screens/groupingData2List";
import GroupingData2List from "../screens/groupingData2List";
import GroupingData3List from "../screens/groupingData3List";
import GroupingData1List from "../screens/groupingData1List";
import CriteriaPopUp from "../screens/CriteriaPopUp";
import ActivitiesRoot from "../screens/ActivitiesRoot";
import ActivitiesSelection from "../screens/ActivitiesSelection";
import ActivitiesRegister from "../screens/ActivitiesRegister";
import ActivitiesPreviousRegister from "../screens/ActivitiesPreviousRegister";
import ChangeDatePopUp from "../screens/ChangeDatePopUp";
import SettingsScreen from "../screens/settingsRoot";
import AdminStackNavigator from "./adminStack";

const SettingsStack = createNativeStackNavigator();

export default function SettingsStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <SettingsStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colours[colorScheme].background,
        },
        headerShown: false,
        headerTitleStyle: { fontFamily: "avenir-next" },
        headerShadowVisible: false,
      }}>
      <SettingsStack.Screen
        name="SettingsRoot"
        component={SettingsScreen}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
<SettingsStack.Screen
        name="AdminStack"
        component={AdminStackNavigator}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
     





        


      
    </SettingsStack.Navigator>
  );
}
