import { StyleSheet, Pressable, Image} from "react-native";
// import { Image } from 'expo-image';
import { View, Text } from "../constants/themedViewAndText";
import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import TimelineIcon from "../../assets/icons/Timeline";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { BoldText } from "../constants/styledText";
import HouseLogo from "./HouseLogo";
import CoachingIcon from "../../assets/icons/Coaching";
import { useNavigation } from "@react-navigation/native";
import Animated from 'react-native-reanimated';
import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp} from 'react-native-reanimated';
import { VibrateOnce } from "../functions/vibration";
import { whatShouldWeCallThisPerson } from "../functions/whatShouldWeCallThisPerson";


const CardTimeline = ({ item }) => {
  const { id, photoURL, preferredFirstName, preferredSurname, title, type } = item;
  const house = item.Enrolments?.items[0]?.groupingData1;
  const coach = item.Enrolments?.items[0]?.groupingData2;
  const year = item.Enrolments?.items[0]?.groupingData3;
  const colorScheme = useColorScheme();
  const [downloadedImage, setDownloadedImage] = useState(null);
  const navigation = useNavigation();
  // const whatShouldWeCallThisPerson = type === "STAFF" || type === "ADMIN" ? title + " " + preferredSurname : preferredFirstName + " " + preferredSurname;

  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";

  const NavigateToTimelineDetail = () => {
    navigation.navigate("TimelinesDetail", { userID: id });
  };

  const fetchPhoto = async () => {
    const file = await Storage.get(photoURL, { level: "public" });
    setDownloadedImage(file);
  };

  useEffect(() => {
    if (photoURL) {
      fetchPhoto();
    } else {
    
      console.log('no photo for this user')
    }
  }, []);

  return (
    <Pressable onPress={()=>{
      VibrateOnce()
      NavigateToTimelineDetail()
    }
      }>
      <Animated.View
      // entering={FadeInLeft} exiting={FadeOutRight}
        style={{
          borderRadius: 10,
          // borderWidth: 1,
          padding: 10,
          // marginHorizontal: 3,
          // marginVertical: 8,
          margin: 10,
          // alignContent:'center',
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: Colours[colorScheme].background,
          flexDirection: "row",
          // backgroundColor: houseColor,
          width: 310,
          height: 180,
        }}>
        {/* <Text>{downloadedImage}</Text> */}

        <View style={{ flex: 1, backgroundColor: "transparent", marginHorizontal: 5, height: 150, alignItems: "flex-start", justifyContent: "space-between" }}>
          <View style={{ backgroundColor: "transparent" }}>
            <BoldText numberOfLines={3} style={{ fontSize: 20, color: Colours[colorScheme].textLessEmphasised }}>{whatShouldWeCallThisPerson(type, title, preferredSurname, preferredFirstName)}</BoldText>
            <Text style={{ fontSize: 18 }}>{year}</Text>
          </View>
          <View style={{ backgroundColor: "transparent" }}>
            {coach && (
              <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                <View style={{ width: 18, height: 18, backgroundColor: "transparent", marginHorizontal: 5 }}>
                  <CoachingIcon color={Colours[colorScheme].text} />
                </View>
                <Text>{coach}</Text>
              </View>
            )}

            {house && (
              <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                <View style={{ width: 18, height: 18, backgroundColor: "transparent", marginHorizontal: 5 }}>
                  <HouseLogo house={house} />
                </View>
                <Text>{house}</Text>
              </View>
            )}
          </View>
        </View>
        <View style={{ backgroundColor: "transparent", height: 130, width: 130, marginHorizontal: 5 }}>
        <View style={{height: 130,
                    width: 130,

                    borderRadius: 120,
                    borderColor: houseColor,
                    borderWidth: 5,}}>
          
          <TimelineIcon color={"#ccc"} />


        



{downloadedImage ? 
          <Image
            source={{ uri: downloadedImage }}
            // cachePolicy='memory-disk'
            // tintColor="gray"
            style={{
              height: 120,
              width: 120,
             
              borderRadius: 100,
              // borderColor: houseColor,
              // borderWidth: 3,

              position: "absolute",
            }}
            accessibilityLabel={preferredFirstName}
          
            //   placeholder={require('../../assets/images/')}
          />:null}
          {/* {year === 'Class of 2023'?<View style={{backgroundColor:'dark-brown', width: '100%', height: '100%', position:'absolute', borderRadius:100,opacity:0.3}}></View>:null} */}
          </View>
        </View>
      </Animated.View>
    </Pressable>
  );
};

export default CardTimeline;

const styles = StyleSheet.create({});
