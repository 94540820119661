import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Storage } from "aws-amplify";
import { getSuffix } from "../functions/getSuffix";
import { prettyDate } from "../functions/prettyDate";
import { useNavigation } from "@react-navigation/native";
import { VibrateOnce } from "../functions/vibration";
const LeagueListItem = ({ item, index, indexOfUserGroup }) => {


const coach = item[0]



  const navigation = useNavigation();
  const {width} = useWindowDimensions();
  const colorScheme = useColorScheme();
  const navigateToGroupingData2List = () => {
 VibrateOnce()
      navigation.navigate("GroupingData2List", {coach });
    }

  return (
    <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "flex-start",
                paddingHorizontal: 20,
                height: 70,
                alignItems: "center",
                backgroundColor: index == indexOfUserGroup ? Colours.palette.secondaryGreen : "transparent",
              }}>
              <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Text style={{ color: index===indexOfUserGroup?Colours.dark.background:Colours[colorScheme].text }}>
                  {`${index + 1}`}

                  {getSuffix(index + 1)}
                </Text>
                {index < 3 && <Feather name="award" size={30} color={index === 0 ? "gold" : index === 1 ? "silver" : "#cd7f32"} />}
              </View>

              <Pressable onPress={
                navigateToGroupingData2List}>
                <BoldText style={{ color:index===indexOfUserGroup?Colours.dark.background: Colours[colorScheme].text }}>{item[0]}</BoldText>
              </Pressable>
              <Text style={{ color: index===indexOfUserGroup?Colours.dark.background:Colours[colorScheme].text }}>{item[1]}</Text>
            </View>
  );
};

export default LeagueListItem;
