import { Pressable, ScrollView, StyleSheet, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useEffect, useState } from "react";
import { OrganisationContext, UserContext } from "../hooks/Contexts";
import { API, graphqlOperation } from "aws-amplify";
import { calculateHousePointTotals } from "../functions/calculateHousePointTotals";
import Colours from "../constants/colours";
import TomlinsonIcon from "../../assets/icons/Tomlinson";
import TruemanIcon from "../../assets/icons/Trueman";
import BremnerIcon from "../../assets/icons/Bremner";
import { Colors } from "react-native/Libraries/NewAppScreen";
import RobinsonIcon from "../../assets/icons/Robinson";
import HouseBar from "./HousePointsBar";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import useColorScheme from "../hooks/useColorScheme";
import { getSuffix } from "../functions/getSuffix";
import ActivityIndicatorAnimation from "./ActivityIndicator";
import { useNavigation } from "@react-navigation/native";
import { VibrateOnce } from "../functions/vibration";

export const listAwardedBadges = /* GraphQL */ `
  query ListAwardedBadges($filter: ModelAwardedBadgeFilterInput, $nextToken: String) {
    listAwardedBadges(filter: $filter, limit: 99999999, nextToken: $nextToken) {
      items {
        id
        dateAwarded
        scoringData3Value
        groupingData1Value
        groupingData2Value
        groupingData3Value
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const createDailyPointsTotal = /* GraphQL */ `
  mutation CreateDailyPointsTotal($input: CreateDailyPointsTotalInput!, $condition: ModelDailyPointsTotalConditionInput) {
    createDailyPointsTotal(input: $input, condition: $condition) {
      id
      organisationID
      groupType
      group
      date
      totalHousePoints
      recordedByID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const dailyPointsTotalByDate = /* GraphQL */ `
  query DailyPointsTotalByDate($date: AWSDate!, $sortDirection: ModelSortDirection, $filter: ModelDailyPointsTotalFilterInput, $nextToken: String) {
    dailyPointsTotalByDate(date: $date, sortDirection: $sortDirection, filter: $filter, limit: 9999999, nextToken: $nextToken) {
      items {
        id
        groupType
        group
        date
        totalHousePoints
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const PointsDashboard = () => {
  const { currentAcademicYearStartDate, organisationID } = useContext(OrganisationContext);
  const { id, Enrolments } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const thisUsersCoachingGroup = Enrolments.items[0]?.groupingData2;
  const today = new Date().toISOString().slice(0, 10);
  const lastWeekDate = new Date();
  lastWeekDate.setDate(lastWeekDate.getDate() - 7);
  const lastWeekDateString = lastWeekDate.toISOString().slice(0, 10);
  const [dailyPointsTotalsByGroup, setDailyPointsTotalsByGroup] = useState(null);
  const colorScheme = useColorScheme();
  const [bremnerTotalYear, setBremnerTotalYear] = useState(0);
  const [tomlinsonTotalYear, setTomlinsonTotalYear] = useState(0);
  const [truemanTotalYear, setTruemanTotalYear] = useState(0);
  const [robinsonTotalYear, setRobinsonTotalYear] = useState(0);
  const [totalYear, setTotalYear] = useState(0);
  const [bremnerTotalWeek, setBremnerTotalWeek] = useState(0);
  const [tomlinsonTotalWeek, setTomlinsonTotalWeek] = useState(0);
  const [truemanTotalWeek, setTruemanTotalWeek] = useState(0);
  const [robinsonTotalWeek, setRobinsonTotalWeek] = useState(0);
  const [totalWeek, setTotalWeek] = useState(0);
  const navigation = useNavigation();
  const [sortedCoachingGroupsThisWeek, setSortedCoachingGroupsThisWeek] = useState([]);
  const WidthOfBarChart = 250;
  const { width } = useWindowDimensions();
  const calculateTruemanWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTruemanWidthWeekResult = ((WidthOfBarChart * truemanTotalWeek) / totalWeek) * ScalingFactorWeek;
    if (isNaN(calculateTruemanWidthWeekResult)) {
      return 0;
    } else {
      return calculateTruemanWidthWeekResult;
    }
  };

  const calculateTomlinsonWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTomlinsonWidthWeekResult = ((WidthOfBarChart * tomlinsonTotalWeek) / totalWeek) * ScalingFactorWeek;
    if (isNaN(calculateTomlinsonWidthWeekResult)) {
      return 0;
    } else {
      return calculateTomlinsonWidthWeekResult;
    }
  };

  const calculateRobinsonWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateRobinsonWidthWeekResult = ((WidthOfBarChart * robinsonTotalWeek) / totalWeek) * ScalingFactorWeek;
    if (isNaN(calculateRobinsonWidthWeekResult)) {
      return 0;
    } else {
      return calculateRobinsonWidthWeekResult;
    }
  };

  const calculateBremnerWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateBremnerWidthWeekResult = ((WidthOfBarChart * bremnerTotalWeek) / totalWeek) * ScalingFactorWeek;

    if (isNaN(calculateBremnerWidthWeekResult)) {
      return 0;
    } else {
      return calculateBremnerWidthWeekResult;
    }
  };

  const calculateTruemanWidthYear = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTruemanWidthEverResult = ((WidthOfBarChart * truemanTotalYear) / totalYear) * ScalingFactorEver;
    if (isNaN(calculateTruemanWidthEverResult)) {
      return 0;
    } else {
      return calculateTruemanWidthEverResult;
    }
  };

  const calculateTomlinsonWidthYear = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTomlinsonWidthEverResult = ((WidthOfBarChart * tomlinsonTotalYear) / totalYear) * ScalingFactorEver;
    if (isNaN(calculateTomlinsonWidthEverResult)) {
      return 0;
    } else {
      return calculateTomlinsonWidthEverResult;
    }
  };

  const calculateRobinsonWidthYear = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateRobinsonWidthEverResult = ((WidthOfBarChart * robinsonTotalYear) / totalYear) * ScalingFactorEver;
    if (isNaN(calculateRobinsonWidthEverResult)) {
      return 0;
    } else {
      return calculateRobinsonWidthEverResult;
    }
  };

  const calculateBremnerWidthYear = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateBremnerWidthEverResult = ((WidthOfBarChart * bremnerTotalYear) / totalYear) * ScalingFactorEver;

    if (isNaN(calculateBremnerWidthEverResult)) {
      return 0;
    } else {
      return calculateBremnerWidthEverResult;
    }
  };

  const getTopCoachingGroupsThisYear = (data) => {
    if (!data || !data.today || !data.today.groupingData2) {
      return {}; // Return an empty object or handle the case when data is not available
    }

    const sortedGroups = Object.entries(data.today.groupingData2).sort(([, a], [, b]) => b - a);

    const topGroups = {
      firstPlace: null,
      secondPlace: null,
      thirdPlace: null,
    };

    for (const [group, housePoints] of sortedGroups) {
      if (!topGroups.firstPlace && group !== "null") {
        topGroups.firstPlace = { group, housePoints };
      } else if (!topGroups.secondPlace && group !== "null") {
        topGroups.secondPlace = { group, housePoints };
      } else if (!topGroups.thirdPlace && group !== "null") {
        topGroups.thirdPlace = { group, housePoints };
      }

      if (topGroups.firstPlace && topGroups.secondPlace && topGroups.thirdPlace) {
        break; // All places are filled
      }
    }

    return topGroups;
  };

  const getTopCoachingGroupsThisWeek = (data) => {
    if (!data || !data.lastWeek || !data.lastWeek.groupingData2) {
      return {}; // Return an empty object or handle the case when data is not available
    }

    const sortedGroups = Object.entries(data.lastWeek.groupingData2).sort(([, a], [, b]) => b - a);

    const topGroups = {
      firstPlace: null,
      secondPlace: null,
      thirdPlace: null,
    };

    for (const [group, housePoints] of sortedGroups) {
      if (!topGroups.firstPlace && group !== "null") {
        topGroups.firstPlace = { group, housePoints };
      } else if (!topGroups.secondPlace && group !== "null") {
        topGroups.secondPlace = { group, housePoints };
      } else if (!topGroups.thirdPlace && group !== "null") {
        topGroups.thirdPlace = { group, housePoints };
      }

      if (topGroups.firstPlace && topGroups.secondPlace && topGroups.thirdPlace) {
        break; // All places are filled
      }
    }

    return topGroups;
  };

  const getAllCoachingGroupsSortedThisWeek = (data) => {
    if (!data || !data.lastWeek || !data.lastWeek.groupingData2) {
      return {}; // Return an empty object or handle the case when data is not available
    }

    const filteredGroups = Object.entries(data.lastWeek.groupingData2)
    .filter(([groupName]) => groupName !== 'null');

    const sortedGroups = filteredGroups.sort(([, a], [, b]) => b - a);

    return sortedGroups;
  };

  const getAllCoachingGroupsSortedThisYear = (data) => {
    if (!data || !data.today || !data.today.groupingData2) {
      return {}; // Return an empty object or handle the case when data is not available
    }
  
    const filteredGroups = Object.entries(data.today.groupingData2)
      .filter(([groupName]) => groupName !== 'null');
  
    const sortedGroups = filteredGroups.sort(([, a], [, b]) => b - a);
  
    return sortedGroups;
  };
  
  

  const drawTomlinsonIcon = () => <TomlinsonIcon color={Colours.palette.tomlinson} />;
  const drawTruemanIcon = () => <TruemanIcon color={Colours.palette.trueman} />;
  const drawBremnerIcon = () => <BremnerIcon color={Colours.palette.bremner} />;
  const drawRobinsonIcon = () => <RobinsonIcon color={Colours.palette.robinson} />;

  var HousePointsPercentagesEver = {
    Tomlinson: tomlinsonTotalYear / totalYear,
    Bremner: bremnerTotalYear / totalYear,
    Trueman: truemanTotalYear / totalYear,
    Robinson: robinsonTotalYear / totalYear,
  };

  //use the largest percetngage value to caluclate a scaling factor
  const arrEver = Object.values(HousePointsPercentagesEver);
  const maxEver = Math.max(...arrEver);
  var ScalingFactorEver = WidthOfBarChart / (maxEver * WidthOfBarChart);

  var HousePointsPercentagesWeek = {
    Tomlinson: tomlinsonTotalWeek / totalWeek,
    Bremner: bremnerTotalWeek / totalWeek,
    Trueman: truemanTotalWeek / totalWeek,
    Robinson: robinsonTotalWeek / totalWeek,
  };

  //use the largest percetngage value to caluclate a scaling factor
  const arrWeek = Object.values(HousePointsPercentagesWeek);
  const maxWeek = Math.max(...arrWeek);
  var ScalingFactorWeek = WidthOfBarChart / (maxWeek * WidthOfBarChart);

  const YearResultsArray = [
    {
      House: "Trueman",
      Width: calculateTruemanWidthYear(),
      Points: truemanTotalYear,
      Logo: drawTruemanIcon(),
      Colour: Colours.palette.trueman,
    },
    {
      House: "Tomlinson",
      Width: calculateTomlinsonWidthYear(),
      Points: tomlinsonTotalYear,
      Logo: drawTomlinsonIcon(),
      Colour: Colours.palette.tomlinson,
    },
    {
      House: "Bremner",
      Width: calculateBremnerWidthYear(),
      Points: bremnerTotalYear,
      Logo: drawBremnerIcon(),
      Colour: Colours.palette.bremner,
    },
    {
      House: "Robinson",
      Width: calculateRobinsonWidthYear(),
      Points: robinsonTotalYear,
      Logo: drawRobinsonIcon(),
      Colour: Colours.palette.robinson,
    },
  ];

  const sortedEverResultsArray = YearResultsArray.sort((a, b) => b.Points - a.Points);



  const WeekResultsArray = [
    {
      House: "Trueman",
      Width: calculateTruemanWidthWeek(),
      Points: truemanTotalWeek,
      Logo: drawTruemanIcon(),
      Colour: Colours.palette.trueman,
    },
    {
      House: "Tomlinson",
      Width: calculateTomlinsonWidthWeek(),
      Points: tomlinsonTotalWeek,
      Logo: drawTomlinsonIcon(),
      Colour: Colours.palette.tomlinson,
    },
    {
      House: "Bremner",
      Width: calculateBremnerWidthWeek(),
      Points: bremnerTotalWeek,
      Logo: drawBremnerIcon(),
      Colour: Colours.palette.bremner,
    },
    {
      House: "Robinson",
      Width: calculateRobinsonWidthWeek(),
      Points: robinsonTotalWeek,
      Logo: drawRobinsonIcon(),
      Colour: Colours.palette.robinson,
    },
  ];

  const sortedWeekResultsArray = WeekResultsArray.sort((a, b) => b.Points - a.Points);

  //GET THE AWARDEDBADGE DATA FROM THE SERVER
  const fetchAwardedBadges = async (endDateForQuery) => {
    let nextToken = null;
    let allAwardedBadges = [];

    do {
      const response = await API.graphql(
        graphqlOperation(listAwardedBadges, {
          filter: {
            dateAwarded: {
              between: [currentAcademicYearStartDate, endDateForQuery],
            },
          },
          nextToken: nextToken,
        })
      );

      allAwardedBadges = [...allAwardedBadges, ...response.data.listAwardedBadges.items];
      nextToken = response.data.listAwardedBadges.nextToken;
    } while (nextToken !== null);

    // Calculate house points totals per group
    const { totalsByGroup1, totalsByGroup2, totalsByGroup3 } = calculateHousePointTotals(allAwardedBadges);

    // Upload totals for group 1
    Object.keys(totalsByGroup1).forEach((group) => {
      const housePointTotalsPerGroupToUpload = {
        organisationID,
        groupType: "groupingData1",
        group,
        date: endDateForQuery,
        totalHousePoints: totalsByGroup1[group],
        recordedByID: id,
      };
      writeHousePointGroupTotalsToDatabase(housePointTotalsPerGroupToUpload);
    });

    // Upload totals for group 2
    Object.keys(totalsByGroup2).forEach((group) => {
      const housePointTotalsPerGroupToUpload = {
        organisationID,
        groupType: "groupingData2",
        group,
        date: endDateForQuery,
        totalHousePoints: totalsByGroup2[group],
        recordedByID: id,
      };
      writeHousePointGroupTotalsToDatabase(housePointTotalsPerGroupToUpload);
    });

    // Upload totals for group 3
    Object.keys(totalsByGroup3).forEach((group) => {
      const housePointTotalsPerGroupToUpload = {
        organisationID,
        groupType: "groupingData3",
        group,
        date: endDateForQuery,
        totalHousePoints: totalsByGroup3[group],
        recordedByID: id,
      };
      writeHousePointGroupTotalsToDatabase(housePointTotalsPerGroupToUpload);
    });
  };

  //WRITE THE HOUSE POINT TOTALS FOR EACH GROUP BACK TO THE SERVER IN THE DAILYTOTALS TABLE
  const writeHousePointGroupTotalsToDatabase = async (housePointTotalsPerGroupToUpload) => {
    await API.graphql({
      query: createDailyPointsTotal,
      variables: { input: housePointTotalsPerGroupToUpload },
    });
  };

  // Function to organize points data by group
  const organizePointsData = (pointsTotal) => {
    const organizedTotals = {
      groupingData1: {},
      groupingData2: {},
      groupingData3: {},
    };

    pointsTotal.forEach((item) => {
      const { groupType, group, totalHousePoints } = item;
      organizedTotals[groupType][group] = totalHousePoints;
    });

    return organizedTotals;
  };

  // Function to calculate points for the last week
  const calculatePointsForLastWeek = (todayPoints, lastWeekPoints) => {
    const pointsForLastWeek = {
      groupingData1: {},
      groupingData2: {},
      groupingData3: {},
    };

    Object.keys(todayPoints).forEach((groupType) => {
      Object.keys(todayPoints[groupType]).forEach((group) => {
        const todayPointsValue = todayPoints[groupType][group];
        const lastWeekPointsValue = lastWeekPoints[groupType]?.[group] || 0; // Use 0 if there are no records from last week

        // Calculate points for last week by subtracting last week's points from today's points
        const pointsDifference = todayPointsValue - lastWeekPointsValue;

        // Store the result in the organized format
        pointsForLastWeek[groupType][group] = pointsDifference;
      });
    });

    return pointsForLastWeek;
  };

  // Function to sort groups by house points
  const sortGroupsByHousePoints = (data) => {
    const sortedData = {
      groupingData1: {},
      groupingData2: {},
      groupingData3: {},
    };

    Object.keys(data).forEach((groupType) => {
      // Sort the groups by house points in descending order
      sortedData[groupType] = Object.entries(data[groupType])
        .sort(([, a], [, b]) => b - a)
        .reduce((obj, [key, val]) => {
          obj[key] = val;
          return obj;
        }, {});
    });

    return sortedData;
  };

  // Function to get the top N groups by house points with positions
  const getTopGroups = (data, groupType, n = 3) => {
    if (!data || !data[groupType]) {
      return {}; // Return an empty object or handle the case when data is not available
    }

    const sortedGroups = Object.entries(data[groupType])
      .sort(([, a], [, b]) => b - a)
      .slice(0, n);

    return sortedGroups.reduce((obj, [key, val], index) => {
      const position = index + 1;
      obj[key] = { housePoints: val, position };
      return obj;
    }, {});
  };

  const getGroupPosition = (data, groupType, userGroup) => {
    const sortedGroups = Object.entries(data[groupType])
      .sort(([, a], [, b]) => b - a)
      .map(([group]) => group);

    return sortedGroups.indexOf(userGroup) + 1;
  };

  const getGroupHousePoints = (data, groupType, userGroup) => {
    const foundGroup = Object.entries(data[groupType])
    .find(([group]) => group === userGroup);

    if (foundGroup) {
      return foundGroup[1]; // Returning the house points (value of the key-value pair)
    } else {
      // Handle the case where the user's coaching group is not found
      return 0; // Or any default value you want to return
    }
  };

  // Function to check if there are records for today in the DailyPointsTotal table
  const checkPointsForTodayAndLastWeek = async () => {
    const todaysResponseForCheck = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: today })); //see if there are any points records for todays date in the points database
    const todaysPointsTotal = todaysResponseForCheck.data.dailyPointsTotalByDate.items;
    const lastWeekResponseForCheck = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: lastWeekDateString }));
    const lastWeekPointsTotal = lastWeekResponseForCheck.data.dailyPointsTotalByDate.items;

    if (todaysPointsTotal.length > 0 && lastWeekPointsTotal.length > 0) {
      console.log("points for last week and today!");

      const todaysResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: today })); //see if there are any points records for todays date in the points database
      const todaysPointsTotalForCalculation = todaysResponse.data.dailyPointsTotalByDate.items;
      const lastWeekResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: lastWeekDateString }));
      const lastWeekPointsTotalForCalculation = lastWeekResponse.data.dailyPointsTotalByDate.items;

      const todayPoints = organizePointsData(todaysPointsTotalForCalculation);
      const lastWeekPoints = organizePointsData(lastWeekPointsTotalForCalculation);
      const pointsForLastWeek = calculatePointsForLastWeek(todayPoints, lastWeekPoints);

      const BremnerPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsYear = BremnerPointsYear.totalHousePoints + TomlinsonPointsYear.totalHousePoints + TruemanPointsYear.totalHousePoints + RobinsonPointsYear.totalHousePoints;
      setBremnerTotalYear(BremnerPointsYear.totalHousePoints);
      setTruemanTotalYear(TruemanPointsYear.totalHousePoints);
      setTomlinsonTotalYear(TomlinsonPointsYear.totalHousePoints);
      setRobinsonTotalYear(RobinsonPointsYear.totalHousePoints);
      setTotalYear(TotalHousePointsYear);
      const BremnerPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsWeek = BremnerPointsWeek.totalHousePoints + TomlinsonPointsWeek.totalHousePoints + TruemanPointsWeek.totalHousePoints + RobinsonPointsWeek.totalHousePoints;
      setBremnerTotalWeek(BremnerPointsYear.totalHousePoints - BremnerPointsWeek.totalHousePoints);
      setTruemanTotalWeek(TruemanPointsYear.totalHousePoints - TruemanPointsWeek.totalHousePoints);
      setTomlinsonTotalWeek(TomlinsonPointsYear.totalHousePoints - TomlinsonPointsWeek.totalHousePoints);
      setRobinsonTotalWeek(RobinsonPointsYear.totalHousePoints - RobinsonPointsWeek.totalHousePoints);
      setTotalWeek(TotalHousePointsYear - TotalHousePointsWeek);

      // Set the calculated totals in state
      setDailyPointsTotalsByGroup({
        today: todayPoints,
        lastWeek: pointsForLastWeek,
      });

      setIsLoading(false);
    } else if (todaysPointsTotal.length > 0 && lastWeekPointsTotal.length === 0) {
      //points for today, but nothing for last week
      await fetchAwardedBadges(lastWeekDateString);
      console.log("points for today but nothing for last week!");

      const todaysResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: today })); //see if there are any points records for todays date in the points database
      const todaysPointsTotalForCalculation = todaysResponse.data.dailyPointsTotalByDate.items;
      const lastWeekResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: lastWeekDateString }));
      const lastWeekPointsTotalForCalculation = lastWeekResponse.data.dailyPointsTotalByDate.items;
      const todayPoints = organizePointsData(todaysPointsTotalForCalculation);
      const lastWeekPoints = organizePointsData(lastWeekPointsTotalForCalculation);
      const pointsForLastWeek = calculatePointsForLastWeek(todayPoints, lastWeekPoints);
      const BremnerPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsYear = BremnerPointsYear.totalHousePoints + TomlinsonPointsYear.totalHousePoints + TruemanPointsYear.totalHousePoints + RobinsonPointsYear.totalHousePoints;
      setBremnerTotalYear(BremnerPointsYear.totalHousePoints);
      setTruemanTotalYear(TruemanPointsYear.totalHousePoints);
      setTomlinsonTotalYear(TomlinsonPointsYear.totalHousePoints);
      setRobinsonTotalYear(RobinsonPointsYear.totalHousePoints);
      setTotalYear(TotalHousePointsYear);
      const BremnerPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsWeek = BremnerPointsWeek.totalHousePoints + TomlinsonPointsWeek.totalHousePoints + TruemanPointsWeek.totalHousePoints + RobinsonPointsWeek.totalHousePoints;
      setBremnerTotalWeek(BremnerPointsYear.totalHousePoints - BremnerPointsWeek.totalHousePoints);
      setTruemanTotalWeek(TruemanPointsYear.totalHousePoints - TruemanPointsWeek.totalHousePoints);
      setTomlinsonTotalWeek(TomlinsonPointsYear.totalHousePoints - TomlinsonPointsWeek.totalHousePoints);
      setRobinsonTotalWeek(RobinsonPointsYear.totalHousePoints - RobinsonPointsWeek.totalHousePoints);
      setTotalWeek(TotalHousePointsYear - TotalHousePointsWeek);

      // Set the calculated totals in state
      setDailyPointsTotalsByGroup({
        today: todayPoints,
        lastWeek: pointsForLastWeek,
      });

      setIsLoading(false);
    } else if (todaysPointsTotal.length === 0 && lastWeekPointsTotal.length > 0) {
      //nothing for today but points for last week
      console.log("nothing for today but points for last week!");
      await fetchAwardedBadges(today);
      const todaysResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: today })); //see if there are any points records for todays date in the points database
      const todaysPointsTotalForCalculation = todaysResponse.data.dailyPointsTotalByDate.items;
      const lastWeekResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: lastWeekDateString }));
      const lastWeekPointsTotalForCalculation = lastWeekResponse.data.dailyPointsTotalByDate.items;

      const todayPoints = organizePointsData(todaysPointsTotalForCalculation);
      const lastWeekPoints = organizePointsData(lastWeekPointsTotalForCalculation);
      const pointsForLastWeek = calculatePointsForLastWeek(todayPoints, lastWeekPoints);

      const BremnerPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsYear = BremnerPointsYear.totalHousePoints + TomlinsonPointsYear.totalHousePoints + TruemanPointsYear.totalHousePoints + RobinsonPointsYear.totalHousePoints;
      setBremnerTotalYear(BremnerPointsYear.totalHousePoints);
      setTruemanTotalYear(TruemanPointsYear.totalHousePoints);
      setTomlinsonTotalYear(TomlinsonPointsYear.totalHousePoints);
      setRobinsonTotalYear(RobinsonPointsYear.totalHousePoints);
      setTotalYear(TotalHousePointsYear);
      const BremnerPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsWeek = BremnerPointsWeek.totalHousePoints + TomlinsonPointsWeek.totalHousePoints + TruemanPointsWeek.totalHousePoints + RobinsonPointsWeek.totalHousePoints;
      setBremnerTotalWeek(BremnerPointsYear.totalHousePoints - BremnerPointsWeek.totalHousePoints);
      setTruemanTotalWeek(TruemanPointsYear.totalHousePoints - TruemanPointsWeek.totalHousePoints);
      setTomlinsonTotalWeek(TomlinsonPointsYear.totalHousePoints - TomlinsonPointsWeek.totalHousePoints);
      setRobinsonTotalWeek(RobinsonPointsYear.totalHousePoints - RobinsonPointsWeek.totalHousePoints);
      setTotalWeek(TotalHousePointsYear - TotalHousePointsWeek);

      // Set the calculated totals in state
      setDailyPointsTotalsByGroup({
        today: todayPoints,
        lastWeek: pointsForLastWeek,
      });

      setIsLoading(false);
    } else if (todaysPointsTotal.length === 0 && lastWeekPointsTotal.length === 0) {
      console.log("no points at all!");
      await fetchAwardedBadges(today);
      await fetchAwardedBadges(lastWeekDateString);

      const todaysResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: today })); //see if there are any points records for todays date in the points database
      const todaysPointsTotalForCalculation = todaysResponse.data.dailyPointsTotalByDate.items;
      const lastWeekResponse = await API.graphql(graphqlOperation(dailyPointsTotalByDate, { date: lastWeekDateString }));
      const lastWeekPointsTotalForCalculation = lastWeekResponse.data.dailyPointsTotalByDate.items;

      const todayPoints = organizePointsData(todaysPointsTotalForCalculation);
      const lastWeekPoints = organizePointsData(lastWeekPointsTotalForCalculation);
      const pointsForLastWeek = calculatePointsForLastWeek(todayPoints, lastWeekPoints);

      const BremnerPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsYear = todaysResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsYear = BremnerPointsYear.totalHousePoints + TomlinsonPointsYear.totalHousePoints + TruemanPointsYear.totalHousePoints + RobinsonPointsYear.totalHousePoints;
      setBremnerTotalYear(BremnerPointsYear.totalHousePoints);
      setTruemanTotalYear(TruemanPointsYear.totalHousePoints);
      setTomlinsonTotalYear(TomlinsonPointsYear.totalHousePoints);
      setRobinsonTotalYear(RobinsonPointsYear.totalHousePoints);
      setTotalYear(TotalHousePointsYear);
      const BremnerPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Bremner");
      const TomlinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Tomlinson");
      const TruemanPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Trueman");
      const RobinsonPointsWeek = lastWeekResponse.data.dailyPointsTotalByDate.items.find((item) => item.group === "Robinson");
      const TotalHousePointsWeek = BremnerPointsWeek.totalHousePoints + TomlinsonPointsWeek.totalHousePoints + TruemanPointsWeek.totalHousePoints + RobinsonPointsWeek.totalHousePoints;
      setBremnerTotalWeek(BremnerPointsYear.totalHousePoints - BremnerPointsWeek.totalHousePoints);
      setTruemanTotalWeek(TruemanPointsYear.totalHousePoints - TruemanPointsWeek.totalHousePoints);
      setTomlinsonTotalWeek(TomlinsonPointsYear.totalHousePoints - TomlinsonPointsWeek.totalHousePoints);
      setRobinsonTotalWeek(RobinsonPointsYear.totalHousePoints - RobinsonPointsWeek.totalHousePoints);
      setTotalWeek(TotalHousePointsYear - TotalHousePointsWeek);

      // Set the calculated totals in state
      setDailyPointsTotalsByGroup({
        today: todayPoints,
        lastWeek: pointsForLastWeek,
      });
      setIsLoading(false);
    } else {
      console.log("error working out what points we have got");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkPointsForTodayAndLastWeek();
  }, []);

  useEffect(() => {
    if (dailyPointsTotalsByGroup) {
      const top3CoachingGroupsThisYear = getTopGroups(dailyPointsTotalsByGroup.today, "groupingData2");
    
    }
  }, [dailyPointsTotalsByGroup]);

  const topCoachingGroupsThisYear = getTopCoachingGroupsThisYear(dailyPointsTotalsByGroup);
  const topCoachingGroupsThisWeek = getTopCoachingGroupsThisWeek(dailyPointsTotalsByGroup);

  const navigateToSortedCoachingGroupsListThisWeek = () => {
    const time = 'Week';

    const sortedCoachingGroups = getAllCoachingGroupsSortedThisWeek(dailyPointsTotalsByGroup);
    
    navigation.navigate("League", { sortedCoachingGroups, time });
  };


  const navigateToSortedCoachingGroupsListThisYear = () => {
    VibrateOnce()
    const time = 'Year';

    const sortedCoachingGroups = getAllCoachingGroupsSortedThisYear(dailyPointsTotalsByGroup);



    navigation.navigate("League", { sortedCoachingGroups, time });
  };

  return (
    <>
      {!isLoading ? (
        <View style={{ flexGrow: 1, alignItems: "center", backgroundColor:'transparent'}}>
          <View style={width > 720 ? styles.horizontalLayout : styles.verticalLayout}>
            {/* THIS YEAR */}
            <View
              style={[
                styles.container,
                {
                  backgroundColor: Colours[colorScheme].background,
                  width: WidthOfBarChart + 90,
                },
              ]}>
              <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center", borderBottomWidth: 1, borderColor: Colours[colorScheme].edging }}>
                <BoldText style={[styles.titleText, { color: Colours[colorScheme].textLessEmphasised }]}>HOUSE POINTS:</BoldText>
                <Text style={{ fontWeight: "800", fontSize: 20, marginLeft: 10, color: Colours[colorScheme].textLessEmphasised }}>2023-2024</Text>
              </View>

              {sortedEverResultsArray?.map((house) => (
                <HouseBar houseName={house.House} houseLogo={house.Logo} houseWidth={house.Width} houseColour={house.Colour} housePoints={house.Points} key={house.House} />
              ))}

              <View style={{ padding: 10, backgroundColor: "transparent" }}>
                <Text>The Coaching groups with the most House Points this academic year are: </Text>
                <Pressable onPress={navigateToSortedCoachingGroupsListThisYear}> 
                <View style={styles.top3RowContainer}>
                  <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Text style={{ color: Colours[colorScheme].text }}>1st</Text>
                    <Feather name="award" size={30} color="gold" />
                  </View>

                  <BoldText style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisYear.firstPlace?.group || "No Data"}</BoldText>
                  <Text style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisYear.firstPlace?.housePoints || "No Data"}</Text>
                </View>
                <View style={styles.top3RowContainer}>
                  <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Text style={{ color: Colours[colorScheme].text }}>2nd</Text>
                    <Feather name="award" size={30} color="silver" />
                  </View>
                  <BoldText style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisYear.secondPlace?.group || "No Data"}</BoldText>
                  <Text style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisYear.secondPlace?.housePoints || "No Data"}</Text>
                </View>
                <View style={styles.top3RowContainer}>
                  <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Text style={{ color: Colours[colorScheme].text }}>3rd</Text>
                    <Feather name="award" size={30} color="#cd7f32" />
                  </View>
                  <BoldText style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisYear.thirdPlace?.group || "No Data"}</BoldText>
                  <Text style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisYear.thirdPlace?.housePoints || "No Data"}</Text>
                </View>

                {/* Check if user's coaching group is in the top 3 for today */}
                {dailyPointsTotalsByGroup && thisUsersCoachingGroup && !getTopGroups(dailyPointsTotalsByGroup.today, "groupingData2").hasOwnProperty(thisUsersCoachingGroup) && (
                  <View style={[styles.top3RowContainer, { borderTopColor: Colours[colorScheme].almostButtons, borderTopWidth: 1, marginTop: 10, paddingTop: 20 }]}>
                    <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                      <Text style={{ color: Colours[colorScheme].text }}>
                        {getGroupPosition(dailyPointsTotalsByGroup.today, "groupingData2", thisUsersCoachingGroup)}
                        {getSuffix(getGroupPosition(dailyPointsTotalsByGroup.today, "groupingData2", thisUsersCoachingGroup))}
                      </Text>
                    </View>
                    <BoldText style={{ color: Colours[colorScheme].text }}> {thisUsersCoachingGroup}</BoldText>
                    <Text style={{ color: Colours[colorScheme].text }}> {getGroupHousePoints(dailyPointsTotalsByGroup.today, "groupingData2", thisUsersCoachingGroup)}</Text>
                  </View>
                )}
                </Pressable>
              </View>
            </View>

            <View
              style={[
                styles.container,
                {
                  backgroundColor: Colours[colorScheme].background,
                  width: WidthOfBarChart + 90,
                },
              ]}>
              <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center", borderBottomWidth: 1, borderColor: Colours[colorScheme].edging }}>
                <BoldText style={[styles.titleText, { color: Colours[colorScheme].textLessEmphasised }]}>HOUSE POINTS:</BoldText>
                <Text style={{ fontWeight: "800", fontSize: 20, marginLeft: 10, color: Colours[colorScheme].textLessEmphasised }}>This Week</Text>
              </View>

              {sortedWeekResultsArray?.map((house) => (
                <HouseBar houseName={house.House} houseLogo={house.Logo} houseWidth={house.Width} houseColour={house.Colour} housePoints={house.Points} key={house.House} />
              ))}
              <View style={{ padding: 10, backgroundColor: "transparent" }}>
              <Text>The Coaching groups with the most House Points this week are: </Text>
              <Pressable onPress={navigateToSortedCoachingGroupsListThisWeek}> 
              <View style={styles.top3RowContainer}>
                <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <Text style={{ color: Colours[colorScheme].text }}>1st</Text>
                  <Feather name="award" size={30} color="gold" />
                </View>

                <BoldText style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisWeek.firstPlace?.group || "No Data"}</BoldText>

                <Text style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisWeek.firstPlace?.housePoints || "No Data"}</Text>
              </View>
              <View style={styles.top3RowContainer}>
                <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <Text style={{ color: Colours[colorScheme].text }}>2nd</Text>
                  <Feather name="award" size={30} color="silver" />
                </View>
                <BoldText style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisWeek.secondPlace?.group || "No Data"}</BoldText>
                <Text style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisWeek.secondPlace?.housePoints || "No Data"}</Text>
              </View>
              <View style={styles.top3RowContainer}>
                <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <Text style={{ color: Colours[colorScheme].text }}>3rd</Text>
                  <Feather name="award" size={30} color="#cd7f32" />
                </View>
                <BoldText style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisWeek.thirdPlace?.group || "No Data"}</BoldText>
                <Text style={{ color: Colours[colorScheme].text }}>{topCoachingGroupsThisWeek.thirdPlace?.housePoints || "No Data"}</Text>
              </View>

              {/* Check if user's coaching group is in the top 3 for today */}
              {dailyPointsTotalsByGroup && thisUsersCoachingGroup && !getTopGroups(dailyPointsTotalsByGroup.lastWeek, "groupingData2").hasOwnProperty(thisUsersCoachingGroup) && (
                <View style={[styles.top3RowContainer, { borderTopColor: Colours[colorScheme].almostButtons, borderTopWidth: 1, marginTop: 10, paddingTop: 20 }]}>
                  <View style={{ backgroundColor: "transparent", width: 60, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Text style={{ color: Colours[colorScheme].text }}>
                      {getGroupPosition(dailyPointsTotalsByGroup.lastWeek, "groupingData2", thisUsersCoachingGroup)}
                      {getSuffix(getGroupPosition(dailyPointsTotalsByGroup.lastWeek, "groupingData2", thisUsersCoachingGroup))}
                    </Text>
                  </View>
                  <BoldText style={{ color: Colours[colorScheme].text }}> {thisUsersCoachingGroup}</BoldText>
                  <Text style={{ color: Colours[colorScheme].text }}> {getGroupHousePoints(dailyPointsTotalsByGroup.lastWeek, "groupingData2", thisUsersCoachingGroup)}</Text>
                </View>
              )}

</Pressable></View>
            </View>
          </View>
        </View>
      ) : (
        <View style={{ backgroundColor: "transparent", flex: 1, justifyContent: "center" }}>
          <ActivityIndicatorAnimation />
        </View>
      )}
    </>
  );
};

export default PointsDashboard;

const styles = StyleSheet.create({
  container: {
    padding: 10,

    margin: 10,
    borderRadius: 10,
    // justifyContent: "space-evenly",
  },
  titleText: {
    fontSize: 24,
    alignSelf: "center",
    marginVertical: 10,
  },
  horizontalLayout: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    width: "100%",
    maxWidth:1300,
    justifyContent: "space-evenly",
  },
  verticalLayout: {
    backgroundColor: "tranparent",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },

  top3RowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    padding: 10,
    alignItems: "center",
    backgroundColor: "transparent",
  },
});
