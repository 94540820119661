import "react-native-gesture-handler";
// import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import { Button, StyleSheet, Text, View, SafeAreaView } from "react-native";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator, defaultDarkModeOverride, ThemeProvider } from "@aws-amplify/ui-react-native";
import awsExports from "./src/aws-exports";
import AuthenticationCheck from "./src/components/AuthenticationCheck";
import useCachedResources from "./src/hooks/useCachedResources";
import RecognitionLogoIndentIcon from "./assets/icons/RecognitionLogoIndent";
import useColorScheme from "./src/hooks/useColorScheme";
import Colours from "./src/constants/colours";

Amplify.configure(awsExports);

const HeaderLogoForAuthScreens = () => {
  const colorScheme = useColorScheme();
  return (
    <View
      style={{
        alignItems: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        flex: 1,
      }}>
      <View style={{ width: 30, height: 30, backgroundColor: "transparent", margin: 20 }}>
        <RecognitionLogoIndentIcon color={Colours.palette.logoGreen} />
      </View>
      <Text style={{ color: Colours[colorScheme].text, marginTop: 2, fontSize: 20, marginLeft: 10, letterSpacing: 4 }}>RECOGNITION</Text>
    </View>
  );
};

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorMode = useColorScheme();

  const theme = {
    tokens: {
      fonts: {
        default: {
          // variable: { value: 'avenir-next' },
          // static: { value: 'avenir-next' },
          value: "avenir-next",
        },
      },
      colors: {
        brand: {
          primary: {
            10: "#51cc85",
            20: "#51cc85",
            40: "#51cc85",
            60: "#51cc85",
            80: "#51cc85",
            90: "#51cc85",
            100: "#51cc85",
          },
        },
      },
    },
    overrides: [defaultDarkModeOverride],
  };
  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email address",
        isRequired: true,
        label: "",
        labelHidden: true,
      },
      password: {
        placeholder: "Enter password",
        label: "",
        labelHidden: true,
      },
    },
  };

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Authenticator.Provider>
        <ThemeProvider theme={theme} colorMode={colorMode}>
          <Authenticator formFields={formFields} Header={HeaderLogoForAuthScreens}>
          {/* <StatusBar style='auto' /> */}
            <SafeAreaView style={{ flex: 1 , backgroundColor:Colours[colorMode].background}}>
              <AuthenticationCheck />
              
            </SafeAreaView>

        
          </Authenticator>

          {/* </SafeAreaView> */}
        </ThemeProvider>
      </Authenticator.Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
