import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Storage } from "aws-amplify";
import { prettyDate } from "../functions/prettyDate";
import { useNavigation } from "@react-navigation/native";
import { VibrateOnce } from "../functions/vibration";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
const ListItemDate = ({ item, opportunityID}) => {
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const {width} = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [downloadedImage, setDownloadedImage] = useState(null);
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);
 const niceLookingDate = prettyDate(item.date)
console.log('item', item)
  const NavigateToActivitiesRegister = () => {
    VibrateOnce();
    navigation.navigate("ActivitiesPreviousRegister", {opportunityID:opportunityID, date:item.date});
  };


  return (
    <Pressable onPress={NavigateToActivitiesRegister}>
      
      
      <View
        style={{
          // backgroundColor: Colours[colorScheme].almostBackground,
          backgroundColor:item.date === formattedDate? Colours.palette.secondaryGreen : "transparent",
          // maxWidth: 800,
          // marginHorizontal: 20,
          // minWidth:450,
          // maxWidth:900,
          // width:'95%',
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          // justifyContent: "space-evenly",
          paddingHorizontal: 20,
          paddingVertical: 10,

          // marginHorizontal: width>500? 20:5,
        }}>


    <View style={{width:50, height:50,
      backgroundColor:'transparent',
      alignItems:'center', justifyContent:'center'}}>
    <Feather name="calendar" size={24} color={item.date === formattedDate? Colours.dark.background:Colours[colorScheme].buttons} /></View> 
       
        
      
        <View style={{flex: 1,flexDirection:'row', backgroundColor:'transparent', alignItems:'center', justifyContent:'space-between'}}>
      
          <Text style={{
          fontSize:14,
          color:item.date === formattedDate? Colours.dark.background:Colours[colorScheme].text,
          // maxWidth:'60%',
        }}
      
            numberOfLines={1}
            >
          {niceLookingDate}
        </Text>
        


        <Text style={{fontSize:12, color:item.date === formattedDate? Colours.dark.background:Colours[colorScheme].text,}}>{item.count} pupils attended</Text>
      </View>
  

      </View>
    </Pressable>
  );
};

export default ListItemDate;
