import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useColorScheme from '../hooks/useColorScheme';
import Colours from '../constants/colours';
import TimelinesRootScreen from "../screens/timelinesRoot";
import TimelinesDetail from "../screens/timelinesDetail";
import OpportunityDetail from "../screens/opportunityDetail";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import PhotoPopUp from "../screens/photoPopUp";
import { VibrateOnce } from "../functions/vibration";
import GroupgingData2List from "../screens/groupingData2List";
import GroupingData2List from "../screens/groupingData2List";
import HomeScreen from "../screens/homeRoot";
import GroupingData2LeagueTable from "../screens/groupingData2LeagueTable";
import GroupingData1List from "../screens/groupingData1List";
import GroupingData3List from "../screens/groupingData3List";
import CriteriaPopUp from "../screens/CriteriaPopUp";


const HomeStack =
  createNativeStackNavigator();


 export default function HomeStackNavigator() {
    const colorScheme = useColorScheme();
    return (
      
      <HomeStack.Navigator
      screenOptions={{     
        headerStyle:{
          backgroundColor: Colours[colorScheme].background,
          
        },  
      headerShown: false,
      headerTitleStyle:{fontFamily: 'avenir-next'},
          headerShadowVisible: false,
        }}
      >
        <HomeStack.Screen
          name="HomeScreen"
          component={HomeScreen}
          options={{
            headerShown: false, //AJL: this is how you show a back button for some of the screens!
            // headerShadowVisible: true,
            // headerTitle: () => <ScreenTitleTimelinesMobile />,
            
          
            
          }}
        />
        
        
        <HomeStack.Screen
          name="League"
          component={GroupingData2LeagueTable}
          options={{
            headerShown: false, //AJL: this is how you show a back button for some of the screens!
         
           
         
            
          }}

          listeners={()=> ({
            transitionStart:() => {
              VibrateOnce();
            },

          })}
        />
        <HomeStack.Screen
        name="TimelinesDetail"
        component={TimelinesDetail}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerTransparent: true,
          // headerStyle: { backgroundColor: "transparent" },
          // headerTintColor: Colours[colorScheme].buttons,
          // headerShadowVisible: false,
          // headerBackTitle: '',
          // title: "",
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <HomeStack.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          // headerShown: true,
          headerTransparent: true,
          headerShadowVisible: false,
          title: "",
          headerTintColor: Colours[colorScheme].buttons,
          headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
<HomeStack.Screen
        name="GroupingData1List"
        component={GroupingData1List}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <HomeStack.Screen
        name="GroupingData2List"
        component={GroupingData2List}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />

<HomeStack.Screen
        name="GroupingData3List"
        component={GroupingData3List}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />

      <HomeStack.Group
        screenOptions={{
          presentation: "modal",
          // presentation: 'transparentModal'
          // presentation:'containedTransparentModal'
        }}>
        {/* <TimeslineStack.Screen name="FlexibleLearningHeatMap" component={FlexibleLearningHeatMap} /> */}
        <HomeStack.Screen
          name="PhotoPopUp"
          component={PhotoPopUp}
          options={{
            headerShown: true,
            headerShadowVisible: false,
            headerTransparent: true,
            headerStyle: { backgroundColor: "transparent" },
            title: "",
            headerTintColor: Colours[colorScheme].buttons,
          }}
          listeners={() => ({
            transitionStart: () => {
              VibrateOnce();
            },
          })}
        />
        <HomeStack.Screen
          name="CriteriaPopUp"
          component={CriteriaPopUp}
          options={{
            headerShown: false,
            // headerShadowVisible: false,
            // headerTransparent: true,
            // headerStyle: { backgroundColor: "transparent" },
            // title: "",
            // headerTintColor: Colours[colorScheme].buttons,
          }}
          listeners={() => ({
            transitionStart: () => {
              VibrateOnce();
            },
          })}
        />
      </HomeStack.Group>




      </HomeStack.Navigator>
    
    );
  }

