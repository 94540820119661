import { StyleSheet, useWindowDimensions, ScrollView, FlatList } from "react-native";
import {Image} from 'expo-image';
import React, { useEffect, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import DynamicBadge from "../components/DynamicBadge";
import { BoldText } from "../constants/styledText";
import { Feather } from "@expo/vector-icons";
import CharacterAttributeGridItem from "../components/CharacterAttributeGridItem";
import { prettyDate } from "../functions/prettyDate";
import BackButton from "../components/BackButton";

export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Mappings {
        items {
          Criteria {
            id
            name
            logoWhiteURI
            colour
          }
        }
      }
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
      }
      availableTo
      year
      term
    }
  }
`;

const OpportunityDetail = ({ route, navigation }) => {
  const { opportunityID, logo } = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [opportunityData, setOpportunityData] = useState(null);
  const { width } = useWindowDimensions();
  const colorScheme = useColorScheme();
  const [layoutStyle, setLayoutStyle] = useState("phone");
  const [downloadedCategoryImage, setDownloadedCategoryImage] = useState("none");

  useEffect(() => {
    if (width < 800) {
      setLayoutStyle("phone");
    } else {
      setLayoutStyle("tablet");
    }
  }, [width]);

  // console.log(opportunityID, "is the id");
  // console.log(route);

  const fetchOpportunityData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getOpportunity, { id: opportunityID }));

      if (response) {
        setOpportunityData(response.data.getOpportunity);
       

        // if (response.data.getOpportunity.OpportunityCategory.logoGreyURI) {
        const file = await Storage.get(response.data.getOpportunity.OpportunityCategory.logoGreyURI, { level: "public" });
      
        setDownloadedCategoryImage(file);
        setIsLoadingImage(false);
        // } else {
        // setDownloadedCategoryImage(null); // Set to null if no photo URL is available
        // }
      } else {
        console.log("error getting data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    fetchOpportunityData();
  }, []);

  // console.log("opportunitydata", opportunityData.Mappings.items);

  return (
    <>
      {!isLoading && !isLoadingImage ? (
        <ScrollView
          contentContainerStyle={{ alignItems: "center" }}
          style={{
            flex: 1,
            // backgroundColor: Colours[colorScheme].background
            backgroundColor: Colours[colorScheme].background,
            paddingTop:10,
          }}>
            <BackButton />
          {/* badge meta data container */}
          <View
            style={{
              alignItems: "center",

              // height: layoutStyle == "tablet" ? 240 : 180,
              backgroundColor: Colours[colorScheme].background,
              width: layoutStyle == "tablet" ? 640 : 320,
            }}>
            <View
              style={{
                // padding: 10,
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor: Colours[colorScheme].background,
                backgroundColor: Colours[colorScheme].background,
                flexDirection: "row",
                // paddingVertical: 20,
                // minHeight:layoutStyle == "tablet" ? 300 : 300,
                // height: layoutStyle == "tablet" ? 300 : 500,
              }}>
              <View style={{ flex: 1, backgroundColor: Colours[colorScheme].background, marginHorizontal: 0, alignItems: "flex-start", justifyContent: "space-between" }}>
                <BoldText style={{ textAlign: "left", fontSize: layoutStyle == "tablet" ? 24 : 18, color: Colours[colorScheme].textLessEmphasised }}>{opportunityData.name}</BoldText>

                <View style={{ flex: 1, backgroundColor: Colours[colorScheme].background, paddingBottom: 10, alignItems: "flex-start", marginTop: layoutStyle == "tablet" ? 0 : 10 }}>
                  {opportunityData.oneOffDate ? (
                    <Text style={{ fontSize: layoutStyle == "tablet" ? 18 : 14, marginHorizontal: 0, color: Colours[colorScheme].textLessEmphasised }}>{prettyDate(opportunityData.oneOffDate)}</Text>
                  ) : opportunityData.multipleStartDate && opportunityData.multipleEndDate ? (
                    <Text style={{ fontSize: layoutStyle == "tablet" ? 18 : 14, marginHorizontal: 0, color: Colours[colorScheme].textLessEmphasised }}>
                      {prettyDate(opportunityData.multipleStartDate)} - {prettyDate(opportunityData.multipleEndDate)}
                    </Text>
                  ) : null}
                </View>
              </View>

              {/* logo */}
              <View style={{ backgroundColor: "transparent", height: layoutStyle == "tablet" ? 180 : 120, width: layoutStyle == "tablet" ? 180 : 120, marginLeft: 10 }}>
                {/* fall back icon that sits behind the photo */}

                <DynamicBadge size={layoutStyle == "tablet" ? 180 : 120} edgeColour={opportunityData.colour ? opportunityData.colour : "grey"} engagementLevel={3} logo={logo} />
              </View>
            </View>

            <View style={{ backgroundColor: Colours[colorScheme].background, alignItems: "flex-start", justifyContent: "space-between", width: "100%", marginVertical: 10 }}>
              {/* location */}
              {opportunityData.location ? (
                <View style={{ backgroundColor: "transparent", marginRight: 5, marginTop:5, flexDirection: "row", alignItems: "center" }}>
                  <Feather name="map-pin" size={20} color={Colours.palette.grey} />
                  <Text style={{ paddingLeft: 5, color: Colours[colorScheme].textLessEmphasised }}>{opportunityData.location}</Text>
                </View>
              ) : null}

              {/* staff */}
              {opportunityData.OpportunityCategory.name ? (
                <View style={{ backgroundColor: "transparent", marginRight: 5,marginTop:5, flexDirection: "row", alignItems: "center" }}>
                 <Feather name="user" size={20} color={Colours.palette.grey} />
                 <Text style={{ paddingLeft: 5, color: Colours[colorScheme].textLessEmphasised }}>{opportunityData.User.title} {opportunityData.User.preferredSurname}</Text>
                </View>
              ) : null}
            

{/* category */}
{opportunityData.User ? (
                <View style={{ backgroundColor: "transparent", marginRight: 5,marginTop:5, flexDirection: "row", alignItems: "center" }}>
                  {!isLoadingImage ? <Image source={downloadedCategoryImage} style={{ width: 20, aspectRatio: 1 }} /> : null}
                  
                  <Text style={{ paddingLeft: 5, color: Colours[colorScheme].textLessEmphasised }}>{opportunityData.OpportunityCategory.name}</Text>
                </View>
              ) : null}
            </View>


            


            {opportunityData.description ? (
            <View style={{ marginTop: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10,width: "100%", padding: 10, backgroundColor: Colours[colorScheme].almostBackground }}>
              <Text>{opportunityData.description}</Text>
            </View>
          ) : null}

           {/* character attributes */}
           {opportunityData.Mappings.items.length > 0 ? (
            <View style={{ marginBottom: 10,padding:10,borderBottomLeftRadius: 10, borderBottomRightRadius: 10, width: "100%", backgroundColor: Colours[colorScheme].practicallyBackground }}>
              {/* <BoldText style={{ color: Colours[colorScheme].textLessEmphasised }}>Character attributes
              {Math.round((width-50)/110)} and 
              {width}


              {layoutStyle == "tablet" ? 6 : 3}
              </BoldText> */}

              <FlatList
                data={opportunityData.Mappings.items}
                // horizontal={true}
                numColumns={layoutStyle == "tablet" ? 6 : 3}
                contentContainerStyle={{
                  alignSelf: "center",
                  flex:1
                }} //positions the flatlist in the centre of the parent view
                // style={{ width: "100%", flex:1 }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
                key={layoutStyle == "tablet" ? 6 : 3}
                keyExtractor={(item) => item.Criteria.id}
                scrollEnabled={false}
                renderItem={({ item }) => <CharacterAttributeGridItem item={item} />}
              />
            </View>

          ) : null}


          </View>
        </ScrollView>
      ) : (
        <View style={{ width: "100%", backgroundColor: Colours[colorScheme].background, flex: 1, justifyContent: "center", alignItems: "center" }}>
          <ActivityIndicatorAnimation />
        </View>
      )}
    </>
  );
};

export default OpportunityDetail;

const styles = StyleSheet.create({});
