import { StyleSheet, Pressable, Image } from "react-native";
// import { Image } from 'expo-image';
import { View, Text } from "../constants/themedViewAndText";
import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import TimelineIcon from "../../assets/icons/Timeline";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { BoldText } from "../constants/styledText";
import HouseLogo from "./HouseLogo";
import CoachingIcon from "../../assets/icons/Coaching";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import Animated from "react-native-reanimated";
import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp } from "react-native-reanimated";
import { VibrateOnce } from "../functions/vibration";
import { whatShouldWeCallThisPerson } from "../functions/whatShouldWeCallThisPerson";

const PhotoListItemRegister = ({ item, onPressDelete }) => {
  const { id, photoURL, preferredFirstName, preferredSurname, title, type } = item;
  const house = item.Enrolments?.items[0]?.groupingData1;
  const coach = item.Enrolments?.items[0]?.groupingData2;
  const year = item.Enrolments?.items[0]?.groupingData3;
  const colorScheme = useColorScheme();
  const [pressed, setPressed] = useState(false);
  const [downloadedImage, setDownloadedImage] = useState(null);
  const navigation = useNavigation();
  // const whatShouldWeCallThisPerson = type === "STAFF" ? title + " " + preferredSurname : preferredFirstName + " " + preferredSurname;

  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";

  const fetchPhoto = async () => {
    const file = await Storage.get(photoURL, { level: "public" });
    setDownloadedImage(file);
  };

  useEffect(() => {
    if (photoURL) {
      fetchPhoto();
    } else {
      console.log("no photo available");
    }
  }, []);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "flex-start",
        margin:5,
        height: 80,
        alignItems: "center",
        backgroundColor: "transparent",

        width: 320,
        borderRadius:10,
        borderWidth: 1,
        borderColor: Colours[colorScheme].practicallyBackground,
      }}>
      <View style={{ backgroundColor: "transparent", height: 70, width: 70, marginHorizontal: 5, alignItems: "center", justifyContent: "center" }}>
        <View
          style={{
            height: 64,
            width: 64,

            borderRadius: 64,
            borderColor: houseColor,
            borderWidth: 2,
          }}>
          <TimelineIcon color={"#ccc"} />

          {downloadedImage ? (
            <Image
              source={{ uri: downloadedImage }}
              style={{
                height: 60,
                width: 60,
                borderRadius: 60,
                position: "absolute",
              }}
              accessibilityLabel={preferredFirstName}
            />
          ) : null}
        </View>
      </View>

      <View style={{ flex: 1, backgroundColor: "transparent", marginHorizontal: 5, alignItems: "flex-start", justifyContent: "space-between" }}>
        <View style={{ backgroundColor: "transparent", width:'100%' }}>
          <BoldText numberOfLines={1} ellipsizeMode='tail' style={{ fontSize: 14, color: Colours[colorScheme].text }}>
            {whatShouldWeCallThisPerson(type, title, preferredSurname, preferredFirstName)}
          </BoldText>
          <Text style={{ fontSize: 10, color: Colours[colorScheme].textLessEmphasised }}>{year}</Text>
        </View>
      </View>

      <Pressable
      
      onPressIn={() => {
        setPressed(true);

        VibrateOnce();
      }}
      onPressOut={() => setPressed(false)}
      
      onPress={() => onPressDelete(item.id)} style={{borderRadius:10, padding: 10, backgroundColor: pressed ? Colours[colorScheme].almostBackground : "transparent", }}>
        <Feather name="x-square" color={Colours.palette.softRed} size={30} />
      </Pressable>
    </View>
  );
};

export default PhotoListItemRegister;

const styles = StyleSheet.create({});
