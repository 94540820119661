export function calculateHousePointTotals(downloadedAwardedBadges) {
    // Assuming your state is an array of items
    const awardedBadges = downloadedAwardedBadges;
  
    // Create objects to store totals for each group
    const totalsByGroup1 = {};
    const totalsByGroup2 = {};
    const totalsByGroup3 = {};
  
    // Iterate through the awardedBadges array
    awardedBadges.forEach((badge) => {
      const { groupingData1Value, groupingData2Value, groupingData3Value, scoringData3Value } = badge;
  
      // Update total for groupingData1Value
      totalsByGroup1[groupingData1Value] = (totalsByGroup1[groupingData1Value] || 0) + scoringData3Value;
  
      // Update total for groupingData2Value
      totalsByGroup2[groupingData2Value] = (totalsByGroup2[groupingData2Value] || 0) + scoringData3Value;
  
      // Update total for groupingData3Value
      totalsByGroup3[groupingData3Value] = (totalsByGroup3[groupingData3Value] || 0) + scoringData3Value;
    });
  
    // Now, 'totalsByGroup1', 'totalsByGroup2', and 'totalsByGroup3' contain the total scores for each unique groupingData value
    console.log('Totals By Group 1:', totalsByGroup1);
    console.log('Totals By Group 2:', totalsByGroup2);
    console.log('Totals By Group 3:', totalsByGroup3);
  
    // Return an object containing the totals
    return { totalsByGroup1, totalsByGroup2, totalsByGroup3 };
  }
  