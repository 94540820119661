import { StyleSheet, Pressable, Image } from "react-native";
// import { Image } from 'expo-image';
import { View, Text } from "../constants/themedViewAndText";
import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import TimelineIcon from "../../assets/icons/Timeline";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { BoldText } from "../constants/styledText";
import HouseLogo from "./HouseLogo";
import CoachingIcon from "../../assets/icons/Coaching";
import { useNavigation } from "@react-navigation/native";
import Animated from "react-native-reanimated";
import { FadeIn, FadeOut, FadeInLeft, FadeOutRight, BounceIn, BounceOut, SlideInUp, SlideOutUp } from "react-native-reanimated";
import { VibrateOnce } from "../functions/vibration";
import { whatShouldWeCallThisPerson } from "../functions/whatShouldWeCallThisPerson";

const PhotoListItem = ({ item }) => {
  const { id, photoURL, preferredFirstName, preferredSurname, title, type } = item;
  const house = item.Enrolments?.items[0]?.groupingData1;
  const coach = item.Enrolments?.items[0]?.groupingData2;
  const year = item.Enrolments?.items[0]?.groupingData3;
  const colorScheme = useColorScheme();
  const [downloadedImage, setDownloadedImage] = useState(null);
  const navigation = useNavigation();
  // const whatShouldWeCallThisPerson = type === "STAFF" ? title + " " + preferredSurname : preferredFirstName + " " + preferredSurname;

  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";

  const NavigateToTimelineDetail = () => {
    navigation.navigate("TimelinesDetail", { userID: id });
  };

  const fetchPhoto = async () => {
    const file = await Storage.get(photoURL, { level: "public" });
    setDownloadedImage(file);
  };

  useEffect(() => {
    if (photoURL) {
      fetchPhoto();
    } else {
      console.log("no photo available");
    }
  }, []);

  return (
    <Pressable
      onPress={() => {
        VibrateOnce();
        NavigateToTimelineDetail();
      }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "flex-start",
          paddingHorizontal: 20,
          height: 80,
          alignItems: "center",
          backgroundColor: "transparent",
          borderBottomWidth: 1,
          borderBottomColor: Colours[colorScheme].practicallyBackground,
        }}>
        <View style={{ backgroundColor: "transparent", height: 70, width: 70, marginHorizontal: 5, alignItems: "center", justifyContent: "center" }}>
          <View
            style={{
              height: 64,
              width: 64,

              borderRadius: 64,
              borderColor: houseColor,
              borderWidth: 2,
            }}>
            <TimelineIcon color={"#ccc"} />

            {downloadedImage ? (
              <Image
                source={{ uri: downloadedImage }}
                // cachePolicy='memory-disk'
                // tintColor="gray"
                style={{
                  height: 60,
                  width: 60,

                  borderRadius: 60,
                  // borderColor: houseColor,
                  // borderWidth: 3,

                  position: "absolute",
                }}
                accessibilityLabel={preferredFirstName}

                //   placeholder={require('../../assets/images/')}
              />
            ) : null}
            {/* {year === 'Class of 2023'?<View style={{backgroundColor:'dark-brown', width: '100%', height: '100%', position:'absolute', borderRadius:100,opacity:0.3}}></View>:null} */}
          </View>
        </View>

        <View style={{ flex: 1, backgroundColor: "transparent", marginHorizontal: 5, alignItems: "flex-start", justifyContent: "space-between" }}>
          <View style={{ backgroundColor: "transparent" }}>
            <BoldText numberOfLines={1} style={{ fontSize: 16, color: Colours[colorScheme].text }}>
              {whatShouldWeCallThisPerson(type, title, preferredSurname, preferredFirstName)}
            </BoldText>
            <Text style={{ fontSize: 12, color: Colours[colorScheme].textLessEmphasised }}>{year}</Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default PhotoListItem;

const styles = StyleSheet.create({});
