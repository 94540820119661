import React from "react";
import { View } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import BackButton from "../components/BackButton";
import TermTabNavigator from "../navigation/termTabs";

const ActivitiesSelection = () => {
  const colorScheme = useColorScheme();
  return (
    <>
      <View style={{ backgroundColor: Colours[colorScheme].background, height: 30 }}>
        <BackButton />
      </View>
      <TermTabNavigator />
    </>
  );
};

export default ActivitiesSelection;
