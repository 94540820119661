import { StyleSheet, FlatList } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { UserContext, AllUsersContext } from "../hooks/Contexts";
import { useWindowDimensions } from "react-native";
import ThumbnailTimeLine from "../components/ThumbnailTimeLine";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import SearchBar from "../components/SearchBar";
import { FlashList } from "@shopify/flash-list";
import CardTimeline from "../components/CardTimeline";

export const connecteesByConnector = /* GraphQL */ `
  query ConnecteesByConnector($connectorID: ID!, $sortDirection: ModelSortDirection, $filter: ModelConnectionFilterInput, $limit: Int, $nextToken: String) {
    connecteesByConnector(connectorID: $connectorID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        connectorID
        connecteeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const TimelinesRootScreen = () => {
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const { width } = useWindowDimensions();
  const [connectionsAndTheirDetails, setConnectionsAndTheirDetails] = useState([]);
  const [stateVariableForNumberOfColumns, setStateVariableForNumberOfColumns] = useState(1);
  const [numberOfColumnsForCards, setNumberOfColumnsForCards] = useState(1);
  const [numberOfColumnsForThumbnails, setNumberOfColumnsForThumnbnails] = useState(1);
  const [filteredData, setFilteredData] = useState(allUserDetailsFromAllUsersContext);
  const [masterData, setMasterData] = useState(allUserDetailsFromAllUsersContext);
const [refreshing, setRefreshing] = useState(false)
  const fetchData = async () => {
    setRefreshing(true)
    try {
     
      const response = await API.graphql(
        graphqlOperation(connecteesByConnector, {
          connectorID: userDetailsFromUserContext.id,
        })
      );

      const connections = response.data.connecteesByConnector.items;
      const connectionIDs = connections.map((connection) => connection.connecteeID);
      const thisUsersConnectionsAndTheirDetails = allUserDetailsFromAllUsersContext.filter((user) => connectionIDs.includes(user.id));
      setConnectionsAndTheirDetails(thisUsersConnectionsAndTheirDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setRefreshing(false)
  };

  // Fetch data when the component is initially mounted
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let columnsForCards = 1;
    let columnsForThumbnails = 1;
  
    if (width < 375) {
      columnsForCards = 1;
      columnsForThumbnails = 1;
    } else if(width < 400) {
      columnsForCards = 1;
      columnsForThumbnails = 2;
    } else if (width < 600) {
      columnsForCards = 1;
      columnsForThumbnails = 2;
    } else if (width < 800) {
      columnsForCards = 2;
      columnsForThumbnails = 3;
    } else if (width < 990) {
      columnsForCards = 2;
      columnsForThumbnails = 4;
    } else {
      columnsForCards = 3;
      columnsForThumbnails = 4;
    }
  
    setNumberOfColumnsForCards(columnsForCards);
    setNumberOfColumnsForThumnbnails(columnsForThumbnails);
  }, [width]);
  

  useEffect(() => {
    if (masterData) {
      setFilteredData(masterData);
    }
  }, [masterData]);

  return (
    <View style={{backgroundColor: Colours[colorScheme].background,flex:1}}>
       <SearchBar masterData={masterData} setFilteredData={setFilteredData} filteredData={filteredData} />
    <View
      style={{
        backgroundColor: Colours[colorScheme].almostBackground,
        borderRadius:10,
        alignItems: "center",
        flex: 1,
        width: '100%',
      }}>
       
     

      {filteredData.length < masterData.length ? (

        //show the results of the search

        <FlatList
          data={filteredData}
          contentContainerStyle={{alignItems:'center'  }}
          style={{ width: '100%', }} //this helps get the scrollbar on the right hand side of the page (note the parent view style too)
          numColumns={numberOfColumnsForCards}
          keyExtractor={(item) => item.id}
         
          key={numberOfColumnsForCards}
          renderItem={({ item }) => <CardTimeline item={item} />}
        />
      ) : (


        //just show the connections this user has

        <FlatList
          data={connectionsAndTheirDetails}
          numColumns={numberOfColumnsForThumbnails}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{alignItems:'center'  }}
          onRefresh={fetchData}
          refreshing={refreshing}
          style={{ width: '100%', }}
          key={numberOfColumnsForThumbnails}
          renderItem={({ item }) => (
            <ThumbnailTimeLine
              id={item.id}
              photoURL={item.photoURL}
              house={item.Enrolments?.items[0]?.groupingData1}
              preferredFirstName={item.preferredFirstName}
              preferredSurname={item.preferredSurname}
              title={item.title}
              type={item.type}
            />
          )}
        />
      )}
    </View></View>
  );
};

export default TimelinesRootScreen;

const styles = StyleSheet.create({});
