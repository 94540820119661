import React, { useState } from "react";
import { StyleSheet, ScrollView, SectionList, Pressable } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { ClubAttendance } from "../models";
import ListItem from "../components/ListItem";
import ListItemClubAttendance from "../components/ListItemClubAttendance";
import { BoldText } from "../constants/styledText";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import FlexibleLearningHeatMap from "./FlexibleLearningHeatMap";
import { VibrateOnce } from "../functions/vibration";
const getTerm = (date) => {
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month >= 0 && month <= 2) {
    return `Spring Term ${year}`;
  } else if (month >= 3 && month <= 7) {
    return `Summer Term ${year}`;
  } else {
    return `Autumn Term ${year}`;
  }
};

const FlexibleLearning = ({ route }) => {
  const { clubAttendance } = route.params;
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const [numberOfColumnsForBadges, setNumberOfColumnsForBadges] = useState(3);
  const [view, setView] = useState("heat");
  const [isPressing, setIsPressing] = useState(false); // State to track press status

  const sortedClubAttendance = clubAttendance.sort((clubA, clubB) => {
    // Convert dateAwarded to JavaScript Date objects for comparison
    const dateA = new Date(clubA.recordedDate);
    const dateB = new Date(clubB.recordedDate);

    // Compare the dates in reverse chronological order
    return dateB - dateA;
  });

  const groupedData = sortedClubAttendance.reduce((acc, item) => {
    const term = getTerm(new Date(item.recordedDate));
    if (!acc[term]) {
      acc[term] = [];
    }
    acc[term].push(item);
    return acc;
  }, {});

  const toggleView = () => {
    VibrateOnce();
    if (view == "list") {
      setView("heat");
    } else {
      setView("list");
    }
  };

  const sections = Object.keys(groupedData).map((key) => ({
    title: key,
    data: groupedData[key],
  }));

  const NavigateToHeatMap = () => {
    console.log("clicked", clubAttendance);
    navigation.navigate("FlexibleLearningHeatMap", { clubAttendance });
  };

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, alignItems:'center', flex: 1 }}>
      <View style={{ backgroundColor: Colours[colorScheme].almostBackground, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, alignItems: "center", flex: 1, width: "100%" }}>
        {/* <Pressable onPress={NavigateToHeatMap}>
    <Text>heatmap</Text>
    </Pressable> */}

        {/* view toggle button */}
        <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, alignItems: "flex-end", width: "100%", padding: 5 }}>
          <Pressable
            onPress={toggleView}
            onPressIn={() => setIsPressing(true)} // Set isPressing to true on press in
            onPressOut={() => setIsPressing(false)} // Set isPressing to false on press out
            style={({ pressed }) => [
              {
                backgroundColor: pressed || isPressing ? Colours[colorScheme].edging : Colours[colorScheme].practicallyBackground,
                borderRadius: 10,
                width: 35,
                height: 35,
                alignItems: "center",
                justifyContent: "center",
              },
            ]}>
            {view == "heat" ? <Feather name="list" size={30} color={Colours.palette.secondaryGreen} /> : <Feather name="calendar" size={30} color={Colours.palette.secondaryGreen} />}
          </Pressable>
        </View>

        {view == "list" ? (
          <SectionList
            sections={sections}
            contentContainerStyle={{ alignSelf: "center", 
            // alignItems:'center',
            width: "100%" 
          }}
            style={{ width: "100%" }}
            key={numberOfColumnsForBadges}
            keyExtractor={(item) => item.id}
            // numColumns={numberOfColumnsForBadges}
            renderItem={({ item }) => <ListItemClubAttendance item={item} />}
            renderSectionHeader={({ section: { title } }) => (
              <View style={{
                width: "100%",
                backgroundColor: Colours[colorScheme].practicallyBackground, padding: 15 }}>
                <Text style={{ color: Colours[colorScheme].textLessEmphasised }}>{title}</Text>
              </View>
            )}
          />
        ) : (
          <FlexibleLearningHeatMap clubAttendance={clubAttendance} />
        )}
      </View>
    </View>
  );
};

export default FlexibleLearning;
