import { ScrollView, StyleSheet, Platform } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext } from "react";
import { OrganisationContext, UserContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import WeekdayGrid from "../components/HeatMap";

const FlexibleLearningHeatMap = ({ clubAttendance }) => {
  const colorScheme = useColorScheme();
  const { closedDates } = useContext(OrganisationContext);

  //these dates need moving to organisation context
  const spring2024StartDate = new Date("2024-01-08");
  const spring2024EndDate = new Date("2024-03-29");

  const autumn20222023StartDate = new Date("2022-09-05");
  const autumn20222023EndDate = new Date("2022-12-16");

  const spring20222023StartDate = new Date("2023-01-01");
  const spring20222023EndDate = new Date("2023-03-31");

  const summer20222023StartDate = new Date("2023-04-17");
  const summer20222023EndDate = new Date("2023-07-25");

  const autumn2023StartDate = new Date("2023-09-06");
  const autumn2023EndDate = new Date("2023-12-16");

  const exceptionalDays = closedDates;

  //   const exceptionalDays = [
  //     '2023-08-01',
  // '2023-08-02',
  // '2023-08-03',
  // '2023-08-04',
  // '2023-08-05',
  // '2023-08-06',
  // '2023-08-07',
  // '2023-08-08',
  // '2023-08-09',
  // '2023-08-10',
  // '2023-08-11',
  // '2023-08-12',
  // '2023-08-13',
  // '2023-08-14',
  // '2023-08-15',
  // '2023-08-16',
  // '2023-08-17',
  // '2023-08-18',
  // '2023-08-19',
  // '2023-08-20',
  // '2023-08-21',
  // '2023-08-22',
  // '2023-08-23',
  // '2023-08-24',
  // '2023-08-25',
  // '2023-08-26',
  // '2023-08-27',
  // '2023-08-28',
  // '2023-08-29',
  // '2023-08-30',
  // '2023-08-31',

  //     '2023-09-01',
  //     '2023-09-04',
  //     '2023-10-06',
  //     '2023-10-30',
  //     '2023-11-01',
  //     '2023-11-02',
  //     '2023-11-03',
  //     '2023-12-01',
  //     '2023-10-31',
  //     '2023-12-25',
  //     '2023-12-26',
  //     '2023-12-27',
  //     '2023-12-28',
  //     '2023-12-29'
  //   ];

  const successfulDays = clubAttendance.map((item) => item.recordedDate);

  const platform = Platform.OS;

  return (
    <ScrollView
      style={{
        flex: 1,
        width: "100%",
        // alignSelf:'center',
        backgroundColor: "transparent",
      }}
      contentContainerStyle={{ alignItems: platform === "web" ? "center" : "stretch" }}>
      {/* <View style={{flex: 1,backgroundColor:'red', width:'auto'}}> */}
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Spring Term 2023-2024</Text>
        <WeekdayGrid startDate={spring2024StartDate} endDate={spring2024EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>

      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Autumn Term 2023-2024</Text>
        <WeekdayGrid startDate={autumn2023StartDate} endDate={autumn2023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Summer Term 2022-2023</Text>
        <WeekdayGrid startDate={summer20222023StartDate} endDate={summer20222023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Spring Term 2022-2023</Text>
        <WeekdayGrid startDate={spring20222023StartDate} endDate={spring20222023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>
      <View style={styles.eachTermView}>
        <Text style={{ backgroundColor: Colours[colorScheme].practicallyBackground, color: Colours[colorScheme].textLessEmphasised, padding: 15 }}>Autumn Term 2022-2023</Text>
        <WeekdayGrid startDate={autumn20222023StartDate} endDate={autumn20222023EndDate} exceptionalDays={exceptionalDays} successfulDays={successfulDays} clubAttendance={clubAttendance} />
      </View>

      {/* </View> */}
    </ScrollView>
  );
};

export default FlexibleLearningHeatMap;

const styles = StyleSheet.create({
  eachTermView: { paddingBottom: 20, backgroundColor: "transparent" },
});
