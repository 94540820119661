import React, { useEffect, useState } from "react";
import {  Pressable,  } from "react-native";
import {Image} from 'expo-image';
import { Storage } from "aws-amplify";
import { View, Text } from "../constants/themedViewAndText";
import { useNavigation } from "@react-navigation/native";
const CharacterAttributeGridItem = ({ item }) => {
  const navigation = useNavigation();
  const [downloadedImage, setDownloadedImage] = useState(null);
  const fetchAttritubeImage = async () => {
    try {
      if (item.Criteria.logoWhiteURI) {
        const file = await Storage.get(item.Criteria.logoWhiteURI, { level: "public" });
        setDownloadedImage(file);
      } else {
        setDownloadedImage(null); // Set to null if no photo URL is available
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      console.log("process complete"); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    fetchAttritubeImage();
  }, []);

  const NavigateToCriteriaPopUp = () => {
    navigation.navigate("CriteriaPopUp", { criteriaID: item.Criteria.id });
  };

  return (
    <Pressable onPress={NavigateToCriteriaPopUp}>
    <View style={{ backgroundColor: "transparent", alignItems: "center", marginHorizontal: 5,}}>
      <View style={{ backgroundColor: item.Criteria.colour, alignItems: "center", justifyContent: "center", borderRadius: 50, width: 60, height: 60, margin: 15 }}>
        <Image source={downloadedImage} style={{ width: 45, height: 45 }} />
      </View>
      <Text style={{ color: item.Criteria.colour, fontSize:10 }}>{item.Criteria.name}</Text>
    </View>
   </Pressable>
  );
};

export default CharacterAttributeGridItem;
