import React, { useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { useRoute } from "@react-navigation/native";
import BackButton from "../components/BackButton";
import CloseButton from "../components/CloseButton";
import {getLast60Days}  from '../functions/last60Days'
import { Picker } from '@react-native-picker/picker';
const ChangeDatePopUp = ({  }) => {

    //this component needs to receive a useState setter function to change the date for the parent component. 
  const route = useRoute();
  const { width } = useWindowDimensions();


  const colorScheme = useColorScheme();


  const arrayForPicker = getLast60Days();


  console.log('arrayForPicker',arrayForPicker)

  

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      
        <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10, alignItems: "center" }}>
          <View style={{ width: "90%", marginTop: 20, height: 200, backgroundColor: Colours[colorScheme].background, borderRadius: 10,  }}>
     <CloseButton />


     <Picker style={{
        borderWidth: 1,
        margin: 10,
        padding: 10,
        borderRadius: 8,
        fontSize: 16,
        fontFamily: "avenir-next",
        width: 280,
        // outlineStyle: "none",
        color: Colours[colorScheme].textLessEmphasised,
                backgroundColor: Colours[colorScheme].background,
                borderColor: Colours[colorScheme].edging,}}>
        {arrayForPicker.map((day, index) => (
          <Picker.Item key={index} label={day} value={day} />
        ))}
      </Picker>
          </View>
         
        </View>
 
    </View>
  );
};

export default ChangeDatePopUp;

const styles = StyleSheet.create({});
