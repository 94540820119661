import React from 'react';
import { StyleSheet } from 'react-native';
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TruemanIcon from '../../assets/icons/Trueman';
import BremnerIcon from '../../assets/icons/Bremner';
import TomlinsonIcon from '../../assets/icons/Tomlinson';
import RobinsonIcon from '../../assets/icons/Robinson';
import RecognitionLogoIndentIcon from '../../assets/icons/RecognitionLogoIndent';

const HouseLogo = ({ house }) => {
  const colorScheme = useColorScheme();

  // Determine the icon component based on the "house" prop
  let selectedIcon;

  switch (house) {
    case 'Trueman':
      selectedIcon = <TruemanIcon color={Colours.palette.trueman} />;
      break;
    case 'Bremner':
      selectedIcon = <BremnerIcon color={Colours.palette.bremner} />;
      break;
    case 'Tomlinson':
      selectedIcon = <TomlinsonIcon color={Colours.palette.tomlinson} />;
      break;
    case 'Robinson':
      selectedIcon = <RobinsonIcon color={Colours.palette.robinson} />;
      break;
    default:
      // Handle null or unknown house here
      selectedIcon = <RecognitionLogoIndentIcon />; 
      break;
  }

  return selectedIcon;
}

export default HouseLogo;

const styles = StyleSheet.create({});
