import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Image } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { ClubAttendanceContext, OrganisationContext, UserContext } from "../hooks/Contexts";
// import { Image } from 'expo-image';
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import { API, Storage, graphqlOperation } from "aws-amplify";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { Feather } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import RegisterTabNavigator from "../navigation/registerTabs";
import { BoldText } from "../constants/styledText";

export const clubAttendanceByOpportunityID = /* GraphQL */ `
  query ClubAttendanceByOpportunityID(
    $opportunityID: ID!
    $recordedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubAttendanceByOpportunityID(opportunityID: $opportunityID, recordedDate: $recordedDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendanceMark
        recordedDate
        userID
        User {
          id
          surname
          firstName
          preferredFirstName
          preferredSurname
          aaa
          title
          type
          photoURL
          dateOfBirth
          email
          gender
          isActive
          pushToken
          createdAt
          updatedAt
          Enrolments {
            items {
              groupingData1
              groupingData2
              groupingData3
            }
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        opportunityID
        Opportunity {
          name
          term
        }
        recordedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const ActivitiesRegister = ({ route, navigation }) => {
  const { opportunityID, downloadedImage, name, term, item } = route.params;
  const colorScheme = useColorScheme();

  const [allClubAttendanceForThisClub, setAllClubAttendanceForThisClub] = useState([]);
  const [previousSessions, setPreviousSessions] = useState([]);

  const fetchData = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(clubAttendanceByOpportunityID, {
          opportunityID: opportunityID,
          filter: {
            attendanceMark: { eq: "P" },
            
          },
        })
      );

      // Extract unique dates using a Set
      const uniqueDatesSet = new Set(response.data.clubAttendanceByOpportunityID.items.map((item) => item.recordedDate));

      // Create an array of objects with date and count
      const uniqueDatesWithCountArray = Array.from(uniqueDatesSet).map((date) => {
        return {
          date,
          count: response.data.clubAttendanceByOpportunityID.items.filter((item) => item.recordedDate === date).length,
        };
      });

      // console.log('response',response.data.clubAttendanceByOpportunityID.items)

      // Convert Set back to an array and set the state
      setPreviousSessions(uniqueDatesWithCountArray);
      setAllClubAttendanceForThisClub(response.data.clubAttendanceByOpportunityID.items);
    } catch (error) {
      console.error("Error fetching data on ActivitiesPreviousSessions screen:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);




  // Function to update the context value
  const updateClubAttendanceContext = (newAttendanceData) => {
    setAllClubAttendanceForThisClub(newAttendanceData);
  };


  return (
    <>
      <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
        <View style={{ backgroundColor: "transparent", padding: 10, height: 50, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
          <BackButton />
          {/* <View style={{backgroundColor:'blue', flexDirection:'row'}}> */}

          <BoldText numberOfLines={1} style={{ maxWidth: "60%", textAlign: "right", color: Colours[colorScheme].text }}>
            {name}
          </BoldText>
          <Text> in {term}</Text>

          {downloadedImage ? (
            <Image
              source={{ uri: downloadedImage }}
              style={{
                height: 50,
                width: 50,
                backgroundColor: "white",
                borderRadius: 100,
                // borderColor: houseColor,
                // borderWidth: 3,

                // position: "absolute",
              }}
            />
          ) : (
            <View style={{ height: 50, width: 50, backgroundColor: "transparent" }}>
              <FlexibleLearningIcon color={"#ccc"} />
            </View>
          )}

          {/* </View> */}
        </View>

        <ClubAttendanceContext.Provider value={{ allClubAttendanceForThisClub, updateClubAttendanceContext }}>
          <RegisterTabNavigator opportunityID={opportunityID} name={name} />
        </ClubAttendanceContext.Provider>
      </View>
    </>
  );
};

export default ActivitiesRegister;

const styles = StyleSheet.create({});
