import { Pressable } from "react-native";
import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";

const BackButton = () => {
  const navigator = useNavigation();
  const colorScheme = useColorScheme();
  const [pressed, setPressed] = useState(false);
  return (
    <Pressable
      onPressIn={() => {
        setPressed(true);

        VibrateOnce();
      }}
      onPressOut={() => setPressed(false)}
      onPress={navigator.goBack}
      style={{
        position: "absolute",
        left: 5,
        backgroundColor: pressed ? Colours[colorScheme].almostBackground : "transparent",
        borderRadius: 5,
      }}>
      <Feather name="chevron-left" color={pressed ? Colours.palette.logoGreen : Colours[colorScheme].buttons} size={30} />
    </Pressable>
  );
};

export default BackButton;
