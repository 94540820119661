import { Pressable } from "react-native";
import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";

const CloseButton = () => {
  const navigator = useNavigation();
  const colorScheme = useColorScheme();
  const [pressed, setPressed] = useState(false);
  return (
    <Pressable
      onPressIn={() => {
        setPressed(true);

        VibrateOnce();
      }}
      onPressOut={() => setPressed(false)}
      onPress={navigator.goBack}
      style={{
        position: "absolute",
        right: 10,
        top:10,
        backgroundColor: pressed ? Colours[colorScheme].almostBackground : "transparent",
        borderRadius: 5,
      }}>
      <Feather name="x" color={pressed ? Colours.palette.logoGreen : Colours[colorScheme].buttons} size={30} />
    </Pressable>
  );
};

export default CloseButton;
