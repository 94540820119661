import React from "react";
import Svg, { Path } from "react-native-svg";

const CoachingIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 1000 1000">


<Path d="M919.63,399.6L742.3,222.27c-295.27,69.07-198.6,65.32-492.22-1.87L77.15,393.3
	c0.62,1.15,50.97,113.23,51.7,114.2c39.02,73.48,168.51,186.94,225.98,247.8c18.6,19.48,52.52,19.51,71.09,0
	c3.41-3.95,14.43-15.4,18.4-19.13c19.44,19.98,36.03,40.15,67.13,32.41c8.73-2.15,16.78-6.83,23.13-13.12
	c0.8-0.71,18.58-18.55,19.88-19.82c19.28,19.89,36.3,40.3,67.19,32.48c8.73-2.15,16.78-6.83,23.13-13.12
	c58.75-62.12,185.77-173.03,226.08-247.86C870.88,507.14,919.63,399.6,919.63,399.6z M777.54,589.82L628.85,738.51
	c-2.54,2.42-5.32,4.56-8.56,5.91c-9.98,4.46-22.57,2.18-30.25-5.68h-0.03c-10.61-10.6-10.65-27.95,0-38.61
	c0,0,88.65-88.68,88.65-88.68l-16.24-16.24c-8.87,8.23-135.74,137.08-148.19,147.47c-10.28,7.21-25.62,5.61-34.38-3.48h-0.03
	c-10.56-10.21-10.61-28.14,0-38.48c8.39-8.38,139.18-139.19,144.06-144.06l-16.24-16.24c-3.48,3.5-11,10.99-14.53,14.53
	c0,0-183.46,183.46-183.46,183.46c-10.62,11.07-30.22,10.4-40.03-1.58c-9.25-10.73-8.33-27.3,2.07-37.7l182.81-182.81
	c3.49-3.44,11.05-11.09,14.53-14.53l-16.24-16.24c-2.18,2.18-162.06,162.06-168.73,168.73v0.03c-27.2,25.03-63.15-13.06-37.34-39.8
	c67.54-67.61,148.51-148.46,214.3-214.3c-11.14,2.5-23.65,2.67-35.07,2.59c-7.74,7.74-16.63,16.63-26.25,26.28l-32.61-32.61
	c-17.73-1.96-41.77-4.67-59.58-6.66c-29.1,59.93-99.17,94.65-164.7,81.33l4.36-22.54c62.59,12.44,125.15-24.84,146.19-83.46
	c25.37,2.85,70.25,7.84,96.09,10.76c13.97,1.51,40.89,5.38,54.69,3.48c38.17-1.19,67.25-36.4,63.16-74.21L546.04,291l4.5-1.05
	l184.51-42.45l157.08,157.12l-41.34,91.31C830.36,530.04,805.72,561.64,777.54,589.82z" fill={color.color}/>
    </Svg>
    
        
    );
  };
  
  export default CoachingIcon;