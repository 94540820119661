import { FlatList, Pressable, StyleSheet } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useEffect, useState } from "react";
import { UserContext, AllUsersContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Auth } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react-native";
import AchievementsTabNavigator from "../navigation/achievementsTabs";
import PointsDashboard from "../components/PointsDashboard";
import CoachingIcon from "../../assets/icons/Coaching";
import { Storage } from "aws-amplify";
import PhotoListItem from "../components/PhotoListItem";
import BackButton from "../components/BackButton";
const GroupingData3List = ({route}) => {

const {year} = route.params
  console.log('year', year)
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);

  const { id, Enrolments } = useContext(UserContext);

 

  function filterRecords(records) {
    return records.filter((record) => record.Enrolments?.items[0]?.groupingData3 === year);
  }

  
  const inputRecords = allUserDetailsFromAllUsersContext;
  const filteredRecords = filterRecords(inputRecords);
// Sort the records by preferredSurname
const sortedRecords = [...filteredRecords].sort((a, b) => {
  const surnameA = a.preferredSurname.toUpperCase();
  const surnameB = b.preferredSurname.toUpperCase();
  return surnameA.localeCompare(surnameB);
});
 

  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10 }}>
        <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, width:'100%',justifyContent:'center', flexDirection: "row", borderTopRightRadius: 10,borderTopLeftRadius:10, alignItems: "center", }}>
<BackButton />
          <BoldText style={{ color: Colours[colorScheme].textLessEmphasised, fontSize: 24, alignSelf: "center", marginVertical: 6 }}>{year}</BoldText>
        </View>
        <FlatList
          data={sortedRecords}
          // ref={flatListRef}
          // getItemLayout={getItemLayout}
          renderItem={({ item }) => (
            <PhotoListItem item={item} />
             
          )}
        />
      </View>
    </View>
  );
};

export default GroupingData3List;

const styles = StyleSheet.create({});
