import * as Haptics from "expo-haptics";
import { Platform } from "react-native";

export function VibrateOnce() {

  if(Platform.OS !== 'web'){
  return (
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
  )
  }
}
export function VibrateTwice() {

  if(Platform.OS !== 'web'){
    return (
        
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success)
          
    )
  }
}