
export const getSuffix = (num) => {
    const lastDigit = num % 10;
    const secondLastDigit = Math.floor((num % 100) / 10);
  
    if (secondLastDigit === 1) {
      return 'th';
    }
  
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
  