import React from "react";
import Svg, { Path,Polygon } from "react-native-svg";

const BremnerIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300">
    <Path d="M115,54.5c0.5,0,1.1-0.1,1.6-0.2c3.4-0.9,5.5-4.4,4.6-7.8c-0.4-1.6-0.1-3,0.9-4.3c1-1.3,2.3-1.9,4-1.9h47.2
		c1.6,0,3,0.7,4,2c1,1.3,1.3,2.7,0.9,4.3c-0.9,3.4,1.2,6.9,4.6,7.8c0.5,0.1,1.1,0.2,1.6,0.2c2.9,0,5.4-2,6.2-4.8
		c1.4-5.4,0.2-11-3.2-15.4c-3.4-4.4-8.5-6.9-14.1-6.9h-47.2c-5.5,0-10.7,2.5-14.1,6.9c-3.4,4.4-4.6,10-3.2,15.4
		C109.5,52.5,112.1,54.5,115,54.5z"fill={color.color}/>
	<Path  d="M71.1,87.2c1.9-3,1-6.9-2-8.8c-2.9-1.8-6.2-2.8-9.5-2.8c-2.1,0-4.1,0.4-6.1,1.1c-5.2,1.9-9.2,6-10.9,11.3
		l-14.6,44.8c-1.7,5.3-0.9,10.9,2.2,15.5c3.1,4.6,8.1,7.4,13.6,7.8c0.1,0,0.3,0,0.4,0c3.4,0,6.2-2.6,6.4-6c0.2-3.5-2.5-6.6-6-6.8
		c-1.6-0.1-2.9-0.8-3.9-2.2c-0.9-1.4-1.1-2.8-0.6-4.4l14.6-44.8c0.5-1.5,1.5-2.6,3.1-3.2c0.6-0.2,1.2-0.3,1.8-0.3
		c1.4,0,2.3,0.6,2.6,0.8c1,0.7,2.2,1,3.4,1C67.9,90.2,70,89.1,71.1,87.2z"fill={color.color}/>
	<Path  d="M256.6,87.9c-1.7-5.3-5.7-9.4-10.9-11.3c-2-0.7-4-1.1-6.1-1.1c-3.3,0-6.6,1-9.5,2.8c-1.4,0.9-2.5,2.3-2.8,4
		c-0.4,1.7-0.1,3.4,0.8,4.8c1.2,1.9,3.2,3,5.4,3c1.2,0,2.4-0.3,3.4-1c0.4-0.2,1.3-0.8,2.6-0.8c0.6,0,1.2,0.1,1.8,0.3
		c1.5,0.6,2.6,1.6,3.1,3.2l14.6,44.8c0.5,1.6,0.3,3-0.6,4.4c-0.9,1.4-2.2,2.1-3.8,2.2c-3.5,0.2-6.2,3.3-6,6.8c0.2,3.4,3,6,6.4,6
		c0.1,0,0.3,0,0.4,0c5.5-0.4,10.5-3.2,13.6-7.8c3.1-4.6,3.9-10.3,2.2-15.5L256.6,87.9z"fill={color.color}/>
	<Path  d="M121.6,241.7c-0.8-0.3-1.5-0.5-2.4-0.5c-2.6,0-5,1.6-5.9,4c-0.6,1.5-1.7,2.5-3.3,3c-0.5,0.1-1,0.2-1.5,0.2
		c-1.5,0-2.5-0.7-2.9-1l-38.1-27.7c-1.3-1-2-2.3-2.1-3.9c-0.1-1.6,0.6-3,1.8-4c2.7-2.3,3.1-6.3,0.9-9c-1.2-1.5-3-2.3-4.9-2.3
		c-1.5,0-2.9,0.5-4.1,1.5c-4.3,3.5-6.6,8.7-6.4,14.3c0.2,5.5,2.8,10.6,7.3,13.9l38.1,27.7c3.1,2.2,6.7,3.4,10.4,3.4
		c1.7,0,3.4-0.2,5-0.7c5.3-1.6,9.6-5.4,11.6-10.6c0.6-1.6,0.6-3.3-0.1-4.9C124.4,243.5,123.2,242.3,121.6,241.7z"fill={color.color}/>
	<Path  d="M231.2,202.9c-1.1,1.3-1.6,3-1.4,4.7c0.2,1.7,1,3.2,2.3,4.3c1.3,1,1.9,2.4,1.8,4c-0.1,1.6-0.7,3-2.1,3.9
		l-38.1,27.7c-0.3,0.3-1.3,1-2.9,1c-0.5,0-1-0.1-1.5-0.2c-1.6-0.5-2.7-1.5-3.3-3c-1-2.5-3.3-4-6-4c-0.8,0-1.6,0.1-2.4,0.4
		c-3.3,1.3-4.9,5-3.6,8.3c2,5.2,6.3,9,11.6,10.6c1.6,0.5,3.3,0.7,5,0.7c3.8,0,7.4-1.2,10.4-3.4l38.1-27.7c4.5-3.3,7.2-8.3,7.3-13.9
		c0.2-5.6-2.2-10.8-6.4-14.3c-1.2-1-2.6-1.5-4.1-1.5C234.2,200.6,232.4,201.4,231.2,202.9z"fill={color.color}/>
	<Path  d="M277.9,171.2l2.1-1.7c2.9-2.4,5.5-5.3,7.7-8.5c7.1-10.4,8.9-23.2,5-35.2L278.1,81c-3.9-12-12.9-21.3-24.8-25.5
		c-3.4-1.2-7-2-10.6-2.3L240,53l-1.8-23.2c-0.1-0.7-0.4-1.4-1-1.8c-0.4-0.3-0.9-0.5-1.4-0.5c-0.2,0-0.4,0-0.6,0.1l-23.3,5.6l-1-2.5
		c-1.4-3.6-3.4-7-5.7-10.1c-7.7-9.9-19.3-15.6-31.9-15.6h-47.2c-12.6,0-24.2,5.7-31.9,15.7c-2.4,3-4.3,6.4-5.7,10l-1,2.5L64,27.4
		c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-1,0.2-1.4,0.5c-0.6,0.4-0.9,1.1-1,1.8L59.2,53l-2.7,0.2c-3.7,0.3-7.2,1.1-10.6,2.3
		C34.1,59.7,25.1,69,21.2,81L6.7,125.8c-3.9,11.9-2.1,24.8,5,35.2c1.9,2.8,4.2,5.4,6.8,7.7l2,1.8L8.6,189.6c-0.4,0.6-0.5,1.4-0.2,2
		c0.2,0.7,0.7,1.2,1.4,1.5l22.4,9.3l-0.7,2.7c-1,3.9-1.5,7.9-1.4,11.8c0.4,12.6,6.5,24,16.6,31.4l38.2,27.7c6.9,5,15.1,7.7,23.6,7.7
		c3.9,0,7.7-0.5,11.4-1.6c3.8-1.1,7.4-2.8,10.7-4.9l2.3-1.5l14.9,17.5c0.5,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.8-0.8l14.9-17.4
		l2.3,1.5c3.3,2.1,6.9,3.8,10.7,4.9c3.8,1.1,7.6,1.6,11.4,1.6c8.5,0,16.7-2.7,23.6-7.7l38.2-27.7c10.2-7.4,16.2-18.8,16.6-31.4
		c0.1-4.2-0.4-8.3-1.5-12.4l-0.8-2.7l21.2-8.8c0.7-0.3,1.1-0.8,1.4-1.5c0.2-0.7,0.1-1.4-0.2-2L277.9,171.2z M256,165.8l-6.7,0.4
		c-5.5,0.3-10.2,4.1-11.9,9.5l0,0.1l-0.1,0.2c-1.7,5.3,0,11,4.1,14.4l5,4.1c6.6,5.5,10.3,13.5,10,22.1c-0.3,8.6-4.4,16.4-11.4,21.5
		l-38.2,27.7c-4.8,3.5-10.3,5.3-16.2,5.3c-2.6,0-5.2-0.4-7.8-1.1c-8.2-2.4-14.8-8.4-18-16.3l-2.1-5.3c-2-5.1-6.9-8.4-12.5-8.4
		l-0.8,0l-0.8,0c-5.6,0-10.5,3.3-12.5,8.4l-2.1,5.2c-3.2,8-9.7,13.9-18,16.3c-2.5,0.7-5.2,1.1-7.8,1.1c-5.8,0-11.4-1.8-16.1-5.3
		L54.2,238c-7-5.1-11.1-12.9-11.4-21.5c-0.3-8.6,3.4-16.7,10-22.1l4.9-4.1c4.2-3.5,5.8-9.1,4.1-14.3c0,0-0.2-0.5-0.2-0.6
		c-1.6-5.2-6.4-8.9-11.8-9.2l-6.6-0.4c-8.6-0.6-16.2-4.9-21.1-12c-4.8-7.1-6.1-15.9-3.4-24l14.6-44.8c2.7-8.2,8.8-14.5,16.9-17.5
		c3-1.1,6.2-1.6,9.4-1.6c5.2,0,10.3,1.5,14.8,4.3l6,3.8c2.1,1.3,4.5,2,7.1,2c2.9,0,5.7-0.9,8-2.6l0.4-0.3l0.1,0
		c4.5-3.3,6.5-8.9,5.1-14.1l-1.7-6.8c-2.1-8.3-0.3-17,4.9-23.8c5.3-6.8,13.2-10.7,21.8-10.7h46.5c8.4,0,16.2,3.4,21.4,9.4
		c6.1,7,8.2,16.1,6,25l-1.8,6.9c-1.4,5.3,0.7,10.8,5.2,14.1c0.1,0.1,0.2,0.2,0.3,0.2c2.4,1.7,5.2,2.6,8.1,2.6c2.5,0,4.9-0.7,7-2
		l6.1-3.9c4.5-2.8,9.6-4.3,14.8-4.3c3.2,0,6.3,0.6,9.4,1.6c8.1,2.9,14.3,9.3,16.9,17.5l14.6,44.8c2.6,8.2,1.4,16.9-3.4,24.1
		C272.2,160.9,264.6,165.3,256,165.8z"fill={color.color}/>
	<Path  d="M149.6,70.4c-12.9,0-25.1,3.1-35.8,8.7l35.8,35.8l35.8-35.8C174.7,73.6,162.6,70.4,149.6,70.4z"fill={color.color}/>
	<Path d="M80.3,184.2l35.8-35.8l-35.8-35.8c-5.6,10.7-8.7,22.9-8.7,35.8C71.6,161.3,74.8,173.5,80.3,184.2z"fill={color.color}/>
	<Path d="M149.6,182l-35.8,35.8c10.7,5.5,22.9,8.7,35.8,8.7c12.9,0,25.1-3.1,35.8-8.7L149.6,182z"fill={color.color}/>
	<Path d="M219,184.2c5.6-10.7,8.7-22.9,8.7-35.8c0-12.9-3.1-25.1-8.7-35.8l-35.8,35.8L219,184.2z"fill={color.color}/>
	<Polygon points="125.2,137.7 119.7,141.7 120.1,142.8 125.4,159.4 131.1,176.9 168.2,176.9 173.9,159.4 179.2,142.9 
		179.6,141.7 174,137.7 149.6,119.9"fill={color.color}/>
    
    </Svg>
    
        
  );
};

export default BremnerIcon;