import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import FlexibleLearning from "../screens/FlexibleLearning";
import Badges from "../screens/Badges";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import BadgeIcon from "../../assets/icons/Badge";
import { Image } from "react-native";
import { Feather } from "@expo/vector-icons";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import IndependentLearningIcon from "../../assets/icons/IndependentLearning";
import { VibrateOnce } from "../functions/vibration";
import TrainingIcon from "../../assets/icons/Training";
import SettingsScreen from "../screens/settingsRoot";
import Training from "../screens/Training";

const Tab = createMaterialTopTabNavigator();

const AchievementsTabNavigator = ({badges, clubAttendance, training, type}) => {
  const colorScheme = useColorScheme();

  console.log('type',type)

  return (
    <>
    {/* {badges.length>0 || clubAttendance.length>0 ||training.length>0 ? */}
    
    
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: { height: 110, backgroundColor: Colours[colorScheme].background, margin: 0, padding: 0, justifyContent:'flex-start' },
        tabBarActiveTintColor: Colours.palette.secondaryGreen, //icon and text label
        tabBarInactiveTintColor: Colours[colorScheme].edging,//icon and text label
        tabBarIconStyle: { margin: 0, padding: 0, height: 50, width: 50 },
        tabBarBounces:true,
        tabBarIndicatorStyle:{backgroundColor:Colours.palette.secondaryGreen,},
        tabBarLabelStyle: { color:Colours[colorScheme].textLessEmphasised, fontSize: 12, fontFamily: "avenir-next", margin: 0, padding: 0 },
        // tabBarShowLabel:false
      }}>
     
     {/* {badges.length>0?  */}
     {type === "CHILD"?
     <Tab.Screen
        name="Badges"
        component={Badges}
        listeners={()=> ({
          tabPress:() => {
            VibrateOnce();
          },

        })}
        initialParams={{ badges: badges }}
        options={{
         
          // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
          title:"Badges",
          tabBarIcon: ({ color }) => (
            <View style={{ width: 50, height: 50, backgroundColor: "transparent" }}>
              <BadgeIcon color={color} />
            </View>
          ),
        }}
      />:null}
      
      {/* :null} */}


      {/* {clubAttendance.length>0? */}
      {type === "CHILD"?
      <Tab.Screen
        name="FlexibleLearning"
        component={FlexibleLearning}
        listeners={()=> ({
          tabPress:() => {
            VibrateOnce();
          },

        })}
        initialParams={{ clubAttendance: clubAttendance }}
        options={{
          title: "Flexible Learning",
          tabBarIcon: ({ color }) => (
            <View style={{ width: 50, height: 50, backgroundColor: "transparent" }}>
              <FlexibleLearningIcon color={color} />
            </View>
          ),
        }}
      />
      
      :null}

{/* {training.length>0? */}
{type === "STAFF" || type === "ADMIN"?
<Tab.Screen

        name="Training"
        component={Training}
        listeners={()=> ({
          tabPress:() => {
            VibrateOnce();
          },

        })}
        initialParams={{ training: training }}
        options={{
          title: "Professional Development",
          tabBarIcon: ({ color }) => (
            <View style={{ width: 50, height: 50, backgroundColor: "transparent" }}>
              <TrainingIcon color={color} />
            </View>
          ),
        }}

/>
:null}


      {/* <Tab.Screen
        name="IndependentLearning"
        component={FlexibleLearning}
        options={{
          title: "Independent Learning",
          tabBarIcon: ({ color }) => (
            <View style={{ width: 50, height: 50, backgroundColor: "transparent" }}>
              <IndependentLearningIcon color={color} />
            </View>
          ),
        }}
      /> */}
    </Tab.Navigator>
    
    {/* :null} */}
    
    </>
  );
};

export default AchievementsTabNavigator;
